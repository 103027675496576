<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit keterangannilai</h5>
           
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updateketerangannilai">
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Tahun Ajaran <span class="text-danger">*</span></label
                        >
                        <select class="form-control" v-model="keterangannilai.id_ta">
                        <option value="">-- select tahun ajaran --</option>
                        <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{ tahunajaran.desc}}</option>
                      </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                  </div> -->

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Kode Nilai</label
                        >
                        <input
                          type="text"
                          placeholder="kode nilai"
                          class="form-control"
                          v-model="keterangannilai.kode"
                        />
                        <small class="text-danger" v-if="validation.kode">{{ validation.kode[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Desc Nilai</label
                        >
                        <input
                          type="text"
                          placeholder="desc nilai"
                          class="form-control"
                          v-model="keterangannilai.desc"
                        />
                        <small class="text-danger" v-if="validation.desc">{{ validation.desc[0] }}</small>
                      </div>
                    </div>
                  </div>
                 

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-keterangannilai' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive,onMounted, watch} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "keterangannilaiIndex",
  components: {},
  data() {
    return {
      showketerangannilai: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const keterangannilai = reactive({
      kode:'',
         desc:''
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

  

    //function register, fungsi ini di jalankan ketika form di submit
    function updateketerangannilai() {
      //define variable
   

      let formData = new FormData();
      formData.append('kode', this.keterangannilai.kode)
      formData.append('desc', this.keterangannilai.desc)
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("keterangannilai/update", {
          keterangannilaiId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-keterangannilai" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("keterangannilai/getDetail", route.params.id);
    });

    watch(
      () => store.state.keterangannilai.details,
      (newketerangannilai) => {
        if (newketerangannilai) {
          const {
           desc,
           kode
          } = newketerangannilai;
       
          keterangannilai.kode = kode;
          keterangannilai.desc = desc;
       
      
        }
      }
    );

    //computed
  

    //return a state and function
    return {
      keterangannilai, // <-- state user
      validation, // <-- state validation
      updateketerangannilai,
    
    };
  },
};
</script>