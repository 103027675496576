<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data tahun ajaran</h5>
              <router-link  v-if="datapermissions.includes('tahunajaran-create')"  to="/tahunajaran/tambah" class="btn btn-primary btn-sm">Tambah</router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4 me-auto my-auto mr-5">
                <div class="form-group">
                  <label for="">Ganti Tahun Ajaran Aktif</label>

                  <select class="form-control select2" v-model="data.ta" @change="changeActive">
                    <option value="">-- select tahun ajaran aktif --</option>
                    <option v-for="tahunajaran in tas" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2" />
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Kode</th>
                    <th>Nama</th>
                    <th>Deskripsi</th>
                    <th>Tahun Awal</th>
                    <th>Tahun Akhir</th>
                    <th>Status Tahun Ajaran</th>
                    <th>Active</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in tahunajarans.data" :key="m.id" style="height: 1px">
                    <td>{{ m.code }}</td>
                    <td>{{ m.name }}</td>
                    <td>{{ m.desc }}</td>
                    <td>{{ m.start_year }}</td>
                    <td>{{ m.end_year }}</td>
                    <td>{{ m.status_tahun_ajaran }}</td>
                    <td>{{ m.active == 1 ? 'Aktif' : '' }}</td>
                    <td>
                      <router-link  v-if="datapermissions.includes('tahunajaran-edit')"  :to="{
                        name: 'admin-tahunajaran-edit',
                        params: { id: m.id },
                      }" class="btn btn-success btn-xs m-0 me-2">
                        <i class="fas fa-pen"></i></router-link>
                      <button  v-if="datapermissions.includes('tahunajaran-delete')"  class="btn btn-danger btn-xs m-0" @click="confirmDelete(m.id)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>
                Menampilkan {{ tahunajarans.to }} dari {{ tahunajarans.total }}
              </p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: tahunajarans.current_page === 1 }">
                  <a class="page-link" @click="changePage(tahunajarans.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in tahunajarans.links" :key="page"   :class="{ 'page-item': true, 'active': page.active === true }"
                      >
                        <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                      </li>
                <li class="page-item" :class="{
                  disabled:
                    tahunajarans.current_page === tahunajarans.last_page,
                }">
                  <a class="page-link" @click="changePage(tahunajarans.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter } from "vue-router";

export default {
  name: "tahunajaranIndex",


  setup() {
    const store = useStore();
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: '',
      ta:''

    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("tahunajaran/getData");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("user/getDataPermission");
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const tahunajarans = computed(() => {
      return store.getters["tahunajaran/getData"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("tahunajaran/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian

      });
    };

    const changeActive = async () => {
      // //console.log('id', data.ta);
      let formData = new FormData();
      formData.append('id_ta', data.ta)

      formData.append("_method", "POST")
      
      await store.dispatch('tahunajaran/updatetoactive', formData)

        //success
        .then(() => {

          //sweet alert
          Swal.fire({
            title: 'BERHASIL!',
            text: "Data Berhasil update",
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
          store.dispatch("tahunajaran/getData");

          router.push({ name: "admin-tahunajaran" });
        });
      };

      const changePerPage = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;

        await store.dispatch("tahunajaran/getData", {
          page: 1,
          perPage: jumlah,
          search: pencarian
        });
      };

      const searchData = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("tahunajaran/getData", {
          page: 1,
          perPage: jumlah,
          search: pencarian
        });


        // Lakukan tindakan yang diinginkan dengan nilai searchQuery
      }

      function confirmDelete(id) {
        Swal.fire({
          title: 'Konfirmasi',
          text: 'Apakah Anda yakin ingin menghapus data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ya, hapus!',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('tahunajaran/destroy', id)
              .then(() => {

                store.dispatch("tahunajaran/getData");

                router.push({ name: "admin-tahunajaran" });
                //alert
                Swal.fire({
                  title: 'BERHASIL!',
                  text: "Data Berhasil Dihapus!",
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 2000
                })



              })
          }
        });
      }


      setNavPills();
      setTooltip(store.state.bootstrap);

      return {
        tahunajarans,
        changePage,
        changePerPage,
        perPageOptions,
        data,
        searchData,
        confirmDelete,
        changeActive,
        tas,
        datapermissions

      };
    },
  };
</script>
