<template>
  <div class="py-4 container-fluid">



    <div class="row mt-3">

     
      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="KB-TK" :value="totaldatakbtk ?? 0" description="" :icon="{
          component: 'ni ni-hat-3',
          background: 'bg-gradient-primary',
          shape: 'rounded-circle'
        }" />
      </div>

      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="SD" :value="totaldatasd ?? 0" description="" :icon="{
          component: 'ni ni-hat-3',
          background: 'bg-gradient-danger',
          shape: 'rounded-circle'
        }" />
      </div>

      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="SMP" :value="totaldatasmp ?? 0" description="" :icon="{
          component: 'ni ni-hat-3',
          background: 'bg-gradient-success',
          shape: 'rounded-circle'
        }" />
      </div>

      <div class="col-lg-3 col-md-6 col-12">
        <mini-statistics-card title="SMA" :value="totaldatasma ?? 0" description="" :icon="{
          component: 'ni ni-hat-3',
          background: 'bg-gradient-warning',
          shape: 'rounded-circle'
        }" />
      </div>


    </div>

    <div class="row mt-3">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="card">
          <h5 class="m-2 text-center">Pendaftar PSB {{settingapp.ppdb_subtitle}}</h5>
          <div v-if="totalinternal">
            <div class="table-responsive">
            
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr rowspan="2">
                    <td class="text-center"></td>
                    <td colspan="2" class="text-center">KB TK</td>
                    <td colspan="2" class="text-center">SD</td>
                    <td colspan="2" class="text-center">SMP</td>
                    <td colspan="2" class="text-center">SMA</td>
                  </tr>
                  <tr style="max-height: 5px">
                    <td></td>
                    <th class="text-center">Internal</th>
                    <th class="text-center">External</th>
                    <th class="text-center">Internal</th>
                    <th class="text-center">External</th>
                    <th class="text-center">Internal</th>
                    <th class="text-center">External</th>
                    <th class="text-center">Internal</th>
                    <th class="text-center">External</th>
                  </tr>

                </thead>

                <tbody>
                 
                  <tr>
                    <td>Pendaftar</td>
                    <td align="center">{{ totalinternal[1] ? totalinternal[1].jumlah_internal : 0}}</td>
                    <td class="text-center">{{ totalexternal[1] ? totalexternal[1].jumlah_external : 0 }}</td>
                    <td class="text-center">{{ totalinternal[2] ? totalinternal[2].jumlah_internal : 0}}</td>
                    <td class="text-center">{{ totalexternal[2] ? totalexternal[2].jumlah_external : 0 }}</td>
                    <td class="text-center">{{ totalinternal[3] ? totalinternal[3].jumlah_internal : 0 }}</td>
                    <td class="text-center">{{ totalexternal[3] ? totalexternal[3].jumlah_external : 0 }}</td>
                    <td class="text-center">{{ totalinternal[4] ? totalinternal[4].jumlah_internal : 0 }}</td>
                    <td class="text-center">{{ totalexternal[4] ? totalexternal[4].jumlah_external : 0 }}</td>
                  </tr>
                  <tr>
                    <td>Aktif </td>
                    <td class="text-center">{{ totalinternal[1] ? totalinternal[1].jumlah_internal_aktif : 0}}</td>
                    <td class="text-center">{{ totalexternal[1] ? totalexternal[1].jumlah_external_aktif : 0}}</td>
                    <td class="text-center">{{ totalinternal[2] ? totalinternal[2].jumlah_internal_aktif : 0 }}</td>
                    <td class="text-center">{{ totalexternal[2] ? totalexternal[2].jumlah_external_aktif : 0 }}</td>
                    <td class="text-center">{{ totalinternal[3] ? totalinternal[3].jumlah_internal_aktif : 0 }}</td>
                    <td class="text-center">{{ totalexternal[3] ? totalexternal[3].jumlah_external_aktif : 0 }}</td>
                    <td class="text-center">{{ totalinternal[4] ? totalinternal[4].jumlah_internal_aktif : 0 }}</td>
                    <td class="text-center">{{ totalexternal[4] ? totalexternal[4].jumlah_external_aktif : 0 }}</td>
                  </tr>
                  <tr>

                    <td>Lulus</td>
                    <td class="text-center">{{ totalinternal[1] ? totalinternal[1].jumlah_internal_lulus : 0 }}</td>
                    <td class="text-center">{{ totalexternal[1] ? totalexternal[1].jumlah_external_lulus : 0 }}</td>
                    <td class="text-center">{{ totalinternal[2] ? totalinternal[2].jumlah_internal_lulus : 0 }}</td>
                    <td class="text-center">{{ totalexternal[2] ? totalexternal[2].jumlah_external_lulus : 0 }}</td>
                    <td class="text-center">{{ totalinternal[3] ? totalinternal[3].jumlah_internal_lulus : 0 }}</td>
                    <td class="text-center">{{ totalexternal[3] ? totalexternal[3].jumlah_external_lulus : 0 }}</td>
                    <td class="text-center">{{ totalinternal[4] ? totalinternal[4].jumlah_internal_lulus : 0 }}</td>
                    <td class="text-center">{{ totalexternal[4] ? totalexternal[4].jumlah_external_lulus : 0 }}</td>
                  </tr>
                  <!-- <tr>

                    <td>Belum diproses</td>
                    <td class="text-center">{{ totalinternal[1].jumlah_belum_diproses }}</td>
                    <td class="text-center">{{ totalexternal[1].jumlah_belum_diproses }}</td>
                    <td class="text-center">{{ totalinternal[2].jumlah_belum_diproses }}</td>
                    <td class="text-center">{{ totalexternal[2].jumlah_belum_diproses }}</td>
                    <td class="text-center">{{ totalinternal[3].jumlah_belum_diproses }}</td>
                    <td class="text-center">{{ totalexternal[3].jumlah_belum_diproses }}</td>
                    <td class="text-center">{{ totalinternal[4].jumlah_belum_diproses }}</td>
                    <td class="text-center">{{ totalexternal[4].jumlah_belum_diproses }}</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <ContentLoader/>
        </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import MiniStatisticsCard from "../../examples/Cards/MiniStatisticsCard.vue";
import { useStore } from "vuex";
import { ContentLoader } from 'vue-content-loader'
export default {
  name: "SmartHome",
  components: {
    MiniStatisticsCard,
    ContentLoader,
  },
  data() {
    return {
      showMenu: false,
    };
  },

  setup() {
    const store = useStore();
    onMounted(async () => {
      await store.dispatch("dashboard/getData");
      await store.dispatch("dashboard/getDataInternal");
      await store.dispatch("dashboard/getDataExternal");
      await store.dispatch("settingapp/getData");
      await store.dispatch("siswa/getSiswaAktifKbtk");
      await store.dispatch("siswa/getSiswaAktifSd");
      await store.dispatch("siswa/getSiswaAktifSmp");
      await store.dispatch("siswa/getSiswaAktifSma");
    });

    const totaldata = computed(() => {
      return store.getters["dashboard/getData"];
    });

    const totaldatakbtk = computed(() => {
      return store.getters["siswa/getSiswaAktifKbtk"];
    });

    const totaldatasd = computed(() => {
      return store.getters["siswa/getSiswaAktifSd"];
    });

    const totaldatasmp = computed(() => {
      return store.getters["siswa/getSiswaAktifSmp"];
    });

    const totaldatasma = computed(() => {
      return store.getters["siswa/getSiswaAktifSma"];
    });

    const totalinternal = computed(() => {
      return store.getters["dashboard/getDataInternal"];
    });

    const totalexternal = computed(() => {
      return store.getters["dashboard/getDataExternal"];
    });

    const settingapp = computed(() => {
      return store.getters["settingapp/getData"];
    });

    return {
      totaldata,
      totalinternal,
      totalexternal,
      settingapp,
      totaldatakbtk,
      totaldatasd,
      totaldatasmp,
      totaldatasma
    }
  },


  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
};
</script>
