//import axios
import axios from 'axios'

const Api = axios.create({

    // baseURL: 'http://localhost:8000/api'
    baseURL: 'https://api-v2.ypii.net/api'
    //baseURL: 'https://api-v2.annisaa-izada.sch.id/api'
})

export default Api