import Api from '../../api/Api'
const unitlevel = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        unitlevelall: [],
        unitnaikkelas: [],
        unitlevels: [],
        unitlulus:[],
        unitleveldetail: {},
    },

    //mutations
    mutations: {
      
        GET_ALL(state, unitall) {
            state.unitall = unitall
        },

        SET_ALL_DATA(state, unitlevelall) {

            //set value state "role"
            state.unitlevelall = unitlevelall
          },
        
          SET_NAIK_KELAS(state, unitnaikkelas) {
        
            //set value state "role"
            state.unitnaikkelas = unitnaikkelas
          },
        
          SET_LULUS(state, unitlulus) {
        
            //set value state "role"
            state.unitlulus = unitlulus
          },
        
      
          SET_DATA(state, unitlevels) {
        
            //set value state "role"
            state.unitlevels = unitlevels
          },
        
          SET_DETAIL_DATA(state, unitleveldetail) {
        
            //set value state "role"
            state.unitleveldetail = unitleveldetail
          },
       
      
    },

    _actions: {
        getAll({ commit }, payload) {
            let q = payload ? payload : '';
            console.log(payload);
            
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/unitlevel/getallwithoutrequest?q=${q}`)
                .then(response => {

                    // //console.log('ini parents',response.data.data)
                    commit('SET_ALL_DATA', response.data.data)
                })
        },

        getAlls({ commit },payload) {
            let q = payload ? payload : '';
           

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/unitlevel/getall?q=${q}`)
                .then(response => {
                    // //console.log('ini parents',response.data.data)
                    commit('SET_ALL_DATA', response.data.data)
                })
        },

        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/unitlevel?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                //  //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getNaikKelas({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/unitlevel/naikkelas`)
                .then(response => {
                 //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },


        getLulus({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/unitlevel/lulus`)
                .then(response => {
                 //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        store({ commit }, formData) {
           

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/unitlevel', formData)

                    .then(response => {

                       
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/unitlevel/edit/${payload}`)
                .then(response => {
                    //console.log(response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DETAIL_DATA', response.data.data)

                })
        },

        update({ commit }, { unitlevelId, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

               
                Api.post(`/unitlevel/update/${unitlevelId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/user/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_user', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },

    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       
        getAll(state) {
            return state.unitlevelall
        },

        getAlls(state) {
            return state.unitlevelall
        },

        getData(state) {
            return state.unitlevels
        },

        getDetail(state) {
            return state.unitleveldetail
        },


        getNaikKelas(state) {
            return state.unitlevels
        },


        getLulus(state) {
            return state.unitlevels
        },

    }

}

export default unitlevel