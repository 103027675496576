<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit unit</h5>
           
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updateUnit">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama </label>
                        <input
                          type="text"
                          placeholder="name"
                          class="form-control"
                          v-model="unit.name"
                        />
                        <small class="text-danger" v-if="validation.name">{{
                          validation.name[0]
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Code</label>
                        <input
                          type="text"
                          placeholder="kode"
                          class="form-control"
                          v-model="unit.code"
                        />
                        <small class="text-danger" v-if="validation.code">{{
                          validation.code[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >File Logo</label
                        >
                        <input
                          type="file"
                          @change="handleFileLogoChange"
                          class="form-control"
                        />

                        <small
                          class="text-danger"
                          v-if="validation.file_logo"
                          >{{ validation.file_logo[0] }}</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >File Tata Tertib</label
                        >
                        <input
                          type="file"
                          @change="handleFileTataTertibChange"
                          class="form-control"
                        />

                        <small
                          class="text-danger"
                          v-if="validation.file_tatatertib"
                          >{{ validation.file_tatatertib[0] }}</small
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Sort</label>
                        <input
                          type="text"
                          placeholder="name"
                          class="form-control"
                          v-model="unit.sort"
                        />
                        <small class="text-danger" v-if="validation.sort">{{
                          validation.sort[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-unit' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive,onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "unitIndex",
  components: {},
  data() {
    return {
      showunit: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const unit = reactive({
      name: "",
        code: "",
        sort: "",
        file_logo: "",
        file_tatatertib: "",
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    function handleFileLogoChange(e) {
      //get image
      let image = (this.unit.file_logo = e.target.files[0]);

      //check fileType
      if (!image.type.match("image.*")) {
        //if fileType not allowed, then clear value and set null
        e.target.value = "";

        //set state "category.image" to null
        this.unit.file_logo = null;

        //show sweet alert
        Swal.fire({
          title: "OOPS!",
          text: "Format File Tidak Didukung!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }

    function handleFileTataTertibChange(e) {
      //get image
      let image = (this.unit.file_tatatertib = e.target.files[0]);

      //check fileType
      if (!image.type.match("image.*")) {
        //if fileType not allowed, then clear value and set null
        e.target.value = "";

        //set state "category.image" to null
        this.unit.file_tatatertib = null;

        //show sweet alert
        Swal.fire({
          title: "OOPS!",
          text: "Format File Tidak Didukung!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }


    //function register, fungsi ini di jalankan ketika form di submit
    function updateUnit() {
      //define variable
   

      let formData = new FormData();
      formData.append("name", this.unit.name);
      formData.append("code", this.unit.code);
      formData.append("sort", this.unit.sort);
      formData.append('file_logo', this.unit.file_logo)
      formData.append('file_tatatertib', this.unit.file_tatatertib)
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("units/update", {
          unitId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-unit" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("units/getDetail", route.params.id);
    });

    watch(
      () => store.state.units.details,
      (newunit) => {
        if (newunit) {
          const {
            name,
        code,
        sort,
        file_logo,
        file_tatatertib,
          } = newunit;
          unit.name = name;
          unit.code = code;
          unit.sort = sort;
          unit.file_logo = file_logo;
          unit.file_tatatertib = file_tatatertib;
      
        }
      }
    );

    //computed
  

    //return a state and function
    return {
      unit, // <-- state user
      validation, // <-- state validation
      updateUnit,
      handleFileTataTertibChange,
      handleFileLogoChange
    };
  },
};
</script>