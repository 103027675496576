import Api from '../../api/Api'
const narasikb = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
        narasikb: {},
        history:{},
        detail:{},
        getExist:{},
        cekinput:{},
        cekinputjenis:{},
        cekinputjenis3:{},
        cekinputjenis4:{},
        publikasi:{},
    },

    //mutations
    mutations: {
       
        SET_ALL_DATA(state, narasikb) {
            state.narasikb = narasikb
        },


        SET_HISTORY(state, history) {
            state.history = history
        },

        SET_DETAIL(state, detail) {
            state.detail = detail
        },

        SET_EXIST(state, getExist) {
            state.getExist = getExist
        },

        SET_CHECK(state, cekinput) {
            state.cekinput = cekinput
        },

        SET_CHECKJENIS(state, cekinputjenis){
            state.cekinputjenis = cekinputjenis
        },

        SET_CHECKJENIS3(state, cekinputjenis3){
            state.cekinputjenis3 = cekinputjenis3
        },

        SET_CHECKJENIS4(state, cekinputjenis4){
            state.cekinputjenis4 = cekinputjenis4
        },


        SET_CHECKJENIS5(state, cekinputjenis5){
            state.cekinputjenis5 = cekinputjenis5
        },


        SET_CHECKJENIS6(state, cekinputjenis6){
            state.cekinputjenis6 = cekinputjenis6
        },


        SET_CHECKJENIS7(state, cekinputjenis7){
            state.cekinputjenis7 = cekinputjenis7
        },

        SET_PUBLIKASI(state, publikasi){
            state.publikasi = publikasi
        },

     
      
    },

    _actions: {
      
      
        getData({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/settingapp`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL_DATA', response.data.data)
                })
        },

        history({ commit },{ id_student , elemen }) {
            // console.log('ini history',payload);
            const payload = { id_student, elemen };
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post(`/get-penilaian-bulanan`,payload)
                .then(response => {
                    // console.log('ini history response ',response.data.data)
                    commit('SET_HISTORY', response.data.data)
                })
        },

        

        store({ commit }, payload) {
          
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                Api.post('/penilaian-bulanan', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, {id,payload}) {
          
            return new Promise((resolve, reject) => {
                // console.log("ini update narasi ",payload);
                const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                Api.post(`/penilaian-bulanan/${id}`, payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        
        getExist({ commit },payload) {
            // console.log('ini getExist',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post(`/getExist`,payload)
                .then(response => {
                    // console.log('ini getExist response ',response.data.data)
                    commit('SET_EXIST', response.data.data)
                })
        },

        detail({ commit },payload) {
            // console.log('ini getExist',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/penilaian-bulanan/${payload}`,payload)
                .then(response => {
                    // console.log('ini getExist response ',response.data.data)
                    commit('SET_DETAIL', response.data.data)
                })
        },

        akhir_store({ commit }, payload) {
          
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                Api.post('/lap-akhir-kbtk', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        akhir_store_image({ commit }, payload) {
          
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                Api.post('/lap-akhir-kbtk-image', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        cekinput({ commit },payload) {
            // console.log("ceka pay",payload);
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/lap-akhir-kbtk/${payload}`)
                .then(response => {
                    // console.log('ini getExist response ',response.data.data)
                    commit('SET_CHECK', response.data.data)
                })
        },

        cekinputjenis({ commit },{ id, type,elemen }) {
            const payload = { id, type,elemen };
            // console.log("ini get jenis contoh ",payload);
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post('/lap-akhir-kbtk-tipenilai', payload)
                .then(response => {
                    // console.log('ini get jenis contoh ',response.data.data)
                    commit('SET_CHECKJENIS', response.data.data)
                })
        },

        cekinputjenis3({ commit },{ id, type,elemen }) {
            const payload = { id, type,elemen };
            // console.log("ini get jenis contoh ",payload);
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post('/lap-akhir-kbtk-tipenilai', payload)
                .then(response => {
                    // console.log('ini get jenis contoh ',response.data.data)
                    commit('SET_CHECKJENIS3', response.data.data)
                })
        },

        cekinputjenis4({ commit },{ id, type,elemen }) {
            const payload = { id, type,elemen };
            // console.log("ini get jenis contoh ",payload);
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post('/lap-akhir-kbtk-tipenilai', payload)
                .then(response => {
                    // console.log('ini get jenis contoh ',response.data.data)
                    commit('SET_CHECKJENIS4', response.data.data)
                })
        },

        cekinputjenis5({ commit },{ id, type,elemen }) {
            const payload = { id, type,elemen };
            // console.log("ini get jenis contoh ",payload);
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post('/lap-akhir-kbtk-tipenilai', payload)
                .then(response => {
                    // console.log('ini get jenis contoh ',response.data.data)
                    commit('SET_CHECKJENIS5', response.data.data)
                })
        },

        cekinputjenis6({ commit },{ id, type,elemen }) {
            const payload = { id, type,elemen };
            // console.log("ini get jenis contoh ",payload);
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post('/lap-akhir-kbtk-tipenilai', payload)
                .then(response => {
                    // console.log('ini get jenis contoh ',response.data.data)
                    commit('SET_CHECKJENIS6', response.data.data)
                })
        },

        cekinputjenis7({ commit },{ id, type,elemen }) {
            const payload = { id, type,elemen };
            // console.log("ini get jenis contoh ",payload);
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post('/lap-akhir-kbtk-tipenilai', payload)
                .then(response => {
                    // console.log('ini get jenis contoh ',response.data.data)
                    commit('SET_CHECKJENIS7', response.data.data)
                })
        },

        publikasi({ commit },payload) {
            // console.log('ini getExist',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/publikasi/${payload}`,payload)
                .then(response => {
                    // console.log('ini SET_PUBLIKASI response ',response.data.data)
                    commit('SET_PUBLIKASI', response.data.data)
                })
        },

        publikasi_store({ commit }, payload) {
          
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                Api.post('/publikasi_store', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },
        
        
    }, 
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getData(state) {
            return state.narasikb
        },

        history(state) {
            return state.history
        },

        detail(state) {
            return state.detail
        },

        getExist(state) {
            return state.getExist
        },

        cekinput(state) {
            return state.cekinput
        },

        cekinputjenis(state){
            return state.cekinputjenis
        },

        cekinputjenis3(state){
            return state.cekinputjenis3
        },

        cekinputjenis4(state){
            return state.cekinputjenis4
        },

        cekinputjenis5(state){
            return state.cekinputjenis5
        },

        cekinputjenis6(state){
            return state.cekinputjenis6
        },

        cekinputjenis7(state){
            return state.cekinputjenis7
        },

        publikasi(state){
            return state.publikasi
        },

      
    }

}

export default narasikb