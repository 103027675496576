<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Ekskul</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveEkskul">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Ekskul</label>
                        <input
                          type="text"
                          placeholder="Nama Ekskul"
                          class="form-control"
                          v-model="menu.nama_ekskul"
                        />
                        <small class="text-danger" v-if="validation.nama_ekskul">{{
                          validation.nama_ekskul[0]
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Unit<small></small></label
                        >
                        <select class="form-control" v-model="menu.unit_id">
                          <option value="">-- select unit --</option>
                          <option
                            v-for="parent in parentmenu"
                            :key="parent.id"
                            :value="parent.id"
                          >
                            {{ parent.name }}
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-if="validation.unit_id"
                          >{{ validation.unit_id[0] }}</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Sifat Ekskul</label>
                            <select class="form-control" v-model="menu.sifat">
                                <option value="Pilihan">Pilihan</option>
                                <option value="Wajib">Wajib</option>
                            </select>
                            <small class="text-danger" v-if="validation.sifat">{{ validation.sifat[0] }}</small>
                        </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-ekskul' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "EkskulIndex",
  components: {},
  data() {
    return {
      showMenu: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const menu = reactive({
      nama_ekskul: "",
      unit_id: "",
      sifat: "",
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    //function register, fungsi ini di jalankan ketika form di submit
    function saveEkskul() {
      //define variable
      let nama_ekskul = menu.nama_ekskul;
      let unit_id = menu.unit_id;
      let sifat = menu.sifat;

      //panggil actions "register" dari module "auth"
      store
        .dispatch("ekskul/store", {
          nama_ekskul,
          unit_id,
          sifat,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-ekskul" });
          Swal.fire({
          icon: 'success',
          title: 'Tambah data berhasil',
        });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("ekskul/getUnit");
      await store.dispatch("units/getAll");
      await store.dispatch("permission/getAllPermission");
    });

    //computed
    const parentmenu = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["units/getAll"];
    });

    const keypermission = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["permission/getAllPermission"];
    });

    //return a state and function
    return {
      menu, // <-- state user
      validation, // <-- state validation
      saveEkskul,
      parentmenu, // <-- hook toast
      keypermission 
    };
  },
};
</script>