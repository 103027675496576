<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Pendaftar Aktif</h5>
              <div class="button"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select
                    v-model="data.perPage"
                    class="form-control form-control-sm ml-2"
                    @change="changePerPage"
                  >
                    <option
                      v-for="option in perPageOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input
                  type="text"
                  class="form-control mr-2"
                  v-model="data.search"
                  @keypress.enter="searchData"
                  placeholder="pencarian"
                  aria-label="pencarian"
                  aria-describedby="button-addon2"
                />
                <button
                  @click="searchData"
                  class="btn btn-success mb-0 ms-2"
                  type="button"
                  id="button-addon2"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="row my-3">
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select
                    class="form-control select2"
                    v-model="data.ta"
                    @change="changeTa"
                  >
                    <option value="">-- select tahun ajaran --</option>
                    <option
                      v-for="tahunajaran in tas"
                      :key="tahunajaran.id"
                      :value="tahunajaran.id"
                    >
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select
                    class="form-control select2"
                    v-model="data.unit"
                    @change="changeUnit"
                  >
                    <option value="">-- select Unit --</option>
                    <option
                      v-for="unit in units"
                      :key="unit.id"
                      :value="unit.id"
                    >
                      {{ unit.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select
                    class="form-control select2"
                    v-model="data.ppdb"
                    @change="changePpdb"
                  >
                    <option value="">-- select PPDB--</option>
                    <option
                      v-for="daftar in datapendaftar"
                      :key="daftar.id"
                      :value="daftar.id"
                    >
                      {{ daftar.name }}-{{ daftar.period }}-{{
                        daftar.source
                      }}-{{ daftar.type }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <a
                  :href="`https://api-v2.annisaa-izada.sch.id/export?tahunajaran=${data.ta}&unit=${data.unit}&ppdb=${data.ppdb}&status=${data.status}`"
                  class="btn btn-primary btn-md"
                  ><i class="fa fa-file-excel-o" aria-hidden="true"></i>
                  export
                </a>
              </div>
            </div>
            <div class="table-responsive">
              <table
                id="datatable-search"
                class="table table-flush small-table"
                style="font-size: 12px"
              >
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Data Lengkap</th>
                    <th class="text-center">Tagihan</th>
                    <th class="text-center">Kelengkapan Berkas</th>
                    <th class="text-center">Selesksi</th>
                    <th class="text-center">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="m in pendaftaraktifs.data"
                    :key="m.id"
                    style="height: 1px"
                  >
                    <td>
                      <b>{{ m.fullname }}</b>
                      <br />{{ m.noreg }} <br />
                      Tanggal Daftar
                      {{
                        new Date(m.created_at).toLocaleString("id-ID", {
                          timeZone: "Europe/Moscow",
                          hourCycle: "h23",
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        })
                      }}
                      <br />No Hp {{ m.cellphone ?? "-" }} <br />
                      <span v-if="m.status == 'Registered'">Terdaftar</span>
                      <span v-else-if="m.status == 'Active'">Aktif</span>
                    </td>
                  <td class="text-center">
                      <b-badge
                        v-if="m.pendaftaran != null"
                        class="badge badge-sm badge-success"
                        >pendaftaran</b-badge
                      >
                      <b-badge v-else class="badge badge-sm badge-danger"
                        >pendaftaran</b-badge
                      >
                      <b-badge
                        v-if="m.psikotest != null"
                        class="badge badge-sm badge-success"
                        >psikotest</b-badge
                      >
                      <b-badge v-else class="badge badge-sm badge-danger"
                        >psikotest</b-badge
                      >
                      <b-badge
                        v-if="m.daftar_ulang != null"
                        class="badge badge-sm badge-success"
                        >daftar ulang</b-badge
                      >
                      <b-badge v-else class="badge badge-sm badge-danger"
                        >daftar ulang</b-badge
                      >
                    </td> 
                   
                    <td class="text-center">
                        <span v-for="i in m.mandatory_data" :key="i.id">
                          <b-badge class="badge badge-sm badge-danger" v-if="i.value == false"> 
                            {{i.label}}
                          </b-badge>
                            <b-badge class="badge badge-sm badge-success" v-else> 
                            {{i.label}}
                          </b-badge>
                        </span>
                        

                     
                    </td>
                    <td class="text-center">
                      <input class="form-check-input" type="checkbox" v-model="data.seleksi[m.id]" :value="m.already_selection" :id="`check-${m.id}`" v-if="m.already_selection == 'yes'"  @click="()=>{changeSelected(m.id)}" checked>
                      <input class="form-check-input" type="checkbox" v-model="data.seleksi[m.id]" :value="m.already_selection" :id="`check-${m.id}`"  v-if="m.already_selection == 'no'"  @click="()=>{changeSelected(m.id)}">
                      <span v-if="m.already_selection == null">tidak ada</span>
                    </td>
                    <td class="text-center">
                      <button
                      v-if="datapermissions.includes('pendaftaraktif-edit')" 
                        class="btn btn-primary btn-xs m-0"
                        @click="showModal(m)"
                      >
                        update
                      </button>
                      &nbsp;
                      <router-link  v-if="datapermissions.includes('pendaftaraktif-edit')"  :to="{name: 'admin-pendaftaraktif-show', params:{id: m.id }}" class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-eye"></i></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
          
            <span>
              <p>
                Menampilkan {{ pendaftaraktifs.per_page }} dari
                {{ pendaftaraktifs.total }}
              </p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li
                  class="page-item"
                  :class="{ disabled: pendaftaraktifs.current_page === 1 }"
                >
                  <a
                    class="page-link"
                    @click="changePage(pendaftaraktifs.current_page - 1)"
                    href="#"
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in pendaftaraktifs.to ? pendaftaraktifs.to['links'] : ''" :key="page"   :class="{ 'page-item': true, 'active': page.active === true }"
                >
                  <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li
                  class="page-item"
                  :class="{
                    disabled:
                      pendaftaraktifs.current_page ===
                      pendaftaraktifs.last_page,
                  }"
                >
                  <a
                    class="page-link"
                    @click="changePage(pendaftaraktifs.current_page + 1)"
                    href="#"
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

export default {
  name: "PendaftaraktifIndex",

  setup() {
    const store = useStore();
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: "",
      ta: "",
      ppdb: "",
      unit: "",
      seleksi: [],
      statuskirim: false,
      paynote:"",
      listPaynote:[
        { value: 'Unpaid', text: "Belum Bayar" },
        { value: 'Fullpayment', text: "Sudah Bayar" },
        { value: 'Installment', text: "Cicilan" },
      ],
      pesan: "",
      status:"Active",
      options: [
        { value: 'Passed', text: "Lulus" },
        { value: 'Conditional Active Student', text: "Lulus Bersyarat" },
        { value: 'Backup Active Student', text: "Lulus Cadangan" },
        { value: 'Fail', text: "Tidak Lulus" },
      ],
    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("reg/getDataAktif");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("units/getAll");
      await store.dispatch("reg/getPpdb");
      await store.dispatch("user/getDataPermission");
    });

    const pendaftaraktifs = computed(() => {
      return store.getters["reg/getDataAktif"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const units = computed(() => {
      if (!data.ta) {
        // data.unit = "";
        return "";
       
      }else{
        return store.getters["units/getAll"];
      }
      
    });

    const datapendaftar = computed(() => {
      if (!data.unit) {
        // data.unit = "";
        return "";
       
      }else{
        return store.getters["reg/getPpdb"];
      }
      
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("reg/getDataAktif", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("reg/getDataAktif", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("reg/getDataAktif", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
      });
    };

    const changeTa = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("reg/getDataAktif", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
      });

      await store.dispatch("reg/getPpdb", {
        tasId: data.ta,
        unit: data.unit,
      });
    };

    const changeUnit = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("reg/getDataAktif", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        status: data.status,
        unit: data.unit,
        ppdb: data.ppdb,
      });

      await store.dispatch("reg/getPpdb", {
        tasId: data.ta,
        unit: data.unit,
      });
    };

    const changePpdb = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("reg/getDataAktif", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        status: data.status,
        unit: data.unit,
        ppdb: data.ppdb,
      });
    };

    const modalData = ref(null);
    const isModalVisible = ref(false);

    const showModal = (m) => {
      modalData.value = m;
      isModalVisible.value = true;

      const datas = {
        statuskirims: "",
        statuses:""
      };

      Swal.fire({
        // title: "Update Data",
         // title: "Update Data",
  html: `
    <div class="form-group">
      <h5>${m.fullname}</h5>
      <div class="form-group">
        <label for="">Hasil Tes Seleksi</label><br/>
        <select
          id="statuses-select"
          class="form-control select2"
          v-model="datas.statuses"
        >
          <option value="">-- select status --</option>
          <option value='Passed'>Lulus</option>
          <option value='Conditional Active Student'>Lulus Bersyarat</option>
          <option value='Backup Active Student'>Lulus Cadangan</option>
          <option value='Fail'>Tidak Lulus</option>
        </select>
      
      </div>
      <div class="form-group">
       
        <label for="">Kirim Notifikasi WA</label>
        <input type="checkbox" v-model="datas.statuskirims" id="statuskirims-checkbox">
      </div>
    </div>
  `,


 preConfirm: () => {
      // Retrieve the selected values and update datas.statuses and datas.statuskirims
      datas.statuses = document.getElementById("statuses-select").value;
      datas.statuskirims = document.getElementById("statuskirims-checkbox").checked ? true : false;
    },

        confirmButtonColor: "#3085d6", // Warna tombol "Update" (biru)
        cancelButtonColor: "#d33", // Warna tombol "Batal" (merah)
        confirmButtonText: "Update!",
        cancelButtonText: "Batal",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("ids", m.id);
          formData.append("status", datas.statuses);
          formData.append("statuskirim", datas.statuskirims);
          formData.append("_method", "POST");
          // console.log(formData)
          store.dispatch("reg/updatetolulus", formData).then(() => {
            store.dispatch("reg/getDataAktif");
            //
            // router.push({ name: "admin-menu" });
            router.push({ name: "admin-pendaftarbaru" });

            Swal.fire({
              title: "BERHASIL!",
              text: "Data Berhasil Diupdate!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          });
        }
      });
    };

    function confirmDelete(id) {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch("reg/destroyPendaftarBaru", id).then(() => {
            store.dispatch("reg/getDataAktif");
            //
            // router.push({ name: "admin-menu" });
            router.push({ name: "admin-pendaftarbaru" });
            //alert
            Swal.fire({
              title: "BERHASIL!",
              text: "Data Berhasil Dihapus!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          });
        }
      });
    }

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      pendaftaraktifs,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      changeTa,
      changeUnit,
      changePpdb,
      tas,
      units,
      datapendaftar,
      showModal,
      datapermissions
    
    
    };
  },
};
</script>
  