<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
  
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Data typay nominal 
                 
                </h5>
                <router-link v-if="datapermissions.includes('typaynominal-create')" to="/typaynominal/tambah" class="btn btn-primary btn-sm"
                  >Tambah</router-link
                >
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-between ">
                <div class="col-md-1">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>Show: </span>
                    <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                      <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 d-flex mb-3">
                  <input type="text" class="form-control mr-2"  v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                  <button  @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i class="fa fa-search"></i> </button>
                </div>
              </div>
              <div class="row">
                <div class="col ms-auto my-auto mr-5">
                  <div class="form-group">
                    <label for="">Tahun Ajaran</label>
                    <select
                      class="form-control select2"
                      v-model="data.ta"
                      @change="changeTa"

                    >
                      <option value="">-- select tahun ajaran --</option>
                      <option
                        v-for="tahunajaran in tas"
                        :key="tahunajaran.id"
                        :value="tahunajaran.id"
                        
                      >
                        {{ tahunajaran.desc }} 
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col ms-auto my-auto mr-5">
                  <div class="form-group">
                    <label for="">Unit</label>
                    <select
                      class="form-control select2"
                      v-model="data.unit"
                      @change="changeUnit"
                    >
                      <option value="">-- select Unit --</option>
                      <option
                        v-for="unit in units"
                        :key="unit.id"
                        :value="unit.id"
                        :selected="unit.id === $route.params.idunit"
                      >
                        {{ unit.name}}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="table-responsive">
                <table
                  id="datatable-search"
                  class="table table-flush small-table"
                  style="font-size: 12px"
                >
                  <thead class="thead-light">
                    <tr style="max-height: 5px">
                      <!-- <th>id</th>
                      <th>idunit</th> -->
                      <th>TA</th>
                      <th class="text-center">Unit</th>
                      <th class="text-center">Typay</th>
                      <th class="text-center">Nominal</th>
                      <th class="text-center">Active</th>
                      <th class="text-center">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr v-for="m in typaynominals.data" :key="m.ids" style="height: 1px">
                      <!-- <td>{{m.ids}}</td>
                      <td>{{ m.id_unit }}</td> -->
                      <td>{{ m.desc }}</td>
                      <td class="text-center">{{ m.code }}</td>
                      <td class="text-center">{{ m.nama_typay }}</td>
                      <td class="text-center">{{ m.nominal }}</td>
                      <td class="text-center">{{ m.active }}</td>
                      <td class="text-center">
                        <router-link v-if="datapermissions.includes('typaynominal-edit')" :to="{name: 'admin-typaynominal-edit', params:{id: m.ids, idta:data.ta, idunit:data.unit }}" class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-pen"></i></router-link>
                        <button v-if="datapermissions.includes('typaynominal-delete')" class="btn btn-danger btn-xs m-0" @click="confirmDelete(m.ids)"> <i class="fas fa-trash"></i> </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
             
            </div>
  
            <div class="card-footer d-flex justify-content-between">
              <span>
                <p>Menampilkan {{typaynominals.to}} dari {{typaynominals.total}}</p>
              </span>
              <nav aria-label="Pagination">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: typaynominals.current_page === 1 }">
                    <a class="page-link" @click="changePage(typaynominals.current_page - 1)" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li v-for="page in typaynominals.links" :key="page"   :class="{ 'page-item': true, 'active': page.active === true }"
                      >
                        <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                      </li>
                  <li class="page-item" :class="{ disabled: typaynominals.current_page === typaynominals.last_page }">
                    <a class="page-link" @click="changePage(typaynominals.current_page + 1)" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { computed, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Swal from 'sweetalert2';
  import { useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  
  export default {
    name: "typaynominalIndex",
   
  
    setup() {
      const store = useStore();
      const route = useRoute();
      const data = reactive({
        // currentPage: 1,
        perPage:10,
        search:'',
        ta: route.params.idta ?? '',
        unit: route.params.idunit ?? ''
        
      });
  
      const router = useRouter();
    
  
      onMounted(async () => {
        if(route.params.idta != undefined && route.params.idunit != undefined){
          let jumlah = data.perPage;
          const pencarian = data.search;
        
          await store.dispatch("typaynominal/getData", {
              tasId:route.params.idta,
              unit:route.params.idunit,
              page:1,
              perPage:jumlah,
              search: pencarian,
          });
        }else{
          await store.dispatch("typaynominal/getData");
        }
       
        await store.dispatch("tahunajaran/getAll");
        await store.dispatch("units/getAll");
        await store.dispatch("user/getDataPermission");
      });
  
      const typaynominals = computed(() => {
        return store.getters["typaynominal/getData"];
      });

      const tas = computed(() => {
        return store.getters["tahunajaran/getAll"];
      });

      const datapermissions = computed(() => {
        return store.getters["user/getDataPermission"];
      });

      const units = computed(() => {
        return store.getters["units/getAll"];
      });
  
      const perPageOptions = [5, 10, 15, 25, 50, 100];
  
  
      const changePage = async (page) => {
        let jumlah = data.perPage;
        const pencarian = data.search;
        await store.dispatch("typaynominal/getData", {
          page:page,
          perPage:jumlah,
          search: pencarian,
          tasId:data.ta,
          unit:data.unit
  
        });
      };

      const changeTa = async () => {
        
        let jumlah = data.perPage;
        const pencarian = data.search;
        await store.dispatch("typaynominal/getData", {
          page:1,
          perPage:jumlah,
          search: pencarian,
          tasId:data.ta,
          unit:data.unit
  
        });
      };

      const changeUnit = async (page) => {
        let jumlah = data.perPage;
        const pencarian = data.search;
        await store.dispatch("typaynominal/getData", {
          page:page,
          perPage:jumlah,
          search: pencarian,
          tasId:data.ta,
          unit:data.unit
        });
      };

      
  
      const changePerPage = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("typaynominal/getData", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          tasId:data.ta,
          unit:data.unit
        });
      };
  
      const searchData = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("typaynominal/getData", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          tasId:data.ta,
          unit:data.unit
        });
       
  
    // Lakukan tindakan yang diinginkan dengan nilai searchQuery
      };
  
      function confirmDelete(id) {
        Swal.fire({
          title: 'Konfirmasi',
          text: 'Apakah Anda yakin ingin menghapus data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ya, hapus!',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('typaynominal/destroy', id)
                  .then(() => {
  
                    store.dispatch("typaynominal/getData");
  // 
  // router.push({ name: "admin-typaynominal" });
                    router.push({ name: "admin-typaynominal" });
                    //alert
                    Swal.fire({
                      title: 'BERHASIL!',
                      text: "Data Berhasil Dihapus!",
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 2000
                    })
  
                  })
              }
        });
      }
  
  
      setNavPills();
      setTooltip(store.state.bootstrap);
  
      return {
        typaynominals,
        changePage,
        changePerPage,
        perPageOptions,
        data,
        searchData,
        confirmDelete,
        tas,
        units,
        changeTa,
        changeUnit,
        datapermissions
      
      };
    },
  };
  </script>
  