import Api from '../../api/Api'
const kelas = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        details:{},
        datall:[],
        kelassemesterdepan:[],
        kelassemesterdepannaikkelas:[],
        kelassemesterdepandanaktif:[],
        unitlevel:{},
        dataclassname:[]

    },

    //mutations
    mutations: {
      
        SET_DATA(state, datas) {
            state.datas = datas
        },

        SET_ALL(state, dataall) {
            state.dataall = dataall
        },

        SET_CLASSNAME(state, dataclassname) {
            state.dataclassname = dataclassname
        },
       
        GET_DETAIL(state,  details) {
            state. details =  details
        },

        SET_KELAS_SEMESTER_DEPAN(state, kelassemesterdepan) {
            state.kelassemesterdepan = kelassemesterdepan
        },

        SET_KELAS_SEMESTER_DEPAN_NAIK_KELAS(state, kelassemesterdepannaikkelas) {
            state.kelassemesterdepannaikkelas = kelassemesterdepannaikkelas
        },

        SET_KELAS_SEMESTER_DEPAN_DAN_AKTIF(state,  kelassemesterdepandanaktif){
            state.kelassemesterdepandanaktif= kelassemesterdepandanaktif
        },

        SET_UNITLEVEL_DATA(state, unitlevel) {
            state.unitlevel = unitlevel
          },
      
    },

    _actions: {
      
        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelas/detail/${payload}`)
                .then(response => {
                    // console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },

        getEdit({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelas/edit/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },

        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let tahunajaran = payload ? payload.tasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelas?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${tahunajaran}`)
                .then(response => {
                    // console.log('ini kelas',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getAll({ commit }, payload) {
            let unit = payload ? payload : '';
            // console.log('ini unit', unit)
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelas/getall?unit=${unit}`)
                .then(response => {
                    // console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },

        getClassname({ commit }, payload) {
            let unit = payload ? payload : '';
            // console.log('ini unit', unit)
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelas/getclassname?unit=${unit}`)
                .then(response => {
                   console.log('ini parent',response.data.data)
                    commit('SET_CLASSNAME', response.data.data)
                })
        },

        getUnitlevel({ commit }, payload) {
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelas/unitlevel/${payload}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_UNITLEVEL_DATA', response.data.data)
                })
        },

        getKelasSemesterDepan({ commit }, payload) {
            let unit = payload ? payload : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelassemesterdepan?unit=${unit}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_KELAS_SEMESTER_DEPAN', response.data.data)
                })
        },

        getKelasSemesterDepanNaikKelas({ commit }, payload) {
            let unit = payload ? payload.unitlevel : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelassemesterdepan/naikkelas?unit=${unit}`)
                .then(response => {
                     //console.log('ini kelas naik semester depan',response.data.data)
                    commit('SET_KELAS_SEMESTER_DEPAN_NAIK_KELAS', response.data.data)
                })
        },

        getKelasSemesterDepandanAktif({ commit }, payload) {
            let unit = payload ? payload : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/kelassemesterdepandanaktif?unit=${unit}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_KELAS_SEMESTER_DEPAN_DAN_AKTIF', response.data.data)
                })
        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/kelas/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_DATA', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        store({ commit }, formData) {
            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/kelas', formData)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { kelasId, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

               
                Api.post(`/kelas/update/${kelasId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       

       
        getData(state) {
            return state.datas
        },

        getDetail(state){
            return state.details
        },

        getAll(state){
            return state.dataall
        },

        getClassname(state){
            return state.dataclassname
        },

        getUnitlevel(state){
            return state.unitlevel
        },

        getKelasSemesterDepan(state){
            return state.kelassemesterdepan
        },

        getKelasSemesterDepanNaikKelas(state){
            return state.kelassemesterdepannaikkelas
        },

        getKelasSemesterDepandanAktif(state){
            return state.kelassemesterdepandanaktif
        }

    }

}

export default kelas