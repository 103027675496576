<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Role</h5>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="storeRole">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Nama Role</label>
                    <input
                      type="text"
                      placeholder="Nama Role"
                      class="form-control"
                      v-model="role.name"
                    />
                    <small class="text-danger" v-if="validation.name">{{
                      validation.name[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      v-model="selectAll"
                      @change="toggleAllPermissions"
                      class="form-check-input"
                    />
                    <label>Select All</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-3"
                  v-for="permission in allpermission"
                  :key="permission.id"
                >
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="role.permissions"
                      :value="permission.name"
                      :id="`check-${permission.id}`"
                    />
                    <label
                      class="custom-control-label"
                      :for="`check-${permission.id}`"
                      >{{ permission.name }}</label
                    >
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-12">
                  <button type="submit" class="btn btn-success btn-sm">
                    simpan
                  </button>
                  &nbsp;
                  <router-link
                    :to="{ name: 'admin-role' }"
                    class="btn btn-danger btn-sm"
                    >kembali</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "MenuIndex",
  setup() {
    const validation = ref([]);
    const store = useStore();
    const data = reactive({});
    const router = useRouter();

    const role = reactive({
      name: "",
      permissions: [],
    });

    const selectAll = ref(false);

    onMounted(async () => {
      await store.dispatch("permission/getAll");
    });

    const allpermission = computed(() => {
      return store.getters["permission/getAll"];
    });

    const toggleAllPermissions = () => {
      if (selectAll.value) {
        // Jika selectAll bernilai true, tambahkan semua izin ke dalam array permissions
        role.permissions = allpermission.value.map(
          (permission) => permission.name
        );
      } else {
        // Jika selectAll bernilai false, kosongkan array permissions
        role.permissions = [];
      }
    };

    setNavPills();
    setTooltip(store.state.bootstrap);

    function storeRole() {
      let formData = new FormData();

      formData.append("name", this.role.name);
      Array.from(this.role.permissions).forEach((permission) => {
        formData.append("permissions[]", permission);
      });

      // //console.log('form insert',formData);

      // sending data to action "storeRole" vuex
      store
        .dispatch("roles/store", formData)

        //success
        .then(() => {
          store.dispatch("menu/getMenu");
          //sweet alert
          router.push({ name: "admin-role" });
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });
        })

        //error
        .catch((error) => {
          //console.log(error);
        
          validation.value = error;
          Swal.fire({
            icon: "error",
            title:  validation.value.name[0]
          });
        });
    }

    return {
      allpermission,
      data,
      role,
      router,
      selectAll,
      storeRole,
      toggleAllPermissions,
      validation,
    };
  },

};
</script>
