<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Menu</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveMenu">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Menu</label>
                        <input
                          type="text"
                          placeholder="Nama Menu"
                          class="form-control"
                          v-model="menu.name"
                        />
                        <small class="text-danger" v-if="validation.name">{{
                          validation.name[0]
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Link</label>
                        <input
                          type="text"
                          placeholder="link"
                          class="form-control"
                          v-model="menu.link"
                        />
                        <small class="text-danger" v-if="validation.link">{{
                          validation.link[0]
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Icon</label>
                        <input
                          type="text"
                          placeholder="icon"
                          class="form-control"
                          v-model="menu.icon"
                        />
                        <small class="text-danger" v-if="validation.icon">{{
                          validation.icon[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Position</label>
                        <select class="form-control" v-model="menu.position">
                          <option value="none">Single Menu</option>
                          <option value="parent">Parent Menu</option>
                          <option value="children">Children Menu</option>
                        </select>
                        <small class="text-danger" v-if="validation.position">{{
                          validation.position[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Parent Menu <small>boleh dikosongkan</small></label
                        >
                        <select class="form-control" v-model="menu.parent_id">
                          <option value="">-- select parent menu --</option>
                          <option
                            v-for="parent in parentmenu"
                            :key="parent.id"
                            :value="parent.id"
                          >
                            {{ parent.name }}
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-if="validation.parent_id"
                          >{{ validation.parent_id[0] }}</small
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Urutan Menu</label
                        >
                        <input
                          type="number"
                          placeholder="order"
                          class="form-control"
                          v-model="menu.order"
                        />
                        <small class="text-danger" v-if="validation.order">{{
                          validation.order[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Permission Key</label
                        >
                        <select
                          class="form-control"
                          v-model="menu.key_permission"
                        >
                          <option value="">-- select permission --</option>

                          <option
                            v-for="permission in keypermission"
                            :key="permission.id"
                            :value="permission.key"
                          >
                            {{ permission.key }}
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-if="validation.key_permission"
                          >{{ validation.key_permission[0] }}</small
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-menu' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "MenuIndex",
  components: {},
  data() {
    return {
      showMenu: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const menu = reactive({
      name: "",
      link: "",
      icon: "",
      key_permission: "",
      position: "",
      parent_id: "",
      order: "",
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    //function register, fungsi ini di jalankan ketika form di submit
    function saveMenu() {
      //define variable
      let name = menu.name;
      let link = menu.link;
      let icon = menu.icon;
      let key_permission = menu.key_permission;
      let position = menu.position;
      let parent_id = menu.parent_id;
      let order = menu.order;

      //panggil actions "register" dari module "auth"
      store
        .dispatch("menu/store", {
          name,
          link,
          icon,
          key_permission,
          position,
          parent_id,
          order,
        })
        .then(() => {
          
          //redirect ke dashboard
          router.push({ name: "admin-menu" });
          Swal.fire({
          icon: 'success',
          title: 'Tambah data berhasil',
        });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("menu/getParent");
      await store.dispatch("permission/getAllPermission");
    });

    //computed
    const parentmenu = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["menu/getParent"];
    });

    const keypermission = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["permission/getAllPermission"];
    });

    //return a state and function
    return {
      menu, // <-- state user
      validation, // <-- state validation
      saveMenu,
      parentmenu, // <-- hook toast
      keypermission 
    };
  },
};
</script>