<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Menu</h5>
           
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updateMenu">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Tahun Ajaran<small></small></label
                        >
                        <select class="form-control" v-model="menu.id_ta">
                          <option value="">-- Pilih Tahun Ajaran --</option>
                          <option
                            v-for="parent in tahunajaran"
                            :key="parent.id"
                            :value="parent.id"
                          >
                            {{ parent.desc }}
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-if="validation.id_ta"
                          >{{ validation.id_ta[0] }}</small
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Nama Ekskul<small></small></label
                        >
                        <select class="form-control" v-model="menu.id_ekskul" @change="changeUnit">
                          <option value="">-- Pilih Ekskul --</option>
                          <option
                            v-for="eks in ekskulall.data"
                            :key="eks.id"
                            :value="`${eks.id} ${eks.unit.id}`"
                          >
                            {{ eks.nama_ekskul }} ({{ eks.unit.code }})
                          </option>
                        </select>
                        <small
                          class="text-danger"
                          v-if="validation.id_ekskul"
                          >{{ validation.id_ekskul[0] }}</small
                        >
                      </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Unit Level<small></small></label>
                        <!-- <input type="text" v-model="menu.level"> -->
                        <select class="form-control" v-model="menu.level" multiple style="height: 200px;" @blur="levelUnit()">
                          <option value="">-- pilih Unit Level --</option>
                          <option v-for="u in unitlevels" :key="u.id" :value="u.id">{{ u.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.level">{{ validation.level[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Nama Koordinator</label
                        >
                        <select class="form-control" v-model="menu.id_instructors">
                        <option value="">-- Pilih Koordinator --</option>
                        <option v-for="guru in gurus" :key="guru.id" :value="guru.id">{{ guru.fullname}}</option>
                      </select>
                        <small class="text-danger" v-if="validation.id_instructors">{{ validation.id_instructors[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Pelatih</label>
                        <input
                          type="text"
                          placeholder="Nama Pelatih"
                          class="form-control"
                          v-model="menu.nama_pelatih"
                        />
                        <small class="text-danger" v-if="validation.nama_pelatih">{{
                          validation.nama_pelatih[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Jumlah Peserta Minimal</label>
                        <input
                          type="number"
                          placeholder="0"
                          class="form-control"
                          v-model="menu.jumlah_peserta_minimal"
                        />
                        <small class="text-danger" v-if="validation.jumlah_peserta_minimal">{{
                          validation.jumlah_peserta_minimal[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Jumlah Peserta Maksimal</label>
                        <input
                          type="number"
                          placeholder="99999"
                          class="form-control"
                          v-model="menu.jumlah_peserta_maksimal"
                        />
                        <small class="text-danger" v-if="validation.jumlah_peserta_maksimal">{{
                          validation.jumlah_peserta_maksimal[0]
                        }}</small>
                      </div>
                    </div>
                  </div>
                  

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-ekskulaktif' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "MenuIndex",
  components: {},
  data() {
    return {
      showMenu: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const menu = reactive({
      id_ta: "",
      id_ekskul: "",
      level: "",
      id_instructors:"",
      nama_pelatih:"",
      jumlah_peserta_minimal:"",
      jumlah_peserta_maksimal:"",
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
    function updateMenu() {
      //define variable
      let id_ta = menu.id_ta;
      let id_ekskul = menu.id_ekskul;
      let level = menu.level;
      let id_instructors = menu.id_instructors;
      let nama_pelatih = menu.nama_pelatih;
      let jumlah_peserta_minimal = menu.jumlah_peserta_minimal;
      let jumlah_peserta_maksimal = menu.jumlah_peserta_maksimal;

      let formData = new FormData();

      formData.append("id_ta", id_ta);
      formData.append("id_ekskul", id_ekskul);
      formData.append("level", level);
      formData.append("id_instructors", id_instructors);
      formData.append("nama_pelatih", nama_pelatih);
      formData.append("jumlah_peserta_minimal", jumlah_peserta_minimal);
      formData.append("jumlah_peserta_maksimal", jumlah_peserta_maksimal);
      formData.append("_method", "PUT");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("ekskulaktif/update", {
          menuId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-ekskulaktif" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("units/getAll");
      await store.dispatch("permission/getAllPermission");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("ekskul/getDataAll");
      await store.dispatch('guru/getAll')
      await store.dispatch("ekskulaktif/getDetailMenu", route.params.id);
      levelUnit();
    });

    watch(
      () => store.state.ekskulaktif.detailmenu,
      (newMenu) => {
        if (newMenu) {
          const {
            id_ta,
            id_ekskul,
            id_unit,
            unitlevel,
            id_instructors,
            nama_pelatih,
            jumlah_peserta_minimal,
            jumlah_peserta_maksimal,

            
          } = newMenu;
          menu.id_ta = id_ta;
          menu.id_ekskul = id_ekskul+' '+id_unit;
          menu.level     = JSON.parse(unitlevel);
          menu.id_instructors = id_instructors;
          menu.nama_pelatih = nama_pelatih;
          menu.jumlah_peserta_minimal = jumlah_peserta_minimal;
          menu.jumlah_peserta_maksimal = jumlah_peserta_maksimal;
        }
      }
    );

    //computed
    const parentmenu = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["units/getAll"];
    });

    const tahunajaran = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["tahunajaran/getAll"];
    });

    const ekskulall = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["ekskul/getDataAll"];
    });


    const changeUnit = async () => {
      const selectedValues = menu.id_ekskul.split(' ');
      // const eksId = selectedValues[0];
      const unitId = selectedValues[1];
      await store.dispatch("unitlevel/getAll",
        unitId
      );
      
      // console.log(eksId);
      // console.log(unitId);
    };

    const levelUnit = async () => {
      const selectedValues = menu.id_ekskul.split(' ');
      // const eksId = selectedValues[0];
      const unitId = selectedValues[1];
      await store.dispatch("unitlevel/getAll",
        unitId
      );
      
      // console.log(eksId);
      // console.log(unitId);
    };

    const unitlevels = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["unitlevel/getAll"];
    });

    const gurus = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["guru/getAll"];
    });

    const keypermission = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["permission/getAllPermission"];
    });

    //return a state and function
    return {
      menu, // <-- state user
      validation, // <-- state validation
      updateMenu,
      parentmenu, // <-- hook toast
      keypermission,
      tahunajaran,
      ekskulall,
      changeUnit,
      unitlevels,
      gurus,
      levelUnit,
    };
  },
};
</script>