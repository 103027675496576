<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Penilaian Ekskul</h5>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Nama Ekskul</label>
                        <!-- <select class="form-control" v-model="menu.id_ekskul" @change="changeUnit" readonly disabled>
                          <option value="">-- Pilih Ekskul --</option>
                          <option
                            v-for="eks in ekskulall.data"
                            :key="eks.id"
                            :value="`${eks.id} ${eks.unit.id}`"
                          >
                            {{ eks.nama_ekskul }} ({{ eks.unit.code }})
                          </option>
                        </select> -->
                        <br>
                        <input type="text" :value="eskul.nama_ekskul" class="form-control" disabled>
                </div>
              </div>
              <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Nama Koordinator</label
                        >
                        <select class="form-control" v-model="menu.id_instructors" readonly disabled>
                        <option value="">-- Pilih Koordinator --</option>
                        <option v-for="guru in gurus" :key="guru.id" :value="guru.id">{{ guru.fullname}}</option>
                      </select>
                        <small class="text-danger" v-if="validation.id_instructors">{{ validation.id_instructors[0] }}</small>
                      </div>
              </div>
              <div class="col-md-6">
                  
                      
                  
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row">
              
              <div class="row">
              </div>
              <div class="col-md-12">
                <caption style="float: right;">
                  <p class="badge bg-warning bg-warning text-white">Tekan Enter untuk menyimpan deskripsi</p>
                </caption>
                <div class="clear" style="clear: both;"></div>
                <div class="table-responsive table-container" style="max-height: none;">
                                <table id="datatable-search" class="table table-bordered"
                                    style="border: 2px solid black; font-size: 12px;">
                                    <thead class="thead-light" style="border: 2px solid black;">
                                        <tr style="max-height: 5px; border: 2px solid black;">
                                            <th
                                                style="text-align: center; vertical-align: middle; position: sticky; left: 0; top:0; background-color:#fff; z-index: 2;border: 2px solid black; ">
                                            </th>
                                            <th colspan="4"  style="text-align: center; vertical-align: middle; border: 2px solid black;">Penilaian</th>
                                        </tr>
                                        <tr style="border: 2px solid black;">
                                            <th
                                               width="180" style="text-align: center; vertical-align: middle; position: sticky; left: 0; top:0; background-color:#fff; z-index: 2; border: 2px solid black;">
                                                Nama Siswa
                                            </th>
                                            <th width="120" style="text-align: center; vertical-align: middle; border: 2px solid black;">Predikat</th>
                                            <th   style="text-align: center; vertical-align: middle; border: 2px solid black;">Deskripsi</th>
                                            <th   style="text-align: center; vertical-align: middle; border: 2px solid black;"></th>
                                            <!-- <th   style="text-align: center; vertical-align: middle; border: 2px solid black;">NA</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="m in list.data" :key="m.idkey" style="height: 1px">
                                            <td
                                                style="position: sticky; left: 0; background-color:#fff; z-index:3; border: 2px solid black;">
                                              {{ m.fullname }}
                                            </td>
                                            <td  style="background-color:#fff;z-index: -2; border: 2px solid black;">
                                              <select class="form-control" 
                                                :id-student="m.id_student"
                                                :data-id="m.id_student"
                                                :v-model="menu.id_predikat"
                                                :key="m.id_student" @change="savepredikat">
                                                <option value="0">-- Pilih --</option>
                                                <option
                                                  v-for="eks in predikat"
                                                  :key="eks.id"
                                                  :value="`${eks.id}`"
                                                  :selected="eks.id == m.id_predikat"
                                                >
                                                  {{ eks.nama_predikat_capaian }}
                                                </option>
                                              </select>
                                            </td>
                                            <td  style="background-color:#fff;z-index: -2; border: 2px solid black;">
                                              <textarea 
                                                  name="deskripsi" 
                                                  :id="m.id_student"
                                                  :id-predikat="m.id_predikat"
                                                  :key="m.id_student" 
                                                  :id-student="m.id_student"
                                                  :value="m.description" 
                                                  cols="60" 
                                                  rows="3" 
                                                  maxlength="200"
                                                  style="width: 100%;"
                                                  @keypress.enter="handleKeyUp"
                                              ></textarea>

                                            </td>
                                            <td>
                                              <button class="btn btn-xs btn-primary" 
                                                  @click.prevent="handleKeyUp"
                                                  :id-predikat="m.id_predikat"
                                                  :key="m.id_student" 
                                                  :id-student="m.id_student"><i class="fas fa-save"></i>
                                              </button>
                                            </td>
                                        </tr>
                                        


                                    </tbody>
                                </table>
                            </div>
              </div>
              
            </div>
                <div class="row mt-5">
                    <div class="col-12">
                      <!-- <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button> -->
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-ekskulaktif' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
// import Select2MultipleControl from 'v-select2-multiple-component';

export default {
  name: "MenuIndex",
  components: {
    // Select2MultipleControl
  },
  data() {
    return {
      showMenu: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.eksulid = this.$route.params.id;
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const menu = reactive({
      id_ta: "",
      id_ekskul: "",
      level: "",
      id_instructors:"",
      nama_pelatih:"",
      jumlah_peserta_minimal:"",
      jumlah_peserta_maksimal:"",
      namaunitlevel:"",
      id_unit_level:[],
      id_unit:"",
    });

    const data = reactive({
        // currentPage: 1,
        perPage:10,
        search:'',
        kelas:'',
        unitlevel:'',
        level:'',
        unit:'',
        
      });

      const perPageOptions = [5, 10, 15, 25, 50, 100];
      const changePage = async (page) => {
        let jumlah = data.perPage;
        const pencarian = data.search;
        await store.dispatch("siswa/getSiswaAktifin", {
          page:page,
          perPage:jumlah,
          search: pencarian,
          kelas:data.kelas,
          unit:data.unit,
          unitlevel:data.level,
          id_unit_level:menu.level
        });
      };
  
      const changePerPage = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaAktifin", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          kelas:data.kelas,
          unit:data.unit,
          unitlevel:data.level,
          id_unit_level:menu.level
        });
      };

      const searchData = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("siswa/getSiswaAktifin", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          id_unit_level:menu.level
        });
      }
    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
    function updateMenu() {
      //define variable
      let id_ta = menu.id_ta;
      let id_ekskul = menu.id_ekskul;
      let level = menu.level;
      let id_instructors = menu.id_instructors;
      let nama_pelatih = menu.nama_pelatih;
      let jumlah_peserta_minimal = menu.jumlah_peserta_minimal;
      let jumlah_peserta_maksimal = menu.jumlah_peserta_maksimal;

      let formData = new FormData();

      formData.append("id_ta", id_ta);
      formData.append("id_ekskul", id_ekskul);
      formData.append("level", level);
      formData.append("id_instructors", id_instructors);
      formData.append("nama_pelatih", nama_pelatih);
      formData.append("jumlah_peserta_minimal", jumlah_peserta_minimal);
      formData.append("jumlah_peserta_maksimal", jumlah_peserta_maksimal);
      formData.append("_method", "PUT");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("ekskulaktif/update", {
          menuId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-ekskulaktif" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

   

    watch(
      () => store.state.ekskulaktif.detailmenu,
      (newMenu) => {
        if (newMenu) {
          const {
            id_ta,
            id_ekskul,
            id_unit,
            unitlevel,
            id_instructors,
            nama_pelatih,
            jumlah_peserta_minimal,
            jumlah_peserta_maksimal,
            namaunitlevel,
            id_predikat,

            
          } = newMenu;
          menu.id_ta = id_ta;
          menu.id_ekskul = id_ekskul+' '+id_unit;
          menu.level     = JSON.parse(unitlevel);
          menu.id_instructors = id_instructors;
          menu.nama_pelatih = nama_pelatih;
          menu.jumlah_peserta_minimal = jumlah_peserta_minimal;
          menu.jumlah_peserta_maksimal = jumlah_peserta_maksimal;
          menu.namaunitlevel = namaunitlevel;
          menu.id_unit = id_unit;
          menu.ekskul_id = id_ekskul;
          menu.id_predikat = id_predikat;
        }
      }
    );

    //computed
    const parentmenu = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["units/getAll"];
    });

    const tahunajaran = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["tahunajaran/getAll"];
    });

    const ekskulall = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["ekskul/getDataAll"];
    });


    const changeUnit = async () => {
      const selectedValues = menu.id_ekskul.split(' ');
      // const eksId = selectedValues[0];
      const unitId = selectedValues[1];
      await store.dispatch("unitlevel/getAll",
        unitId
      );
      
      // console.log(eksId);
      // console.log(unitId);
    };

    const levelUnit = async () => {
      const selectedValues = menu.id_ekskul.split(' ');
      // const eksId = selectedValues[0];
      const unitId = selectedValues[1];
      await store.dispatch("unitlevel/getAll",
        unitId
      );
      
      // console.log(eksId);
      // console.log(unitId);
    };

    const unitlevels = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["unitlevel/getAll"];
    });

    const gurus = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["guru/getAll"];
    });

    const keypermission = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["permission/getAllPermission"];
    });

    const SiswaAktifWhereIn = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      // const unitId = [11,12];
      const unitId = [11,12];
      return store.getters["siswa/getSiswaAktifWhereIn",unitId
      ];
    });

    // const SiswaAktifWhereIn = async () => {
    //   // const selectedValues = menu.id_ekskul.split(' ');
    //   // const eksId = selectedValues[0];
    //   const unitId = [11,12];
      
    //   await store.dispatch("siswa/getSiswaAktifWhereIn",
    //     {
    //       id_unit_level: unitId,
    //     }
    //   );
      
    //   // console.log(eksId);
    //   // console.log(unitId);
    // };

    const formattedUnitlevels = computed(() => {
      // return 0;
      //panggil getter dengan nama "getParent" di module "menu" vuex
      // console.log("ini konsole ",SiswaAktifWhereIn);
      return SiswaAktifWhereIn;
      // return SiswaAktifWhereIn.value.map((item) => ({
      //   id: item.id,
      //   text: item.text, // Show name if exists, otherwise an empty string
      // }));
    });

    const datapermissions = computed(() => {
        return store.getters["user/getDataPermission"];
      });

      const siswas = computed(() => {
        return store.getters["siswa/getSiswaAktifin"];
      });

      
      const list = computed(() => {
            return store.getters["ekskulaktif/getPesertabaru"];
            // return 0;
        });

      const predikat = computed(() => {
            return store.getters["ekskulaktif/getpredikat"];
            // return 0;
      });

      const eskul = computed(() => {
            return store.getters["ekskulaktif/getDetailMenu"];
            // return 0;
      });

      // const proxyObject = {};
    onMounted(async () => {
      await store.dispatch("units/getAll");
      await store.dispatch("permission/getAllPermission");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("ekskul/getDataAll");
      await store.dispatch('guru/getAll')
      await store.dispatch("unitlevel/getAll");
      await store.dispatch("ekskulaktif/getDetailMenu", route.params.id);
      levelUnit();
      await store.dispatch("siswa/getSiswaAktifin");
      // await store.dispatch("siswa/getSiswaAktif");
      await store.dispatch("ekskulaktif/getPesertabaru", route.params.id);
      // console.log(route.params);
      await store.dispatch("ekskulaktif/getpredikat",route.params.idunit);
     
    });

    async function savepredikat(event) {
      const id_student = event.target.getAttribute('id-student');
      const id_predikat = event.target.value;
      const id_ekskul   =route.params.id;

      let formData = new FormData();

      formData.append("id_student", id_student);
      formData.append("id_predikat", id_predikat);
      formData.append("id_ekskul_aktif", id_ekskul);
      formData.append("typenilai", 'predikat');

      
                          await store.dispatch('ekskulaktif/penilaianstore', formData)
                            .then(() => {

                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });
    }

    async function handleKeyUp(event) {
      const id_student = event.target.getAttribute('id-student');
      const id_predikat = event.target.getAttribute('id-predikat');
      const description = event.target.value !== '' ? event.target.value : document.getElementById(id_student).value;
      const id_ekskul = route.params.id;

      let formData = new FormData();


      formData.append("id_student", id_student);
      formData.append("id_predikat", id_predikat);
      formData.append("description", description);
      formData.append("id_ekskul_aktif", id_ekskul);
      formData.append("typenilai", 'desription');
      // console.log(formData);
      
                          await store.dispatch('ekskulaktif/penilaianstore', formData)
                            .then(() => {

                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });
    }

    async function addPengajar(id) {
        
        let formData = new FormData();
        formData.append('id_ekskul_aktif',  route.params.id)
        formData.append('students_id', id)
        // console.log('ini form',formData);
        await store.dispatch('ekskulaktif/storepeserta', formData)
          .then(() => {
              Swal.fire({
                  title: "BERHASIL!",
                  text: "Data Berhasil Ditambahkan!",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
              });

              // store.dispatch("pengajar/getData");
              store.dispatch("ekskulaktif/getPesertabaru",route.params.id);
          })
          .catch((error) => {
              validation.value = error;
              Swal.fire({
                  title: "GAGAL!",
                  text:   validation.value.error,
                  icon: "error",
                  showConfirmButton: false,
                  timer: 2000,
              });
          });
    }

    function cancelPengajar(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('ekskulaktif/destroypeserta', id)
                .then(() => {
                  Swal.fire({
                    title: 'BERHASIL!',
                    text: "Data Berhasil Dihapus!",
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                  })
                  store.dispatch("ekskulaktif/getPesertabaru",route.params.id);
                })
            }
      });
    }


    //return a state and function
    return {
      menu, // <-- state user
      validation, // <-- state validation
      updateMenu,
      parentmenu, // <-- hook toast
      keypermission,
      tahunajaran,
      ekskulall,
      changeUnit,
      unitlevels,
      gurus,
      levelUnit,
      formattedUnitlevels,
      SiswaAktifWhereIn,
      siswas,
      data,
      datapermissions,
      perPageOptions,
      changePage,
      changePerPage,
      searchData,
      addPengajar,
      cancelPengajar,
      list,
      predikat,
      savepredikat,
      handleKeyUp,
      eskul,
    };
  },
};
</script>