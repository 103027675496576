<template>
    <div class="container-fluid p-5">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                            <h5>Input Nilai Sikap</h5>
                            <div class="d-flex gap-4 items-center">
                                <p>Wali Kelas : {{ guru.fullname }}</p>
                                <router-link :to="{name:'admin-perwalian'}" class="btn btn-sm btn-danger">Kembali</router-link>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlInput1">Pilih kelas yang diajar:<span
                                            class="text-danger">*</span></label>
                                    <select class="form-control" v-model="data.kelasid" @change="changeSiswa">
                                        <option value="">-- select kelas --</option>
                                        <option v-for="k in kelas" :key="k.id" :value="k.id" :id-ta="k.id_ta">{{ k.classname
                                        }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!-- {{ data.kelasid }} -->

                        <div class="mt-5" v-if="data.kelasid > 0">
                           
                         
                            <div class="table-responsive table-container">
                                <table >
                                    <thead >
                                        <tr>
                                            <th >
                                            </th>
                                            <th v-for="ranah in kategori" :key="ranah.id"
                                                
                                                :colspan="`${ranah.generate}`">
                                                {{ ranah.subkategori.generate }}{{ ranah.nama_kategori }}</th>
                                        </tr>
                                        <tr >
                                            <th
                                               >
                                                Nama Siswa</th>
                                            <template v-for="ranah in kategori">
                                                <th v-for="n in ranah.generate" :key="n"
                                                   
                                                    align="center">{{ n }}</th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr v-for="m in siswa" :key="m.id" >
                                            <td
                                               >
                                                {{ m.nama }} </td>
                                            <template v-for="ranah in kategori" :key="ranah.id">
                                              
                                                <td v-for="(n, index) in ranah.generate" :key="index" align="center"
                                                   >
                                                    <!-- {{ getNilai(m.id_siswa, ranah.id, ranah.id_sub, n) }} -->
                                                    <select class="form-control"
                                                        @change="handleSelectChange(m.id_siswa, ranah.id, ranah.id_sub, n, $event)">
                                                        <option
                                                            v-if="getNilai(m.id_siswa, ranah.id, ranah.id_sub, n)"
                                                            :value="getNilai(m.id_siswa, ranah.id, ranah.id_sub, n)">
                                                            {{ getNilai(m.id_siswa, ranah.id, ranah.id_sub, n) }}
                                                        </option>
                                                        <option v-else value="">Pilih</option>
                                                        <option value="">Pilih</option>
                                                        <option v-for="k in filteredKetNilai(ranah)" :value="k.kode"
                                                            :key="k.id">{{
                                                                k.kode }}
                                                        </option>
                                                    </select>


                                                </td>
                                            </template>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                            



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapMutations } from "vuex";
import { computed, ref, reactive, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Swal from 'sweetalert2';

// const body = document.getElementsByTagName("body")[0];
export default {
    name: "inputnilai",
    // created() {
    //     this.$store.state.hideConfigButton = true;
    //     this.toggleDefaultLayout();
    //     body.classList.remove("bg-gray-100");
    // },
    // beforeUnmount() {
    //     this.$store.state.hideConfigButton = false;
    //     this.toggleDefaultLayout();
    //     body.classList.add("bg-gray-100");
    // },

    setup() {

        const inputRefs = ref({});

        const data = reactive({
            kelasid: '',
        });

        const guru = reactive({
            fullname: '',
            nickname: '',
            gender: '',
            birthplace: '',
            birthdate: '',
            marital_status: '',
            wni: '',
            religion: '',
            edulevel: '',
            address: '',
            id_province: '',
            id_city: '',
            id_district: '',
            id_subdistrict: '',
            cellphone: '',
            email: '',
            lang: '',
            nuptk: '',
        });


        const validation = ref([]);
        const store = useStore();
        const route = useRoute();

        onMounted(async () => {
            await store.dispatch("guru/getDetail", route.params.id);
            await store.dispatch("bebanmengajar/getKelasWalasData", route.params.id);
            // await store.dispatch("bebanmengajar/getSiswaSikap")
            await store.dispatch("keterangannilai/getAll");
            await store.dispatch("kategorisikap/getKategoriMapelWalas");
            const inputs = document.querySelectorAll('.form-nilai');
            inputs.forEach((input) => {
                input.addEventListener('keydown', focusNextInput());
            });

        });

        onUnmounted(() => {
            const inputs = document.querySelectorAll('.form-nilai');
            inputs.forEach((input) => {
                input.removeEventListener('keydown', focusNextInput());
            });
        });

        const kelas = computed(() => {
            return store.getters["bebanmengajar/getKelasWalasData"];
        });

        const siswa = computed(() => {
            return store.getters["bebanmengajar/getSiswaSikapwalas"];
        });

        const kategori = computed(() => {
            return store.getters["kategorisikap/getKategoriMapelWalas"];
        });

        const changeSiswa = async () => {
            await store.dispatch("nilaisikap/getData", data.kelasid);
            await store.dispatch("bebanmengajar/getSiswaSikapwalas", data.kelasid);
            await store.dispatch("kategorisikap/getKategoriMapelWalas", data.kelasid);
        };

        const ketnilai = computed(() => {
            return store.getters["keterangannilai/getAll"] || [];
        });

        const filteredKetNilai = (ranah) => {
            
            const keterangannilai_id = ranah.keterangannilai_id || [];
            return ketnilai.value.filter((k) => keterangannilai_id.includes(k.id));
        };

        const nilaisikap = computed(() => {
            return store.getters["nilaisikap/getData"];
        });


        const focusNextInput = (e) => {


            var total = 0;

            // Loop melalui setiap objek dalam kategori.value dan tambahkan jumlahnya ke total
            for (var i = 0; i < kategori.value.length; i++) {
                total += kategori.value[i].jumlah_generate;
            }

            // Hasilnya akan disimpan dalam variabel 'total'
            // console.log("Total jumlah generate:", total);


            // const posisi = index;

            if (e.key === 'Enter' || e.key === 'Tab') {
                e.preventDefault();
                const targetInput = e.target;
                const inputs = document.querySelectorAll('.form-nilai');
                const currentIndex = Array.from(inputs).indexOf(targetInput);

                const nextIndex = (currentIndex + total) % inputs.length;
                inputs[nextIndex].focus();
            }

        };

        
       
        const getNilai = (idSiswa, idKategori, idSubkategori, order) => {
            const nilai = nilaisikap.value.find(item => {
                console.log('items',item.id_siswa, item.id_kategori, item.id_subkategori, item.order)
                console.log('is', idSiswa, idKategori, idSubkategori, order)
                return (
                    item.id_siswa === idSiswa &&
                    item.id_kategori === idKategori &&
                    item.id_subkategori === idSubkategori &&
                    item.order === order
                );
            });

            return nilai ? nilai.nilai : '';

        };

        async function handleSelectChange(idsiswa, kategoriItemId, subkategoriItemId, order, event) {
            try {
                // Aksi yang ingin Anda lakukan ketika nilai elemen select berubah
                const selectedValue = event.target.value;
 
                // console.log("Selected value changed to:", selectedValue);

                let formData = new FormData();
                formData.append('nilai', selectedValue)
                formData.append('id_siswa', idsiswa)
                formData.append('id_subkategori', subkategoriItemId)
                formData.append('id_kategori', kategoriItemId)
                formData.append('kelas_id', data.kelasid)
                formData.append('order', order)

                console.log('ini form', formData)

                await store.dispatch('nilaisikap/store', formData);

                // Tambahan logika atau pemanggilan fungsi sesuai kebutuhan
            } catch (error) {
                validation.value = error;

                Swal.fire({
                    title: "GAGAL!",
                    text: validation.value.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                });

                console.error("An error occurred:", error);
            }
        }





        watch(
            () => store.state.guru.details,
            (newguru) => {
                //console.log('newguru',newguru);
                if (newguru) {
                    const {
                        fullname,
                        nickname,
                        gender,
                        birthplace,
                        birthdate,
                        marital_status,
                        wni,
                        religion,
                        edulevel,
                        address,
                        id_province,
                        id_city,
                        id_district,
                        id_subdistrict,
                        cellphone,
                        email,
                        lang,
                        nuptk,
                    } = newguru;
                    guru.fullname = fullname;
                    guru.nickname = nickname;
                    guru.gender = gender;
                    guru.birthplace = birthplace;
                    guru.birthdate = birthdate;
                    guru.marital_status = marital_status;
                    guru.wni = wni;
                    guru.religion = religion;
                    guru.edulevel = edulevel;
                    guru.address = address;
                    guru.id_province = id_province;
                    guru.id_city = id_city;
                    guru.id_district = id_district;
                    guru.id_subdistrict = id_subdistrict;
                    guru.cellphone = cellphone;
                    guru.email = email;
                    guru.lang = lang;
                    guru.nuptk = nuptk;

                }
            }
        );

        return {

            validation,
            guru,
            kelas,
            data,
            changeSiswa,
            siswa,
            kategori,
            nilaisikap,
            inputRefs,
            focusNextInput,
            ketnilai,
            filteredKetNilai,
            handleSelectChange,
            getNilai


        };
    },

    methods: {
        ...mapMutations(["toggleDefaultLayout"]),



    },
};
</script>

<style>
.table-container {
  overflow: auto;
  max-height: 400px;
}

table.table-bordered th {
  position: sticky;
  top: 0;
  background-color: white;
}

.form-nilai {
  max-width: 100px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

td {
  color: black;
}
</style>


<style scoped>
/* Add your modal styling here */
.modal {
  /* Add styles for overlay/background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;

}


.modal-content {
  /* Add styles for the modal content */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Gaya untuk tabel utama */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  /* Untuk memberikan sedikit ruang antara tabel dan elemen lainnya */
}

/* Gaya untuk elemen header tabel (thead) */
thead {
  background-color: #f2f2f2;
  /* Warna latar belakang untuk header */
}

/* Gaya untuk sel header tabel (th) */
th,
td {
  border: 1px solid #ddd;
  /* Garis tepi untuk sel header dan sel data */
  padding: 8px;
  /* Ruang dalam di dalam sel */
  text-align: left;
  /* Aligment teks ke kiri */
}

/* Gaya untuk baris tabel (tr) */
tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Warna latar belakang untuk baris genap */
}

/* Gaya saat kursor berada di atas sel */
td:hover {
  background-color: #e0e0e0;
  /* Warna latar belakang saat kursor di atas sel */
}
</style>