import Api from '../../api/Api'
const masterp5 = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        datall:[],
        details:{},
        datamapel: []
    },

    //mutations
    mutations: {
      
        SET_DATA(state, datas) {
            state.datas = datas
        },

        
        SET_DATA_MAPEL(state, datamapel) {
            state.datamapel = datamapel
        },


        SET_ALL(state, dataall) {
            state.dataall = dataall
        },
       
        GET_DETAIL(state,  details) {
            state. details =  details
        },
      
    },

    _actions: {
      
        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/masterp5/edit/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },
        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            // let page = payload ? payload.page : ''
            let id = payload ? payload.id : ''
          
            // let perpage = payload ? payload.perPage : ''
            // let pencarian = payload ? payload.search : ''
            // let tahunajaran = payload ? payload.tasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/masterp5/detail/${id}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getMapel({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let tahunajaran = payload ? payload.tasId : ''
            let id_unit = payload ? payload.idunit : ''
            let id_unitlevel = payload ? payload.idunitlevel : ''
            let id_ta = payload ? payload.idta : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pengajar/listmapelgurubyunitta/${id_unit}/${id_ta}/${id_unitlevel}?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${tahunajaran}`)
                .then(response => {
                //    console.log('ini mapel',response.data.data)
                    commit('SET_DATA_MAPEL', response.data.data)
                })
        },


        getAll({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/masterp5/all`)
                .then(response => {
                    //console.log('masterp5 all',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },

     

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/masterp5/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_user', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        store({ commit }, formData) {
            //console.log('ini user', formData);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/masterp5', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { masterp5Id, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

               
                Api.post(`/masterp5/update/${masterp5Id}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       

       
        getData(state) {
            return state.datas
        },

        getDetail(state){
            return state.details
        },

        getAll(state){
            return state.dataall
        },

        getMapel(state){
            return state.datamapel
        }
    }

}

export default masterp5