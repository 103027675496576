<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Laporan Akhir KB-TK</h5>
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                 
                  

                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>Identitas Siswa </h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <table class="table">
                                          <thead>
                                            <tr>
                                                <td >Nama Lengkap</td>
                                                <td>:</td>
                                                <td>{{siswa.fullname}}</td>
                                            </tr>
                                            <tr>
                                                <td >Kelas</td>
                                                <td>:</td>
                                                <td>
                                                  {{ student.unitlevel?student.unitlevel.kelas.classname:'' }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td >Jenis Laporan</td>
                                                <td >:</td>
                                                <td v-if="this.$route.params.bidang == 'wali kelas'">{{  this.$route.params.bidang }}</td>
                                                <td v-if="this.$route.params.bidang != 'wali kelas'">
                                                  Guru Bidang - {{  this.$route.params.bidang }}
                                                </td>
                                            </tr>
                                            <tr>
                                              <td>Semester</td>
                                              <td >:</td>
                                              <td>
                                                {{ tahunajaran.desc }}
                                                <input type="hidden" name="id_ta" v-model="tahunajaran.id">
                                              </td>
                                            </tr>
                                          </thead>
                                        </table>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="">
                        <div class="col-md-12">
                          <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" v-if="this.$route.params.bidang == 'wali kelas'">
                                Agama dan Budi Pekerti
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" v-if="this.$route.params.bidang == 'wali kelas'">
                                Jati Diri
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false" v-if="this.$route.params.bidang == 'wali kelas'">
                                STEM
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="p5-tab" data-bs-toggle="tab" data-bs-target="#p5" type="button" role="tab" aria-controls="p5" aria-selected="false" v-if="this.$route.params.bidang == 'wali kelas'">
                                P5
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="olahraga-tab" data-bs-toggle="tab" data-bs-target="#olahraga" type="button" role="tab" aria-controls="olahraga" aria-selected="false" v-if="this.$route.params.bidang == 'Olahraga' || this.$route.params.bidang == 'wali kelas'">
                                Olahraga
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="bahasa-inggris-tab" data-bs-toggle="tab" data-bs-target="#bahasa-inggris" type="button" role="tab" aria-controls="bahasa-inggris" aria-selected="false" v-if="this.$route.params.bidang == 'inggris' || this.$route.params.bidang == 'wali kelas'">
                                Bahasa Inggris
                              </button>
                            </li>
                            <li class="nav-item" role="presentation">
                              <button class="nav-link" id="Komputer-tab" data-bs-toggle="tab" data-bs-target="#Komputer" type="button" role="tab" aria-controls="Komputer" aria-selected="false" v-if="this.$route.params.bidang == 'Komputer' || this.$route.params.bidang == 'wali kelas'">
                                Komputer
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-12">
                          <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab" v-if="this.$route.params.bidang == 'wali kelas'">
                              <div class="mt-5">
                                <!-- <h3></h3> -->
                                <!-- <p>This is the content of the Home tab.</p> -->
                                <form @submit.prevent="saveLaporanNarasi($event,1)">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label for=""><h4>Agama dan Budi Pekerti</h4></label><br>
                                        <textarea 
                                            name="narasi" 
                                            :value="cekdata ? cekdata.narasi:''"
                                            @keypress.enter="saveLaporanNarasiEnter($event,1)" 
                                            
                                            cols="110" rows="10" maxlength="1000">
                                        </textarea>
                                        <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 1</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange($event,1)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 2</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange2($event,1)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 3</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange3($event,1)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto3">{{ validation.foto3[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata ? cekdata.foto1 != '' :'' ">
                                          <div id="gambarlama">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata.foto1" alt="" style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage1" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata ? cekdata.foto2 != '' :'' ">
                                          <div id="gambarlama2">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata.foto2" alt=""  style="max-height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage2"  style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata ? cekdata.foto3 != '' :'' ">
                                          <div id="gambarlama3">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata.foto3" alt=""  style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage3" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <button type="submit" class="btn btn-sm btn-success">Simpan</button>
                                      &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-akhir-kb' , params:{id:this.$route.params.instructor }}"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab" v-if="this.$route.params.bidang == 'wali kelas'">
                              <div class="mt-5">
                                <!-- <h3>Jati Diri</h3> -->
                                <!-- <p>This is the content of the Home tab.</p> -->
                                <form @submit.prevent="saveLaporanNarasi($event,2)">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label for=""><h4>Jati Diri</h4></label><br>
                                        <textarea 
                                            name="narasi" 
                                            :value="cekdatadua ? cekdatadua.narasi : ''" 
                                            @keypress.enter="saveLaporanNarasiEnter($event,2)" 
                                            cols="110" rows="10" maxlength="1000">
                                        </textarea>

                                        <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 1</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange21($event,2)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 2</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange22($event,2)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 3</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange23($event,2)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto3">{{ validation.foto3[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdatadua ? cekdatadua.foto1 != '' :'' ">
                                          <div id="gambarlama21">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdatadua.foto1" alt="" style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage21" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdatadua ? cekdatadua.foto2 != '' :'' ">
                                          <div id="gambarlama22">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdatadua.foto2" alt=""  style="max-height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage22" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdatadua ? cekdatadua.foto3 != '' :'' ">
                                          <div id="gambarlama23">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdatadua.foto3" alt=""  style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage23" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <button type="submit" class="btn btn-sm btn-success">Simpan</button>
                                      &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-akhir-kb' , params:{id:this.$route.params.instructor }}"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab" v-if="this.$route.params.bidang == 'wali kelas'">
                              <div class="mt-3">
                                <!-- <h3>
                                  Dasar - Dasar Literasi, Matematika, Sains, Teknologi, Rekayasa dan Seni
                                </h3> -->
                                <!-- <p>This is the content of the Home tab.</p> -->
                                <form @submit.prevent="saveLaporanNarasi($event,3)">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label for=""><h4>Dasar - Dasar Literasi, Matematika, Sains, Teknologi, Rekayasa dan Seni</h4></label><br>
                                        <textarea 
                                            name="narasi" 
                                            :value="cekdata3 ? cekdata3.narasi : ''" 
                                            @keypress.enter="saveLaporanNarasiEnter($event,3)" 
                                            cols="110" rows="10" maxlength="1000">
                                        </textarea>

                                        <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 1</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange31($event,3)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 2</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange32($event,3)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 3</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange33($event,3)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto3">{{ validation.foto3[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata3 ? cekdata3.foto1 != '' :'' ">
                                          <div id="gambarlama31">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata3.foto1" alt="" style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage31" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata3 ? cekdata3.foto2 != '' :'' ">
                                          <div id="gambarlama32">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata3.foto2" alt=""  style="max-height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage32" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata3 ? cekdata3.foto3 != '' :'' ">
                                          <div id="gambarlama33">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata3.foto3" alt=""  style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage33" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <button type="submit" class="btn btn-sm btn-success">Simpan</button>
                                      &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-akhir-kb' , params:{id:this.$route.params.instructor }}"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="p5" role="tabpanel" aria-labelledby="p5-tab" v-if="this.$route.params.bidang == 'wali kelas'">
                              <div class="mt-3">
                                <!-- <h3>
                                  Projek Penguatan Profil Pancasila
                                </h3> -->
                                <!-- <p>This is the content of the Home tab.</p> -->
                                <form @submit.prevent="saveLaporanNarasi($event,4)">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label for=""><h4>Projek Penguatan Profil Pelajar Pancasila</h4></label><br>
                                        <textarea 
                                            name="narasi" 
                                            :value="cekdata4 ? cekdata4.narasi : ''" 
                                            @keypress.enter="saveLaporanNarasiEnter($event,4)" 
                                            cols="110" rows="10" maxlength="1000">
                                        </textarea>

                                        <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 1</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange41($event,4)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 2</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange42($event,4)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 3</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange43($event,4)"  placeholder="upload raport"/>
                                        <small class="text-danger" v-if="validation.foto3">{{ validation.foto3[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata4 ? cekdata4.foto1 != '' :'' ">
                                          <div id="gambarlama41">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata4.foto1" alt="" style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage41" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata4 ? cekdata4.foto2 != '' :'' ">
                                          <div id="gambarlama42">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata4.foto2" alt=""  style="max-height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage42" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata4 ? cekdata4.foto3 != '' :'' ">
                                          <div id="gambarlama43">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata4.foto3" alt=""  style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage43" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <button type="submit" class="btn btn-sm btn-success">Simpan</button>
                                      &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-akhir-kb' , params:{id:this.$route.params.instructor }}"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="olahraga" role="tabpanel" aria-labelledby="olahraga-tab" 
                            v-if="this.$route.params.bidang == 'Olahraga' || this.$route.params.bidang == 'wali kelas'"
                            :class="{ 'active show': this.$route.params.bidang === 'Olahraga' }"
                            >
                              <div class="mt-3">
                                <form @submit.prevent="saveLaporanNarasi($event,5)">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label for=""><h4>Olahraga</h4></label><br>
                                        <textarea 
                                            name="narasi" 
                                            :value="cekdata5 ? cekdata5.narasi : ''" 
                                            @keypress.enter="saveLaporanNarasiEnter($event,5)" 
                                            cols="110" rows="10" maxlength="1000"
                                            :disabled="this.$route.params.bidang === 'wali kelas'"
                                            >
                                        </textarea>

                                        <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 1</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control" 
                                        @change="handleFileChange51($event,5)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 2</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control" 
                                        @change="handleFileChange52($event,5)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 3</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control" 
                                        @change="handleFileChange53($event,5)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto3">{{ validation.foto3[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata5 ? cekdata5.foto1 != '' :'' ">
                                          <div id="gambarlama51">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata5.foto1" alt="" style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage51" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata5 ? cekdata5.foto2 != '' :'' ">
                                          <div id="gambarlama52">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata5.foto2" alt=""  style="max-height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage52" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata5 ? cekdata5.foto3 != '' :'' ">
                                          <div id="gambarlama53">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata5.foto3" alt=""  style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage53" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <button type="submit" class="btn btn-sm btn-success"
                                      :disabled="this.$route.params.bidang === 'wali kelas'"
                                      >
                                        Simpan
                                      </button>
                                      &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-akhir-kb' , params:{id:this.$route.params.instructor }}"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="bahasa-inggris" role="tabpanel" aria-labelledby="bahasa-inggris-tab" 
                            v-if="this.$route.params.bidang == 'inggris' || this.$route.params.bidang == 'wali kelas'"
                            :class="{ 'active show': this.$route.params.bidang === 'inggris' }">
                              <div class="mt-3">
                                <!-- <h3>
                                  Projek Penguatan Profil Pancasila
                                </h3> -->
                                <!-- <p>This is the content of the Home tab.</p> -->
                                <form @submit.prevent="saveLaporanNarasi($event,6)">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label for=""><h4>Bahasa Inggris</h4></label><br>
                                        <textarea 
                                            name="narasi" 
                                            :value="cekdata6 ? cekdata6.narasi : ''" 
                                            @keypress.enter="saveLaporanNarasiEnter($event,6)"
                                            :disabled="this.$route.params.bidang === 'wali kelas'"
                                            cols="110" rows="10" maxlength="1000">
                                        </textarea>

                                        <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 1</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control" 
                                        @change="handleFileChange61($event,6)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 2</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control" 
                                        @change="handleFileChange62($event,6)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 3</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control" 
                                        @change="handleFileChange63($event,6)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto3">{{ validation.foto3[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata6 ? cekdata6.foto1 != '' :'' ">
                                          <div id="gambarlama61">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata6.foto1" alt="" style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage61" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata6 ? cekdata6.foto2 != '' :'' ">
                                          <div id="gambarlama62">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata6.foto2" alt=""  style="max-height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage62" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata6 ? cekdata6.foto3 != '' :'' ">
                                          <div id="gambarlama63">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata6.foto3" alt=""  style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage63" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <button type="submit" class="btn btn-sm btn-success"
                                      :disabled="this.$route.params.bidang === 'wali kelas'"
                                      >
                                        Simpan
                                      </button>
                                      &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-akhir-kb' , params:{id:this.$route.params.instructor }}"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="tab-pane fade" id="Komputer" role="tabpanel" aria-labelledby="Komputer-tab" 
                            v-if="this.$route.params.bidang == 'Komputer' || this.$route.params.bidang == 'wali kelas'"
                            :class="{ 'active show': this.$route.params.bidang === 'Komputer' }">
                              <div class="mt-3">
                                <!-- <h3>
                                  Projek Penguatan Profil Pancasila
                                </h3> -->
                                <!-- <p>This is the content of the Home tab.</p> -->
                                <form @submit.prevent="saveLaporanNarasi($event,7)">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label for=""><h4>Komputer</h4></label><br>
                                        <textarea 
                                            name="narasi" 
                                            :value="cekdata7 ? cekdata7.narasi : ''" 
                                            @keypress.enter="saveLaporanNarasiEnter($event,7)" 
                                            cols="110" rows="10" maxlength="1000"
                                            :disabled="this.$route.params.bidang === 'wali kelas'"
                                            >
                                        </textarea>

                                        <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 1</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control" 
                                        @change="handleFileChange71($event,7)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 2</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  
                                        @change="handleFileChange72($event,7)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label for="">Foto 3</label><br>
                                        <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  
                                        @change="handleFileChange73($event,7)"  placeholder="upload raport"
                                        :disabled="this.$route.params.bidang === 'wali kelas'"
                                        />
                                        <small class="text-danger" v-if="validation.foto3">{{ validation.foto3[0] }}</small>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata7 ? cekdata7.foto1 != '' :'' ">
                                          <div id="gambarlama71">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata7.foto1" alt="" style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage71" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata7 ? cekdata7.foto2 != '' :'' ">
                                          <div id="gambarlama72">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata7.foto2" alt=""  style="max-height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage72" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <div v-if="cekdata7 ? cekdata7.foto3 != '' :'' ">
                                          <div id="gambarlama73">
                                            <img :src="'http://localhost:8000/public/report/akhirkbtk/' + cekdata7.foto3" alt=""  style="height:4cm;width:auto">
                                          </div>
                                        </div>
                                        <img id="previewImage73" style="max-height:4cm;width:auto;display: none;"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <button type="submit" class="btn btn-sm btn-success"
                                      :disabled="this.$route.params.bidang === 'wali kelas'"
                                      >
                                        Simpan
                                      </button>
                                      &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-akhir-kb' , params:{id:this.$route.params.instructor }}"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- History -->
                    <div class="row m-3 table-responsive">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="">History Perkembangan</label>
                            <table border="1" style="table-layout: fixed; width: 100%;border-collapse: separate;
                            border: 1px solid grey;
                            border-spacing: 0px;
                            ">
                              <thead>
                                <tr style="text-align:center">
                                  <!-- <th>Semester</th> -->
                                  <th>Bulan</th>
                                  <th>Jenis Laporan</th>
                                  <th style="width: 45%;">Komentar</th>
                                  <th>Foto 1</th>
                                  <th>Foto 2</th>
                                  <!-- <th>Action</th> -->
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="m in filteredData" :key="m.id" style="height: 1px">
                                  <!-- <td>{{ m.tas.desc }} </td> -->
                                  <td valign="top">&nbsp;{{ m.bulans.nama }}</td>
                                  <td valign="top">&nbsp;{{ m.elemen }} </td>
                                  <td valign="top" style="word-wrap: break-word !important;padding: 7px;">
                                    <div style="word-wrap: break-word !important;text-align: justify;">
                                      {{ limitText(m.narasi, 1000) }}
                                    </div>
                                  </td>
                                  <td align="center">
                                    <a v-if="m.foto1 != null " :href="'http://localhost:8000/public/report/' + m.foto1" target="_blank">
                                      <!-- <i class="fas fa-image"></i> -->
                                      <div style="width: 100px;">
                                        <img :src="'http://localhost:8000/public/report/' + m.foto1" alt=""  class="image img-thumbnail img-rounded" style="height:4cm;width:auto">
                                      </div>
                                    </a>
                                  </td>
                                  <td align="center">
                                    <a v-if="m.foto2 != null "  :href="'http://localhost:8000/public/report/' + m.foto2" target="_blank">
                                      <!-- <i class="fas fa-image"></i> -->
                                      <div style="width: 100px;">
                                        <img :src="'http://localhost:8000/public/report/' + m.foto2" alt="" class="image img-thumbnail img-rounded" style="height:4cm;width:auto">
                                      </div>
                                    </a>
                                  </td>
                                  <!-- <td>
                                    <router-link v-if="$route.params.bidang.toLowerCase() === m.elemen.toLowerCase()" :to="{name: 'admin-laporan-bulanan-kb-edit', params:{idsiswa:m.id_student,id: m.id,instructor:this.$route.params.instructor}}" class="btn btn-warning btn-xs m-0 me-2"> <i class="fas fa-edit"></i></router-link>
                                    <router-link v-if="$route.params.bidang.toLowerCase() === m.elemen.toLowerCase()" :to="{name: 'admin-laporan-bulanan-kb-print', params:{id: m.id } }" class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-print"></i></router-link>
                                  </td> -->
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted, watch, computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
  
  export default {
    name: "KBEdit",
    components: {},
    data() {
      return {
        showsiswa: false,
      };
    },
    methods: {
      limitText(text, limit) {
        if (text.length > limit) {
          return text.substring(0, limit) + '...';
        }
        return text;
      },
    },
    computed: {
      filteredData() {
        // const bidang = this.$route.params.bidang;
        return this.history.data
      }
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state
      const siswa = reactive({
        
        foto1:'',
        foto2:'',
      });

      const variable = reactive({
        id_unit: "",
        id_ta:"",
        id_student: "",
        bulan: "",
        tahun: "",
        elemen: "",
        narasi: "",
        foto1:"",
        foto2:"",
      });
      


      const listbulan = computed(() => {
            return store.getters["global/getBulan"];
      });


      const tahunajaran = computed(() => {
            return store.getters["tahunajaran/getActive"];
      });

      const student = computed(() => {
            return store.getters["reg/getDetailSiswa"];
      });

      const history = computed(() => {
        return store.getters["narasikb/history"];
      });

      const cekdata = computed(() => {
            return store.getters["narasikb/cekinput"];
      });

      const cekdatadua = computed(() => { // jati diri
            return store.getters["narasikb/cekinputjenis"];
      });

      const cekdata3 = computed(() => { // STEM
            return store.getters["narasikb/cekinputjenis3"];
      });

      const cekdata4 = computed(() => { // STEM
            return store.getters["narasikb/cekinputjenis4"];
      });

      const cekdata5 = computed(() => { // STEM
            return store.getters["narasikb/cekinputjenis5"];
      });

      const cekdata6 = computed(() => { // STEM
            return store.getters["narasikb/cekinputjenis6"];
      });

      const cekdata7 = computed(() => { // STEM
            return store.getters["narasikb/cekinputjenis7"];
      });

  
      //store vuex
      const store = useStore();

      const router = useRouter();

      const validation = ref([]);
  


    const route = useRoute();


      onMounted(async () => {
        // console.log("onlun ",route.params);
        await store.dispatch("reg/getDetailSiswa", route.params.id);
        await store.dispatch("narasikb/cekinput", route.params.id);
        await store.dispatch("narasikb/cekinputjenis", { id: route.params.id, type: 2, elemen:route.params.bidang });
        await store.dispatch("narasikb/cekinputjenis3", { id: route.params.id, type: 3, elemen:route.params.bidang });
        await store.dispatch("narasikb/cekinputjenis4", { id: route.params.id, type: 4, elemen:route.params.bidang });
        await store.dispatch("narasikb/cekinputjenis5", { id: route.params.id, type: 5, elemen:route.params.bidang });
        await store.dispatch("narasikb/cekinputjenis6", { id: route.params.id, type: 6, elemen:route.params.bidang });
        await store.dispatch("narasikb/cekinputjenis7", { id: route.params.id, type: 7, elemen:route.params.bidang });
        await store.dispatch("global/getBulan");
        await store.dispatch("tahunajaran/getActive");
        await store.dispatch("narasikb/history",{id_student:route.params.id,elemen:route.params.bidang});
        // await store.dispatch("narasikb/getExist", {id:route.params.id, bidang:route.params.bidang });
      });
  
      watch(
        () => store.state.reg.pesertadetail,
        (newsiswa) => {
          //console.log('newsiswa',newsiswa);
          if (newsiswa) {
            const {
                fullname,
                nickname,
                gender,
                birthplace,
                birthdate,
                tingkatan,
                sekolah,
                id_unitlevel,
                id,
            } = newsiswa;
            siswa.fullname = fullname;
            siswa.nickname = nickname;
            siswa.gender = gender;
            siswa.tempatlahir = birthplace;
            siswa.tanggallahir = birthdate;
            siswa.tingkatan = tingkatan;
            siswa.sekolah = sekolah;
            siswa.id_unitlevel = id_unitlevel;
            siswa.id = id;       
            
            
          }
        }
      );

      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        raport: {
          file: '',
          id_studentactive:''
        },
        
      });

      async function handleFileChange(e,id) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto1_old', this.cekdata.foto1);
        formData.append('foto1', this.variable.foto1);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage1");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama").style.display = "none";
          document.getElementById("previewImage1").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange2(e,id) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage2");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama2").style.display = "none";
          document.getElementById("previewImage2").style.display = "block";
          reader.readAsDataURL(image);



      }

      async function handleFileChange3(e,id) {
        let image = (this.variable.foto3 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto3 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto3', this.variable.foto3);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage3");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama3").style.display = "none";
          document.getElementById("previewImage3").style.display = "block";
          reader.readAsDataURL(image);
      }

      async function handleFileChange21(e,id) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto1_old', this.cekdatadua.foto1);
        formData.append('foto1', this.variable.foto1);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage21");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama21").style.display = "none";
          document.getElementById("previewImage21").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange22(e,id) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto2_old', this.cekdatadua.foto2);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage22");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama22").style.display = "none";
          document.getElementById("previewImage22").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange23(e,id) {
        let image = (this.variable.foto3 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto3 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto3_old', this.cekdatadua.foto3);
        formData.append('foto3', this.variable.foto3);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage23");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama23").style.display = "none";
          document.getElementById("previewImage23").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange31(e,id) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto1_old', this.cekdata3.foto1);
        formData.append('foto1', this.variable.foto1);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage31");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama31").style.display = "none";
          document.getElementById("previewImage31").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange32(e,id) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto2_old', this.cekdata3.foto2);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage32");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama32").style.display = "none";
          document.getElementById("previewImage32").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange33(e,id) {
        let image = (this.variable.foto3 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto3 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto3_old', this.cekdata3.foto3);
        formData.append('foto3', this.variable.foto3);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage33");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama33").style.display = "none";
          document.getElementById("previewImage33").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange41(e,id) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto1_old', this.cekdata4.foto1);
        formData.append('foto1', this.variable.foto1);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage41");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama41").style.display = "none";
          document.getElementById("previewImage41").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange42(e,id) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto2_old', this.cekdata4.foto2);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage42");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama42").style.display = "none";
          document.getElementById("previewImage42").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange43(e,id) {
        let image = (this.variable.foto3 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto3 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto3_old', this.cekdata4.foto3);
        formData.append('foto3', this.variable.foto3);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage43");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama43").style.display = "none";
          document.getElementById("previewImage43").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange51(e,id) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto1_old', this.cekdata5.foto1);
        formData.append('foto1', this.variable.foto1);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage51");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama51").style.display = "none";
          document.getElementById("previewImage51").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange52(e,id) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto2_old', this.cekdata5.foto2);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage52");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama52").style.display = "none";
          document.getElementById("previewImage52").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange53(e,id) {
        let image = (this.variable.foto3 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto3 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto3_old', this.cekdata5.foto3);
        formData.append('foto3', this.variable.foto3);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage53");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama53").style.display = "none";
          document.getElementById("previewImage53").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange61(e,id) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto1_old', this.cekdata6.foto1);
        formData.append('foto1', this.variable.foto1);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage61");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama61").style.display = "none";
          document.getElementById("previewImage61").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange62(e,id) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto2_old', this.cekdata6.foto2);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage62");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama62").style.display = "none";
          document.getElementById("previewImage62").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange63(e,id) {
        let image = (this.variable.foto3 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto3 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto3_old', this.cekdata6.foto3);
        formData.append('foto3', this.variable.foto3);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage63");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama63").style.display = "none";
          document.getElementById("previewImage63").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange71(e,id) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto1_old', this.cekdata7.foto1);
        formData.append('foto1', this.variable.foto1);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage71");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama71").style.display = "none";
          document.getElementById("previewImage71").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange72(e,id) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto2_old', this.cekdata7.foto2);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage72");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama72").style.display = "none";
          document.getElementById("previewImage72").style.display = "block";
          reader.readAsDataURL(image);          
      }

      async function handleFileChange73(e,id) {
        let image = (this.variable.foto3 = e.target.files[0]);
        console.log(id)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto3 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }

        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('tipe_nilai', id);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append('foto3_old', this.cekdata7.foto3);
        formData.append('foto3', this.variable.foto3);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store_image", formData)
          .then(() => {
             console.log("berhasil");
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });

        const reader = new FileReader();
          reader.onload = (event) => {
              // Set pratinjau gambar ke suatu elemen HTML, misalnya sebuah <img> tag
              const previewImage = document.getElementById("previewImage73");
              previewImage.src = event.target.result;
          };

          // Membaca konten gambar sebagai URL data
          document.getElementById("gambarlama73").style.display = "none";
          document.getElementById("previewImage73").style.display = "block";
          reader.readAsDataURL(image);          
      }




      async function saveLaporanNarasi(event,type_nilai) {
        console.log('narasi isi : ', event.target.narasi.value);
        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('narasi', event.target.narasi.value);
        formData.append('tipe_nilai', type_nilai);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store", formData)
          .then(() => {
            router.push({ name: 'admin-laporan-akhir-kb', params: { id:route.params.instructor } });
            Swal.fire({
              icon: "success",
              title: "Simpan data berhasil",
            });
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });


      }

      async function saveLaporanNarasiEnter(event,type_nilai) {
        // console.log('narasi isi : ', event);
        let formData = new FormData();
        formData.append('id_unit', this.student.unitlevel?this.student.unitlevel.id_unit:0);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('elemen', route.params.bidang);
        formData.append('narasi', event.target.value);
        formData.append('tipe_nilai', type_nilai);
        formData.append('classrooms', this.student.unitlevel?this.student.unitlevel.kelas.id:0);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/akhir_store", formData)
          .then(() => {
            //  const result = store.dispatch("narasikb/cekinput",siswa.id);
            //   console.log("Hasil getter cekinput:", result.id);
          })
          .catch((error) => {
            validation.value = error ? error:'';
          });


      }


      

      

      //return a state and function
      return {
        siswa, // <--
        listbulan,
        variable,
        handleFileChange,
        handleFileChange2,
        handleFileChange3,
        handleFileChange21,
        handleFileChange22,
        handleFileChange23,
        handleFileChange31,
        handleFileChange32,
        handleFileChange33,
        handleFileChange41,
        handleFileChange42,
        handleFileChange43,
        handleFileChange51,
        handleFileChange52,
        handleFileChange53,
        handleFileChange61,
        handleFileChange62,
        handleFileChange63,
        handleFileChange71,
        handleFileChange72,
        handleFileChange73,
        data,
        validation,
        tahunajaran,
        // getexist,
        saveLaporanNarasi,
        saveLaporanNarasiEnter,
        student,
        cekdata,
        cekdatadua,
        cekdata3,
        cekdata4,
        cekdata5,
        cekdata6,
        cekdata7,
        history,
      };
    },
  };
  </script>
  <style scoped>
  table,th,td {
    border-width:1px !important;
  }
</style>