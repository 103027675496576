import Api from '../../api/Api'
const matapelajaran = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        databyunit: [],
        datall:[],
        details:{}
    },

    //mutations
    mutations: {
      
        SET_DATA(state, datas) {
            state.datas = datas
        },

        SET_BYUNIT(state, databyunit) {
            state.databyunit = databyunit
        },


        SET_ALL(state, dataall) {
            state.dataall = dataall
        },
       
        GET_DETAIL(state,  details) {
            state. details =  details
        },
      
    },

    _actions: {
      
        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/matapelajaran/edit/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },
        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/matapelajaran?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getAll({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let idunit = payload ? payload.idunit : ''
          
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/matapelajaran/all?id_unit=${idunit}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },

        getByUnit({ commit }, payload) {
           
            let idunit = payload ? payload.idunit : ''
            let id = payload ? payload.id : ''
           
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/matapelajaran/getbyunit?id_unit=${idunit}&id=${id}`)
                .then(response => {
                    // console.log('iniget by unit',response.data.data)
                    commit('SET_BYUNIT', response.data.data)
                })
        },
        
        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/matapelajaran/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_user', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        store({ commit }, formData) {
            //console.log('ini user', formData);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/matapelajaran', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { mapelId, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

               
                Api.post(`/matapelajaran/update/${mapelId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       

       
        getData(state) {
            return state.datas
        },

        getByUnit(state){
            return state.getbyunit
        },

        getDetail(state){
            return state.details
        },

        getAll(state){
            return state.dataall
        }
    }

}

export default matapelajaran