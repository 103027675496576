<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
  
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Data List Kelas</h5>
               
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-between ">
                <div class="col-md-1">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>Show: </span>
                    <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                      <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 d-flex mb-3">
                  <input type="text" class="form-control mr-2"  v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                  <button  @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i class="fa fa-search"></i> </button>
                </div>
              </div>
              <div class="table-responsive">
                <table
                  id="datatable-search"
                  class="table table-flush small-table"
                  style="font-size: 12px"
                >
                  <thead class="thead-light">
                    <tr style="max-height: 5px">
                      <th>Tahun Ajaran</th>
                      <th>Kelas</th>
                      <!-- <th>Level</th> -->
                      <th>Wali Kelas</th>
                      <!-- <th>Guru Pendamping</th> -->
                     
                      <th class="text-right">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="m in kelass.data" :key="m.id" style="height: 1px">
                      <td>{{ m.ta ? m.ta.desc : '' }}</td>
                      <td>{{ m.master_kelas ? m.master_kelas.classname : '' }}</td>
                      <!-- <td>{{ m.master_kelas ? m.master_kelas.unit_level.unit.code : ''}}</td> -->
                      <td>{{ m.wali_kelas ? m.wali_kelas.fullname : ''}} <br> {{ m.guru_pendamping ? m.guru_pendamping.fullname : '' }} </td>
                      <!-- <td>{{ m.guru_pendamping ? m.guru_pendamping.fullname : '' }}</td> -->
                      <td>{{ m.guru_pendamping ? m.guru_pendamping.total_student_count : '' }} </td>
                     
                    
                      <td>
                        <!-- Mengecek apakah m.master_kelas tidak null -->

                        <router-link :to="{name: 'admin-absenkelassiswa', params:{id: m.idkelas }}"  class="btn btn-primary btn-xs m-0 me-1"> <i class="fas fa-calendar"></i> &nbsp;Absensi
                        </router-link>

                        <router-link :to="{ name: 'admin-ekskulaktif-peserta-rekap', params: { id: m.idkelas } }"  class="btn btn-warning btn-xs m-0 me-1"> <i class="fas fa-table"></i> &nbsp;Ekskul
                        </router-link>
                        
                        <a href="#" v-if="m.master_kelas && m.master_kelas.unit_level.unit.id != 1" class="btn btn-primary btn-xs m-0 me-1">
                          <i class="fas fa-table"></i> &nbsp;Nilai
                        </a>
                        <router-link v-if="m.master_kelas && m.master_kelas.unit_level.unit.id != 1"
                        :to="{name: 'admin-nilaisikapsiswa', params:{id: m.idkelas }}"
                                     class="btn btn-success btn-xs m-0 me-1">
                            <i class="fas fa-pen"></i> &nbsp; Sikap 
                        </router-link>
                        

                        <router-link v-if="m.master_kelas && m.master_kelas.unit_level.unit.id != 1"
                        :to="{ name: 'admin-masterp5-inputnilai', params: { id: m.idkelas } }"
                        class="btn btn-success btn-xs m-0 me-1">
               <i class="fas fa-pen"></i> &nbsp;P5
           </router-link>
                       
                        <router-link v-if="m.master_kelas && m.master_kelas.unit_level.unit.id != 1"
                       
                        :to="{name: 'admin-nilaiwalassiswa', params:{id: m.idkelas }}"
                        class="btn btn-success btn-xs m-0 me-1">
               <i class="fas fa-pen"></i> &nbsp;Komentar
           </router-link>
        
           


           <router-link :to="{ name: 'admin-siswakelasraport', params: { id: m.idkelas } }" v-if="m.master_kelas && m.master_kelas.unit_level.unit.id != 1"
           class="btn btn-info btn-xs m-0 me-1"> <i class="fas fa-print"></i> &nbsp;MID </router-link>
           <a href="#" v-if="m.master_kelas && m.master_kelas.unit_level.unit.id != 1" class="btn btn-info btn-xs m-0 me-1">
            <i class="fas fa-print"></i> &nbsp;Akhir
          </a>

          <router-link :to="{ name: 'admin-siswakelasraport', params: { id: m.idkelas } }" v-if="m.master_kelas && m.master_kelas.unit_level.unit.id != 1"
          class="btn btn-info btn-xs m-0 me-1"> <i class="fas fa-print"></i> &nbsp;P5</router-link>
          
          

          

          <!-- <a :href="{ name: 'admin-peserta-bulanan-kb', params: { id: m.idkelas } }" v-if="m.master_kelas && m.master_kelas.unit_level.unit.id == 1" class="btn btn-danger btn-xs m-0 me-1">
            <i class="fas fa-print"></i> &nbsp;Laporan Bulanan KB TK {{ m.idkelas }}
          </a> -->
          <router-link :to="{ name: 'admin-peserta-bulanan-kb', params: { id: m.idkelas } }" v-if="m.master_kelas && m.master_kelas.unit_level.unit.id == 1" 
          class="btn btn-info btn-xs m-0 me-1"> <i class="fas fa-print"></i> &nbsp;Bulanan KB TK</router-link>

          <router-link :to="{ name: 'admin-peserta-akhir-kb', params: { id: m.idkelas } }" v-if="m.master_kelas && m.master_kelas.unit_level.unit.id == 1" 
          class="btn btn-info btn-xs m-0 me-1"> <i class="fas fa-print"></i> &nbsp; Akhir KB TK</router-link>


          <!-- <a href="#" v-if="m.master_kelas && m.master_kelas.unit_level.unit.id == 1" class="btn btn-danger btn-xs m-0 me-1">
            <i class="fas fa-print"></i> &nbsp;Laporan Akhir KB TK
          </a> -->

        
             
                    </td>
                    
                    </tr>
                  </tbody>
                </table>
              </div>
            
             
            </div>
  
            <div class="card-footer d-flex justify-content-between">
              <span>
                <p>Menampilkan {{kelass.to}} dari {{kelass.total}}</p>
              </span>
              <nav aria-label="Pagination">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: kelass.current_page === 1 }">
                    <a class="page-link" @click="changePage(kelass.current_page - 1)" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li v-for="page in kelass.links" :key="page"   :class="{ 'page-item': true, 'active': page.active === true }"
                  >
                    <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                  </li>
                  <li class="page-item" :class="{ disabled: kelass.current_page === kelass.last_page }">
                    <a class="page-link" @click="changePage(kelass.current_page + 1)" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { computed, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Swal from 'sweetalert2';
  import { useRouter } from "vue-router";
  
  export default {
    name: "aturannilaiIndex",
  
    setup() {
      const store = useStore();
      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        tasId:''

        
        
      });
  
      const router = useRouter();
  
      onMounted(async () => {
        await store.dispatch("kelas/getData");
      });
  
      const kelass = computed(() => {
        return store.getters["kelas/getData"];
      });
  
      const perPageOptions = [5, 10, 15, 25, 50, 100];
  
  
      const changePage = async (page) => {
        let jumlah = data.perPage;
        const pencarian = data.search;
        await store.dispatch("kelas/getData", {
          page:page,
          perPage:jumlah,
          search: pencarian,
          tasId:''
  
        });
      };
  
      const changePerPage = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("kelas/getData", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          tasId:''
        });
      };
  
      const searchData = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("kelas/getData", {
          page: 1,
          perPage:jumlah,
          search: pencarian,
          tasId:''

        });
       
  
    // Lakukan tindakan yang diinginkan dengan nilai searchQuery
      };
  
      function confirmDelete(id) {
        Swal.fire({
          title: 'Konfirmasi',
          text: 'Apakah Anda yakin ingin menghapus data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ya, hapus!',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('kelas/destroy', id)
                  .then(() => {
  
                    store.dispatch("kelas/getData");
  // 
  // router.push({ name: "admin-aturannilai" });
                    router.push({ name: "admin-aturannilai" });
                    //alert
                    Swal.fire({
                      title: 'BERHASIL!',
                      text: "Data Berhasil Dihapus!",
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 2000
                    })
  
                   
  
                  })
              }
        });
      }
  
  
      setNavPills();
      setTooltip(store.state.bootstrap);
  
      return {
        kelass,
        changePage,
        changePerPage,
        perPageOptions,
        data,
        searchData,
        confirmDelete
      
      };
    },
  };
  </script>
  