import Api from '../../api/Api'
const reg = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
        pesertaBaru:[],
        pesertaActive:[],
        pesertaLulus:[],
        pesertatidakLulus:[],
        pesertaGagal:[],
        ppdb : [],
        page: 1,
        pesertadetail: {},
        nisdetail: {},
        pesertatanpanis:[]
    },

    //mutations
    mutations: {
       
        GET_BARU(state, pesertaBaru) {
            state.pesertaBaru = pesertaBaru
        },
        GET_ACTIVE(state, pesertaActive) {
            state.pesertaActive = pesertaActive
        },

        GET_LULUS(state, pesertaLulus) {
            state.pesertaLulus = pesertaLulus
        },

        GET_TIDAK_LULUS(state, pesertatidakLulus) {
            state.pesertatidakLulus = pesertatidakLulus
        },

        GET_GAGAL(state,  pesertaGagal) {
            state.pesertaGagal =  pesertaGagal
        },

        GET_TANPA_NIS(state, pesertatanpanis) {
            state.pesertatanpanis = pesertatanpanis
        },

        SET_PPDB(state, ppdb) {
            state.ppdb = ppdb
        },

        GET_DETAIL_SISWA(state, pesertadetail) {
            //set value state "role"
            state.pesertadetail = pesertadetail
        },

        GET_DETAIL_NIS(state, nisdetail) {
            //set value state "role"
            state.nisdetail = nisdetail
        },


      
    },

    _actions: {

        getPpdb({ commit }, payload) {
            let taId = payload ? payload.tasId : '';
            let unit = payload ? payload.unit : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ppdb?tahunajaran=${taId}&unit=${unit}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_PPDB', response.data.data)
                })
        },

        getDataBaru({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let taId = payload ? payload.tasId : '';
          
            let unit = payload ? payload.unit : '';
            let status = payload ? payload.status : '';
            let ppdb = payload ? payload.ppdb : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pendaftarbaru?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${taId}&unit=${unit}&ppdb=${ppdb}&status=${status}`)
                .then(response => {
                    // console.log('ini pendaftar baru',response.data.data)
                    commit('GET_BARU', response.data.data)
                })
        },

        getDataTanpaNis({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
        
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswatanpanis?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    //  //console.log('ini parent',response.data.data)
                    commit('GET_TANPA_NIS', response.data.data)
                })
        },

        getDataAktif({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let taId = payload ? payload.tasId : '';
            let unit = payload ? payload.unit : '';
            let status = payload ? payload.status : '';
            let ppdb = payload ? payload.ppdb : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pendaftarbaru/active?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${taId}&unit=${unit}&ppdb=${ppdb}&status=${status}`)
                .then(response => {
                    console.log('ini aktif',response.data.data)
                    commit('GET_ACTIVE', response.data.data)
                })
        },

        getDataLulus({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let taId = payload ? payload.tasId : '';
            let paynote = payload ? payload.paynote : '';
            let unit = payload ? payload.unit : '';
            let status = payload ? payload.status : '';
            let ppdb = payload ? payload.ppdb : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pendaftarbaru/lulus?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${taId}&unit=${unit}&ppdb=${ppdb}&status=${status}&paynote=${paynote}`)
                .then(response => {
                    //console.log('ini parent',response.data.data)
                    commit('GET_LULUS', response.data.data)
                })
        },

        getDataTidakLulus({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let taId = payload ? payload.tasId : '';
            let paynote = payload ? payload.paynote : '';
            let unit = payload ? payload.unit : '';
            let status = payload ? payload.status : '';
            let ppdb = payload ? payload.ppdb : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pendaftarbaru/fail?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${taId}&unit=${unit}&ppdb=${ppdb}&status=${status}&paynote=${paynote}`)
                .then(response => {
                    //console.log('ini parent',response.data.data)
                    commit('GET_TIDAK_LULUS', response.data.data)
                })
        },


        getDetailSiswa({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pendaftarbaru/detail/${payload}`)
                .then(response => {
                     console.log('ini detail', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL_SISWA', response.data.data)

                })

        },

        getDetailNis({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/pendaftarbaru/shownis/${payload}`)
                .then(response => {
                     //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL_NIS', response.data.data)

                })
        },

        updatetoactive({ commit }, payload) {
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/pendaftarbaru/updatetoactive', payload)

                    .then(response => {

                        // //console.log(response.data.data)
                        // //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })
            })
        },

        updatetolulus({ commit }, payload) {
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/pendaftarbaru/updatetolulus', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        updatepaynote({ commit }, payload) {
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/pendaftarbaru/updatepaynote', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        updateNis({ commit }, payload) {
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/pendaftarbaru/updatenis', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        generateNis({ commit }, payload) {
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/generatenis', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        destroyPendaftarBaru({ commit }, payload) {
            // Define variable token
            
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.delete(`/pendaftarbaru/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_MENU
                        commit('GET_BARU', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },
        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getPpdb(state) {
            return state.ppdb
        },

        getDataBaru(state) {
            return state.pesertaBaru
        },

        getDataAktif(state){
            return state.pesertaActive
        },

        getDataLulus(state){
            return state.pesertaLulus
        },

        getDetailSiswa(state){
            return state.pesertadetail
        },

        getDetailNis(state){
            return state.nisdetail
        },

        getDataTidakLulus(state){
            return state.pesertatidakLulus
        },

        getDataTanpaNis(state){
            return state.pesertatanpanis
        }
       
    }

}

export default reg