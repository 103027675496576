<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Edit setting app</h5>
             
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="updatesettingapp">
                    <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="exampleFormControlInput1">Nama </label>
                            <input
                              type="text"
                              placeholder="name"
                              class="form-control"
                              v-model="settingapp.name"
                            />
                            <small class="text-danger" v-if="validation.name">{{
                              validation.name[0]
                            }}</small>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="form-group">
                            <label for="exampleFormControlInput1">Tagline </label>
                            <input
                              type="text"
                              placeholder="name"
                              class="form-control"
                              v-model="settingapp.tagline"
                            />
                            <small class="text-danger" v-if="validation.tagline">{{
                              validation.tagline[0]
                            }}</small>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Alamat sekolah</label>
                                  <input
                                  type="text"
                                  placeholder="address"
                                  class="form-control"
                                  v-model="settingapp.address"
                                />
                                <small class="text-danger" v-if="validation.address">{{
                                  validation.address[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-3">
                              <div class="form-group">
                                  <label for="">Sign Area</label>
                                  <input
                                  type="text"
                                  placeholder="sign area"
                                  class="form-control"
                                  v-model="settingapp.sign_area"
                                />
                                <small class="text-danger" v-if="validation.sign_area">{{
                                  validation.sign_area[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-3">
                              <div class="form-group">
                                  <label for="">Phone</label>
                                  <input
                                  type="text"
                                  placeholder="phone"
                                  class="form-control"
                                  v-model="settingapp.phone"
                                />
                                <small class="text-danger" v-if="validation.phone">{{
                                  validation.phone[0]
                                }}</small>
                              </div>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-8">
                              <div class="form-group">
                                  <label for="">Email</label>
                                  <input
                                  type="text"
                                  placeholder="email"
                                  class="form-control"
                                  v-model="settingapp.email"
                                />
                                <small class="text-danger" v-if="validation.email">{{
                                  validation.phone[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label for="">Fax</label>
                                  <input
                                  type="text"
                                  placeholder="fax"
                                  class="form-control"
                                  v-model="settingapp.fax"
                                />
                                <small class="text-danger" v-if="validation.fax">{{
                                  validation.fax[0]
                                }}</small>
                              </div>
                          </div>
                      </div>

                      <hr>

                      <div class="row">
                          <div class="col-12">
                              <h6>Data Link Aplikasi Sekolah</h6>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">PSB Link</label>
                                  <input
                                  type="text"
                                  placeholder="psb link"
                                  class="form-control"
                                  v-model="settingapp.psb_link"
                                />
                                <small class="text-danger" v-if="validation.psb_link">{{
                                  validation.psb_link[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Akademik Link</label>
                                  <input
                                  type="text"
                                  placeholder="akademik link"
                                  class="form-control"
                                  v-model="settingapp.akademik_link"
                                />
                                <small class="text-danger" v-if="validation.akademik_link">{{
                                  validation.akademik_link[0]
                                }}</small>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Perpustakaan Link</label>
                                  <input
                                  type="text"
                                  placeholder="perpustakaan link"
                                  class="form-control"
                                  v-model="settingapp.perpustakaan_link"
                                />
                                <small class="text-danger" v-if="validation.perpustakaan_link">{{
                                  validation.perpustakaan_link[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Admin panel Link</label>
                                  <input
                                  type="text"
                                  placeholder="adminpanel link"
                                  class="form-control"
                                  v-model="settingapp.adminpanel_link"
                                />
                                <small class="text-danger" v-if="validation.adminpanel_link">{{
                                  validation.adminpanel_link[0]
                                }}</small>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">PPDB Title</label>
                                  <input
                                  type="text"
                                  placeholder="ppdb title"
                                  class="form-control"
                                  v-model="settingapp.ppdb_title"
                                />
                                <small class="text-danger" v-if="validation.ppdb_title">{{
                                  validation.ppdb_title[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">PPDB Subtitle</label>
                                  <input
                                  type="text"
                                  placeholder="ppdb_subtitle"
                                  class="form-control"
                                  v-model="settingapp.ppdb_subtitle"
                                />
                                <small class="text-danger" v-if="validation.ppdb_subtitle">{{
                                  validation.ppdb_subtitle[0]
                                }}</small>
                              </div>
                          </div>
                      </div>


                      <hr>

                      <div class="row">
                          <div class="col-12">
                              <h6>Data Media Sosial Sekolah</h6>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Facebook</label>
                                  <input
                                  type="text"
                                  placeholder="facebook"
                                  class="form-control"
                                  v-model="settingapp.facebook"
                                />
                                <small class="text-danger" v-if="validation.facebook">{{
                                  validation.facebook[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">twitter</label>
                                  <input
                                  type="text"
                                  placeholder="twitter"
                                  class="form-control"
                                  v-model="settingapp.twitter"
                                />
                                <small class="text-danger" v-if="validation.twitter">{{
                                  validation.twitter[0]
                                }}</small>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Instagram</label>
                                  <input
                                  type="text"
                                  placeholder="instagram"
                                  class="form-control"
                                  v-model="settingapp.instagram"
                                />
                                <small class="text-danger" v-if="validation.instagram">{{
                                  validation.instagram[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Youtube</label>
                                  <input
                                  type="text"
                                  placeholder="youtube"
                                  class="form-control"
                                  v-model="settingapp.youtube"
                                />
                                <small class="text-danger" v-if="validation.youtube">{{
                                  validation.youtube[0]
                                }}</small>
                              </div>
                          </div>
                      </div>

                      <hr>

                      <div class="row">
                          <div class="col-12">
                              <h6>Data Whatsapp Gateway</h6>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Whatsapp Nomor</label>
                                  <input
                                  type="text"
                                  placeholder="whatsapp nomor"
                                  class="form-control"
                                  v-model="settingapp.wa_number"
                                />
                                <small class="text-danger" v-if="validation.wa_number">{{
                                  validation.wa_number[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Whatsapp Token</label>
                                  <input
                                  type="text"
                                  placeholder="whatsapp token"
                                  class="form-control"
                                  v-model="settingapp.wa_token"
                                />
                                <small class="text-danger" v-if="validation.wa_token">{{
                                  validation.wa_token[0]
                                }}</small>
                              </div>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-12">
                              <h6>Data Rekening Sekolah</h6>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label for="">Nomor Rekening</label>
                                  <input
                                  type="text"
                                  placeholder="nomor rekeningr"
                                  class="form-control"
                                  v-model="settingapp.finacc_number"
                                />
                                <small class="text-danger" v-if="validation.finacc_number">{{
                                  validation.finacc_number[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-4">
                              <div class="form-group">
                                  <label for="">Nama Rekening</label>
                                  <input
                                  type="text"
                                  placeholder="nama rekening"
                                  class="form-control"
                                  v-model="settingapp.finacc_name"
                                />
                                <small class="text-danger" v-if="validation.finacc_name">{{
                                  validation.finacc_name[0]
                                }}</small>
                              </div>
                          </div>

                          <div class="col-md-4">
                              <div class="form-group">
                                  <label for="">Nama Bank</label>
                                  <input
                                  type="text"
                                  placeholder="nama bank"
                                  class="form-control"
                                  v-model="settingapp.finacc_bankname"
                                />
                                <small class="text-danger" v-if="validation.finacc_bankname">{{
                                  validation.finacc_bankname[0]
                                }}</small>
                              </div>
                          </div>
                      </div>

                      <hr>

                      <div class="row">
                          <div class="col-12">
                              <h6>Misc</h6>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Leader</label>
                                  <input
                                  type="text"
                                  placeholder="leader"
                                  class="form-control"
                                  v-model="settingapp.leader"
                                />
                                <small class="text-danger" v-if="validation.leader">{{
                                  validation.leader[0]
                                }}</small>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="">Note</label>
                                  <input
                                  type="text"
                                  placeholder="whatsapp token"
                                  class="form-control"
                                  v-model="settingapp.note"
                                />
                                <small class="text-danger" v-if="validation.note">{{
                                  validation.note[0]
                                }}</small>
                              </div>
                          </div>
                      </div>

                      <hr>

                      <div class="row">
                          <div class="col-12">
                              <h6>Upload</h6>
                          </div>
                      </div>

                      <div class="row ">
                          <div class="col-md-6">
                              <div class="card">
                                  <div class="card-header">
                                      Upload Logo
                                  </div>
                                  <div class="card-body">
                                      <p>Upload file dokumen berformat JPG/PNG
                                          Maksimum ukuran 2MB.</p>
                                          <input type="file" @change="handleFileLogoChange" class="form-control" >
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="card">
                                  <div class="card-header">
                                      Upload Logo Admin
                                  </div>
                                  <div class="card-body">
                                      <p>Upload file dokumen berformat JPG/PNG
                                          Maksimum ukuran 2MB.</p>
                                          <input type="file" @change="handleFileLogoAdminChange" class="form-control" >
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div class="row mt-3">
                          <div class="col-md-6">
                              <div class="card">
                                  <div class="card-header">
                                     Rule Keuangan
                                  </div>
                                  <div class="card-body">
                                      <p>Upload file dokumen berformat JPG/PNG
                                          Maksimum ukuran 2MB.</p>
                                          <input type="file" @change="handleFileRuleKeuanganChange" class="form-control" >
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="card">
                                  <div class="card-header">
                                      Rule Tata Tertib
                                  </div>
                                  <div class="card-body">
                                      <p>Upload file dokumen berformat JPG/PNG
                                          Maksimum ukuran 2MB.</p>
                                          <input type="file" @change="handleFileRuleTataTertibChange" class="form-control" >
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row mt-3">
                          <div class="col-md-6">
                              <div class="card">
                                  <div class="card-header">
                                      Barcode
                                  </div>
                                  <div class="card-body">
                                      <p>Upload file dokumen berformat JPG/PNG
                                          Maksimum ukuran 2MB.</p>
                                          <input type="file" @change="handleFileBarcodeChange" class="form-control" >
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="card">
                                  <div class="card-header">
                                      Rule Image
                                  </div>
                                  <div class="card-body">
                                      <p>Upload file dokumen berformat JPG/PNG
                                          Maksimum ukuran 2MB.</p>
                                          <input type="file" @change="handleFileRuleImageChange" class="form-control" >
                                  </div>
                              </div>
                          </div>
                      </div>
  
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                        &nbsp;
                        <router-link
                          :to="{ name: 'admin-settingapp' }"
                          class="btn btn-danger btn-sm"
                          >kembali</router-link
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive,onMounted, watch } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "settingappIndex",
    components: {},
    data() {
      return {
        showsettingapp: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const settingapp = reactive({
        name: "",
        tagline:"",
          address:"",
          sign_area:"",
          phone:"",
          fax:"",
          email:"",
          header:"",
          footer:"",
          logo:"",
          logo_admin:"",
          barcode:"",
          bgimage_login:"",
          financeruleinfo:"",
          schoolruleinfo:"",
          adminpanel_link:"",
          perpustakaan_link:"",
          akademik_link:"",
          psb_link:"",
          facebook:"",
          instagram:"",
          twitter:"",
          youtube:"",
          wa_number:"",
          wa_token:"",
          wa_message_reg:"",
          wa_message_active:"",
          wa_message_passed:"",
          finacc_number:"",
          finacc_name:"",
          finacc_bankname:"",
          ppdb_title:"",
          ppdb_subtitle:"",
          ppdb_rule:"",
          ppdb_rule_image:"",
          leader:"",
          note:""

      });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
      function handleFileLogoChange(e) {
        //get image
        let image = (this.settingapp.logo = e.target.files[0]);

        //check fileType
        if (!image.type.match("image.*")) {
          //if fileType not allowed, then clear value and set null
          e.target.value = "";

          //set state "category.image" to null
          this.settingapp.logo = null;

          //show sweet alert
          this.$swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }

      function handleFileRuleTataTertibChange(e) {
        //get image
        let image = (this.settingapp.schoolruleinfo = e.target.files[0]);

        //check fileType
        if (!image.type.match("pdf.*")) {
          //if fileType not allowed, then clear value and set null
          e.target.value = "";

          //set state "category.image" to null
          this.settingapp.schoolruleinfo = null;

          //show sweet alert
          this.$swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }

      function handleFileRuleKeuanganChange(e) {
        //get image
        let image = (this.settingapp.financeruleinfo = e.target.files[0]);

        //check fileType
        if (!image.type.match("pdf.*")) {
          //if fileType not allowed, then clear value and set null
          e.target.value = "";

          //set state "category.image" to null
          this.settingapp.financeruleinfo = null;

          //show sweet alert
          this.$swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }


      function handleFileLogoAdminChange(e) {
        //get image
        let image = (this.settingapp.logo_admin = e.target.files[0]);

        //check fileType
        if (!image.type.match("image.*")) {
          //if fileType not allowed, then clear value and set null
          e.target.value = "";

          //set state "category.image" to null
          this.settingapp.logo_admin = null;

          //show sweet alert
          this.$swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }

      function handleFileBarcodeChange(e) {
        //get image
        let image = (this.settingapp.barcode = e.target.files[0]);

        //check fileType
        if (!image.type.match("image.*")) {
          //if fileType not allowed, then clear value and set null
          e.target.value = "";

          //set state "category.image" to null
          this.settingapp.barcode = null;

          //show sweet alert
          this.$swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }

      function handleFileRuleImageChange(e) {
        //get image
        let image = (this.settingapp.ppdb_rule_image = e.target.files[0]);

        //check fileType
        if (!image.type.match("image.*")) {
          //if fileType not allowed, then clear value and set null
          e.target.value = "";

          //set state "category.image" to null
          this.settingapp.ppdb_rule_image = null;

          //show sweet alert
          this.$swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
  
    
  
      //function register, fungsi ini di jalankan ketika form di submit
      function updatesettingapp() {
        //define variable
     
  
        let formData = new FormData();
        formData.append("name", this.settingapp.name);
        formData.append('tagline', this.settingapp.tagline);
        formData.append('address', this.settingapp.address);
        formData.append('sign_area', this.settingapp.sign_area);
        formData.append('phone', this.settingapp.phone);
        formData.append('fax', this.settingapp.fax);
        formData.append('email', this.settingapp.email);
        formData.append('logo', this.settingapp.logo);
        formData.append('logo_admin', this.settingapp.logo_admin);
        formData.append('barcode', this.settingapp.barcode);
        formData.append('financeruleinfo', this.settingapp.financeruleinfo);
        formData.append('schoolruleinfo', this.settingapp.schoolruleinfo);
        formData.append('adminpanel_link', this.settingapp.adminpanel_link);
        formData.append('akademik_link', this.settingapp.akademik_link);
        formData.append('perpustakaan_link', this.settingapp.perpustakaan_link);
        formData.append('psb_link', this.settingapp.psb_link);
        formData.append('facebook', this.settingapp.facebook);
        formData.append('twitter', this.settingapp.twitter);
        formData.append('youtube', this.settingapp.youtube);
        formData.append('wa_number', this.settingapp.wa_number);
        formData.append('wa_token', this.settingapp.wa_token);
        formData.append('finacc_name', this.settingapp.finacc_name);
        formData.append('finacc_bankname', this.settingapp.finacc_bankname);
        formData.append('finacc_number', this.settingapp.finacc_number);
        formData.append('ppdb_title', this.settingapp.ppdb_title);
        formData.append('ppdb_subtitle', this.settingapp.ppdb_subtitle);
        formData.append('ppdb_rule', this.settingapp.ppdb_rule);
        formData.append('ppdb_rule_image', this.settingapp.ppdb_rule_image);
        formData.append('ppdb_leader', this.settingapp.ppdb_leader);
        formData.append('ppdb_note', this.settingapp.ppdb_note);
        formData.append("_method", "POST");
  
        //panggil actions "register" dari module "auth"
        store
          .dispatch("settingapp/store", formData)
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-settingapp" });
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
  
      onMounted(async () => {
        await store.dispatch("settingapp/getData");
      });
  
      watch(
        () => store.state.settingapp.settingapps,
        (newsettingapp) => {
          if (newsettingapp) {
            const {
              name,
              tagline,
                address,
                sign_area,
                phone,
                fax,
                email,
                header,
                footer,
                logo,
                logo_admin,
                barcode,
                bgimage_login,
                financeruleinfo,
                schoolruleinfo,
                adminpanel_link,
                perpustakaan_link,
                akademik_link,
                psb_link,
                facebook,
                instagram,
                twitter,
                youtube,
                wa_number,
                wa_token,
                wa_message_reg,
                wa_message_active,
                wa_message_passed,
                finacc_number,
                finacc_name,
                finacc_bankname,
                ppdb_title,
                ppdb_subtitle,
                ppdb_rule,
                ppdb_rule_image,
                leader,
                note
              
            } = newsettingapp;
                settingapp.name = name;
                settingapp.tagline = tagline;
                settingapp.address = address;
                settingapp.sign_area = sign_area;
                settingapp.phone = phone;
                settingapp.fax = fax;
                settingapp.email = email;
                settingapp.header = header;
                settingapp.footer = footer;
                settingapp.logo = logo;
                settingapp.logo_admin = logo_admin;
                settingapp.barcode = barcode;
                settingapp.bgimage_login = bgimage_login;
                settingapp.financeruleinfo = financeruleinfo;
                settingapp.schoolruleinfo = schoolruleinfo;
                settingapp.adminpanel_link = adminpanel_link;
                settingapp.perpustakaan_link = perpustakaan_link;
                settingapp.akademik_link = akademik_link;
                settingapp.psb_link = psb_link;
                settingapp.facebook = facebook;
                settingapp.instagram = instagram;
                settingapp.twitter = twitter;
                settingapp.youtube = youtube;
                settingapp.wa_number = wa_number;
                settingapp.wa_token = wa_token;
                settingapp.wa_message_reg = wa_message_reg;
                settingapp.wa_message_active = wa_message_active;
                settingapp.wa_message_passed = wa_message_passed;
                settingapp.finacc_number = finacc_number;
                settingapp.finacc_name = finacc_name;
                settingapp.finacc_bankname = finacc_bankname;
                settingapp.ppdb_title = ppdb_title;
                settingapp.ppdb_subtitle = ppdb_subtitle;
                settingapp.ppdb_rule = ppdb_rule;
                settingapp.ppdb_rule_image = ppdb_rule_image;
                settingapp.leader = leader;
                settingapp.note = note;
          
          }
        }
      );
  
      //computed
    
  
      //return a state and function
      return {
        settingapp, // <-- state user
        validation, // <-- state validation
        updatesettingapp,
        handleFileLogoChange,
        handleFileRuleTataTertibChange,
        handleFileRuleKeuanganChange,
        handleFileLogoAdminChange,
        handleFileBarcodeChange,
        handleFileRuleImageChange

      };
    },
  };
  </script>