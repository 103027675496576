<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Mata Pelajaran</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveMataPelajaran">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Unit</label>
                        <select
                        class="form-control select2"
                        v-model="matapelajaran.id_unit"


                        >
                          <option value="">-- select Unit--</option>
                          <option
                            v-for="u in units"
                            :key="u.id"
                            :value="u.id"
                          >
                            {{ u.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Kode Mata Pelajaran <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          placeholder="Masukkan Kode Mapel"
                          class="form-control"
                          v-model="matapelajaran.kode_mapel" />
                        <small class="text-danger" v-if="validation.kode_mapel">{{ validation.kode_mapel[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Nama Mata Pelajaran <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          placeholder="Masukkan Nama Mapel"
                          class="form-control"
                          v-model="matapelajaran.nama_mapel"
                        />
                        <small class="text-danger" v-if="validation.nama_mapel">{{ validation.nama_mapel[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">

                        <label for="exampleFormControlInput1"
                          >No urut</label
                        >
                        <input
                          type="text"
                          placeholder="Masukkan Nama Mapel"
                          class="form-control"
                          v-model="matapelajaran.no_urut"
                        />
                        <small class="text-danger" v-if="validation.no_urut">{{ validation.no_urut[0] }}</small>
                      </div>
                    </div>
                  </div>

                 
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Kelompok</label
                        >
                        <input
                          type="text"
                          placeholder="Masukkan Nama Kelompok"
                          class="form-control"
                          v-model="matapelajaran.kelompok"
                        />
                        <small class="text-danger" v-if="validation.kelompok">{{ validation.kelompok[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="matapelajaran.id_unit != 4">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Jenis Penilaian</label
                        >

                        <select
                        class="form-control select2"
                        v-model="matapelajaran.jenis_penilaian">
                        <option value="">Jenis Penilaian</option>
                        <option value="P">P</option>
                        <option value="K">K</option>
                        <option value="P dan K">P dan K</option>
                        <option value="SMA">SMA</option>
                        </select>
                       
                        <small class="text-danger" v-if="validation.jenis_penilaian">{{ validation.jenis_penilaian[0] }}</small>
                      </div>
                    </div>
                  </div>


           

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">parent</label>
                        <select
                        class="form-control select2"
                        v-model="matapelajaran.id_parents"


                        >
                          <option value="">-- select Parent--</option>
                          <option
                            v-for="m in mapel"
                            :key="m.id"
                            :value="m.id"
                          >
                          {{ m.kode_mapel }}-{{ m.nama_mapel }}- {{ m.unit ? m.unit.name : '' }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>



                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-matapelajaran' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "userIndex",
  components: {},
  data() {
    return {
      showuser: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

 
  setup() {
    //user state
    const matapelajaran = reactive({
          kode_mapel: '',
          nama_mapel:'',
          id_unit:'',
          id_parents :'',
          no_urut:'',
          kelompok:'',
          jenis_penilaian:''
          
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

 
    //function register, fungsi ini di jalankan ketika form di submit
    function saveMataPelajaran() {

      let jenispenilaian = '';
      if(this.matapelajaran.id_unit != 4){
        jenispenilaian = this.matapelajaran.jenis_penilaian;
      }else{
        jenispenilaian = 'SMA';
      }
      let formData = new FormData();
      formData.append('nama_mapel', this.matapelajaran.nama_mapel)
      formData.append('kode_mapel', this.matapelajaran.kode_mapel)
      formData.append('id_unit', this.matapelajaran.id_unit)
      formData.append('id_parents', this.matapelajaran.id_parents)
      formData.append('jenis_penilaian', jenispenilaian)
      formData.append('no_urut', this.matapelajaran.no_urut)
      formData.append('kelompok', this.matapelajaran.kelompok)


      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("matapelajaran/store", 
          formData,
        )
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-matapelajaran" });
          Swal.fire({
          icon: 'success',
          title: 'Tambah data berhasil',
        });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch('matapelajaran/getData');
      await store.dispatch("units/getAll");
      await store.dispatch("matapelajaran/getAll");
    });

    //computed
    const matapelajarans = computed(() => {
      return store.getters["matapelajaran/getData"];
    });

    const units = computed(() => {
      //panggil getter dengan nama "getParent" di module "unitlevel" vuex
      return store.getters["units/getAll"];
    });

    const mapel = computed(() => {
      //panggil getter dengan nama "getParent" di module "unitlevel" vuex
      return store.getters["matapelajaran/getAll"];
    });



    //return a state and function
    return {
      matapelajaran,
      matapelajarans, // <-- state user
      units,
      validation, // <-- state validation
      saveMataPelajaran,
      mapel
      
  
    };
  },
};
</script>