<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Edit workpriority</h5>
             
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="updateworkpriority">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Pekerjaan</label>
                          <select class="form-control" v-model="workpriority.id_work">
                            <option value="">-- select pekerjaan --</option>
                            <option v-for="work in works" :key="work.id" :value="work.id">{{ work.name}}</option>
                          </select>
                          <small class="text-danger" v-if="validation.id_typay">{{
                            validation.id_typay[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Typay</label>
                          <select class="form-control" v-model="workpriority.id_typay">
                            <option value="">-- select typay --</option>
                            <option v-for="typay in typays" :key="typay.id" :value="typay.id">{{ typay.name}}</option>
                          </select>
                          <small class="text-danger" v-if="validation.id_typay">{{
                            validation.id_typay[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Priority</label>
                          <select class="form-control" v-model="workpriority.priority">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Discount</label>
                          <input
                            type="number"
                            placeholder="Discount"
                            class="form-control"
                            v-model="workpriority.discount"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                        &nbsp;
                        <router-link
                          :to="{ name: 'admin-workpriority' }"
                          class="btn btn-danger btn-sm"
                          >kembali</router-link
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive,onMounted, watch, computed} from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "workpriorityIndex",
    components: {},
    data() {
      return {
        showworkpriority: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const workpriority = reactive({
        id_work: "",
        id_typay: "",
        priority: "no",
        discount: null,
      });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
  
      const route = useRoute();

      onMounted(async () => {
       
       await store.dispatch("typay/getAll");
       await store.dispatch("work/getAll");
     });

   

     const typays = computed(() => {
       return store.getters["typay/getAll"];
     });

     const works = computed(() => {
       return store.getters["work/getAll"];
     });
  
    
  
  
      //function register, fungsi ini di jalankan ketika form di submit
      function updateworkpriority() {
        //define variable
     
  
        let formData = new FormData();
        formData.append("id_work", workpriority.id_work);
        formData.append("id_typay", workpriority.id_typay);
        formData.append("priority", workpriority.priority);
        formData.append("discount", workpriority.discount);
        formData.append("_method", "POST");
  
        //panggil actions "register" dari module "auth"
        store
          .dispatch("workpriority/update", {
            workpriorityId: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-workpriority" });
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
  
      onMounted(async () => {
        await store.dispatch("workpriority/getDetail", route.params.id);
      });
  
      watch(
        () => store.state.workpriority.details,
        (newworkpriority) => {
          if (newworkpriority) {
            const {
              id_work,
        id_typay,
        priority,
        discount,
            } = newworkpriority;
            workpriority.id_work = id_work;
            workpriority.id_typay = id_typay;
            workpriority.priority = priority;
            workpriority.discount = discount;

          }
        }
      );
  
      //computed
    
  
      //return a state and function
      return {
        workpriority, // <-- state user
        validation, // <-- state validation
        updateworkpriority,
        typays,
        works
       
      };
    },
  };
  </script>