import { createRouter, createWebHistory } from "vue-router";
import Landing from "../views/dashboards/Landing.vue";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";

import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUser from "../views/pages/Users/NewUser.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";

import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";

// ini yang udah di custome
import MenuIndex from "../views/v1/menu/MenuIndex.vue";
import MenuTambah from "../views/v1/menu/MenuTambah.vue";
import MenuEdit from "../views/v1/menu/MenuEdit.vue";
import JadwalpelaksanaanIndex from "../views/v1/jadwalpelaksanaan/JadwalpelaksanaanIndex.vue";
import JadwalpelaksanaanCreate from "../views/v1/jadwalpelaksanaan/JadwalpelaksanaanCreate.vue";
import JadwalpelaksanaanEdit from "../views/v1/jadwalpelaksanaan/JadwalpelaksanaanEdit.vue";
import PermissionIndex from "../views/v1/permission/PermissionIndex.vue";
import PermissionTambah from "../views/v1/permission/PermissionTambah.vue";
import PermissionEdit from "../views/v1/permission/PermissionEdit.vue";
import RoleIndex from "../views/v1/role/RoleIndex.vue";
import RoleTambah from "../views/v1/role/RoleTambah.vue";
import RoleEdit from "../views/v1/role/RoleEdit.vue";
import UserIndex from "../views/v1/user/UserIndex.vue";
import UserTambah from "../views/v1/user/UserTambah.vue";
import UserEdit from "../views/v1/user/UserEdit.vue";
import Login from "../views/auth/signin/Illustration.vue";
import Dashboard from "../views/dashboards/SmartHome.vue";
import MedicalInfo from "../views/v1/medicalinfo/MedicalInfoIndex.vue";
import Informasippdb from "../views/v1/jadwalpelaksanaan/informasi.vue";
import PendaftarBaru from "../views/v1/pendaftarbaru/PendaftarbaruIndex.vue";
import PendaftarAktif from "../views/v1/pendaftaraktif/PendaftaraktifIndex.vue";
import PendaftarAktifShow from "../views/v1/pendaftaraktif/PendaftaraktifShow.vue";
import PendaftarLulus from "../views/v1/pendaftarlulus/PendaftarlulusIndex.vue";
import PendaftarLulusShow from "../views/v1/pendaftarlulus/PendaftarlulusShow.vue";
import PendaftarTidakLulus from "../views/v1/pendaftartidaklulus/PendaftartidaklulusIndex.vue";
import PendaftarTidakLulusShow from "../views/v1/pendaftartidaklulus/PendaftartidaklulusShow.vue";
import ProfileEdit from "../views/v1/profile/ProfileEdit.vue";
import PasswordEdit from "../views/v1/profile/PasswordEdit.vue";
import DaftarNamaSekolahIndex from "../views/v1/daftarnamasekolah/DaftarNamaSekolahIndex.vue";
import DaftarNamaSekolahTambah from "../views/v1/daftarnamasekolah/DaftarNamaSekolahTambah.vue";
import DaftarNamaSekolahEdit from "../views/v1/daftarnamasekolah/DaftarNamaSekolahEdit.vue";
import AturanNilaiIndex from "../views/v1/aturannilai/AturanNilaiIndex.vue";
import AturanNilaiTambah from "../views/v1/aturannilai/AturanNilaiTambah.vue";
import AturanNilaiEdit from "../views/v1/aturannilai/AturanNilaiEdit.vue";

import MasterMapelCeklisIndex from "../views/v1/mastermapelceklis/MapelCeklisIndex.vue";
import MasterMapelCeklisTambah from "../views/v1/mastermapelceklis/MapelCeklisTambah.vue";
import MasterMapelCeklisEdit from "../views/v1/mastermapelceklis/MapelCeklisEdit.vue";
import MasterMapelCeklisListJadwal from "../views/v1/mastermapelceklis/ListJadwal.vue";
import MasterMapelCeklisForm from "../views/v1/mastermapelceklis/Form.vue";

import Masterp5Index from "../views/v1/masterp5/Masterp5Index.vue";
import Masterp5Tambah from "../views/v1/masterp5/Masterp5Tambah.vue";
import Masterp5Edit from "../views/v1/masterp5/Masterp5Edit.vue";
import Masterp5Form from "../views/v1/masterp5/Form.vue";
import Masterp5Kelas from "../views/v1/masterp5/Kelas.vue";
import Masterp5InputNilai from "../views/v1/masterp5/Inputnilai.vue";

import UploadRaportIndex from "../views/v1/uploadraport/UploadRaportIndex.vue";
import UploadRaportTambah from "../views/v1/uploadraport/UploadRaportTambah.vue";
import UploadRaportEdit from "../views/v1/uploadraport/UploadRaportEdit.vue";
import ListSiswaRaport from "../views/v1/uploadraport/ListSiswaRaport.vue";





import AbsensiListKelas from "../views/v1/absenkelas/ListKelas.vue";
import AbsensiListSiswa from "../views/v1/absenkelas/ListSiswa.vue";

import NilaiSikapListKelas from "../views/v1/nilaisikap/ListKelas.vue";
import NilaiListSiswa from "../views/v1/nilaisikap/ListSiswa.vue";
import NilaiBkListKelas from "../views/v1/nilaibk/ListKelas.vue";
import NilaiBkListSiswa from "../views/v1/nilaibk/ListSiswa.vue";
import NilaiWalasListKelas from "../views/v1/nilaiwalas/ListKelas.vue";
import NilaiWalasListSiswa from "../views/v1/nilaiwalas/ListSiswa.vue";

import NilaiCeklisListKelas from "../views/v1/nilaiceklis/ListKelas.vue";
import NilaiCeklisListSiswa from "../views/v1/nilaiceklis/ListSiswa.vue";



import UploadKelulusanIndex from "../views/v1/uploadkelulusan/UploadKelulusanIndex.vue";
import UploadKelulusanTambah from "../views/v1/uploadkelulusan/UploadKelulusanTambah.vue";
import UploadKelulusanEdit from "../views/v1/uploadkelulusan/UploadKelulusanEdit.vue";
import ListUploadSiswaLulus from "../views/v1//uploadkelulusan/ListSiswaLulus.vue";

import OrangtuaIndex from "../views/v1/orangtua/OrangtuaIndex.vue";

import PengajarIndex from "../views/v1/pengajar/PengajarIndex.vue";
import PengajarTambah from "../views/v1/pengajar/PengajarTambah.vue";
import PengajarEdit from "../views/v1/pengajar/PengajarEdit.vue";

import WorkpriorityIndex from "../views/v1/workpriority/WorkpriorityIndex.vue";
import WorkpriorityTambah from "../views/v1/workpriority/WorkpriorityTambah.vue";
import WorkpriorityEdit from "../views/v1/workpriority/WorkpriorityEdit.vue";

import WorkIndex from "../views/v1/work/WorkIndex.vue";
import WorkTambah from "../views/v1/work/WorkTambah.vue";
import WorkEdit from "../views/v1/work/WorkEdit.vue";

import TypayIndex from "../views/v1/typay/TypayIndex.vue";
import TypayTambah from "../views/v1/typay/TypayTambah.vue";
import TypayEdit from "../views/v1/typay/TypayEdit.vue";

import TypaynominalIndex from "../views/v1/typaynominal/TypaynominalIndex.vue";
import TypaynominalTambah from "../views/v1/typaynominal/TypaynominalTambah.vue";
import TypaynominalEdit from "../views/v1/typaynominal/TypaynominalEdit.vue";


import MataPelajaranIndex from "../views/v1/matapelajaran/MataPelajaranIndex.vue";
import MataPelajaranTambah from "../views/v1/matapelajaran/MataPelajaranTambah.vue";
import MataPelajaranEdit from "../views/v1/matapelajaran/MataPelajaranEdit.vue";

import BebanPengajarIndex from "../views/v1/bebanpengajar/BebanPengajarIndex.vue";
import BebanPengajarTambah from "../views/v1/bebanpengajar/BebanPengajarTambah.vue";
import BebanPengajarEdit from "../views/v1/bebanpengajar/BebanPengajarEdit.vue";
import BebanPengajarListPengajar from "../views/v1/bebanpengajar/listpengajar.vue";
import BebanPengajarListSiswa from "../views/v1/bebanpengajar/listsiswa.vue";

import DaftarInfoRegistrasiIndex from "../views/v1/daftarinforegistrasi/DaftarInfoRegistrasiIndex.vue";
import DaftarInfoRegistrasiTambah from "../views/v1/daftarinforegistrasi/DaftarInfoRegistrasiTambah.vue";
import DaftarInfoRegistrasiEdit from "../views/v1/daftarinforegistrasi/DaftarInfoRegistrasiEdit.vue";
import ModaTransportasiIndex from "../views/v1/modatransportasi/ModaTransportasiIndex.vue";
import ModaTransportasiTambah from "../views/v1/modatransportasi/ModaTransportasiTambah.vue";
import ModaTransportasiEdit from "../views/v1/modatransportasi/ModaTransportasiEdit.vue";
import RelasiKeluargaIndex from "../views/v1/relasikeluarga/RelasiKeluargaIndex.vue";
import RelasiKeluargaTambah from "../views/v1/relasikeluarga/RelasiKeluargaTambah.vue";
import RelasiKeluargaEdit from "../views/v1/relasikeluarga/RelasiKeluargaEdit.vue";
import JenisPekerjaanIndex from "../views/v1/jenispekerjaan/JenisPekerjaanIndex.vue";
import JenisPekerjaanTambah from "../views/v1/jenispekerjaan/JenisPekerjaanTambah.vue";
import JenisPekerjaanEdit from "../views/v1/jenispekerjaan/JenisPekerjaanEdit.vue";
import UnitIndex from "../views/v1/unit/UnitIndex.vue";
import UnitTambah from "../views/v1/unit/UnitTambah.vue";
import UnitEdit from "../views/v1/unit/UnitEdit.vue";
import UnitlevelIndex from "../views/v1/unitlevel/UnitlevelIndex.vue";
import UnitlevelTambah from "../views/v1/unitlevel/UnitlevelTambah.vue";
import UnitlevelEdit from "../views/v1/unitlevel/UnitlevelEdit.vue";
import settingappIndex from "../views/v1/settingapp/SettingAppIndex.vue";
import TahunajaranIndex from "../views/v1/tahunajaran/TahunajaranIndex.vue";
import TahunajaranTambah from "../views/v1/tahunajaran/TahunajaranTambah.vue";
import TahunajaranEdit from "../views/v1/tahunajaran/TahunajaranEdit.vue";
import KategoriPenilaianIndex from "../views/v1/kategoripenilaian/KategoriPenilaianIndex.vue";
import KategoriPenilaianTambah from "../views/v1/kategoripenilaian/KategoriPenilaianTambah.vue";
import KategoriPenilaianEdit from "../views/v1/kategoripenilaian/KategoriPenilaianEdit.vue";
import SubKategoriPenilaianIndex from "../views/v1/subkategoripenilaian/SubKategoriPenilaianIndex.vue";
import SubKategoriPenilaianTambah from "../views/v1/subkategoripenilaian/SubKategoriPenilaianTambah.vue";
import SubKategoriPenilaianEdit from "../views/v1/subkategoripenilaian/SubKategoriPenilaianEdit.vue";
import KategoriSikapIndex from "../views/v1/kategorisikap/KategoriSikapIndex.vue";
import KategoriSikapTambah from "../views/v1/kategorisikap/KategoriSikapTambah.vue";
import KategoriSikapEdit from "../views/v1/kategorisikap/KategoriSikapEdit.vue";
import GroupKategoriSikapIndex from "../views/v1/groupkategorisikap/GroupIndex.vue";
import GroupKategoriSikapTambah from "../views/v1/groupkategorisikap/GroupTambah.vue";
import GroupKategoriSikapEdit from "../views/v1/groupkategorisikap/GroupEdit.vue";
import KeteranganNilaiIndex from "../views/v1/keterangannilai/KeteranganNilaiIndex.vue";
import KeteranganNilaiTambah from "../views/v1/keterangannilai/KeteranganNilaiTambah.vue";
import KeteranganNilaiEdit from "../views/v1/keterangannilai/KeteranganNilaiEdit.vue";
import KelasIndex from "../views/v1/kelas/KelasIndex.vue";
import KelasTambah from "../views/v1/kelas/KelasTambah.vue";
import KelasEdit from "../views/v1/kelas/KelasEdit.vue";
import KelasSiswa from "../views/v1/kelas/Listsiswa.vue";
import KelasStatusSiswa from "../views/v1/kelas/Liststatussiswa.vue";
import SubKategoriSikapIndex from "../views/v1/subkategorisikap/SubKategoriSikapIndex.vue";
import SubKategoriSikapTambah from "../views/v1/subkategorisikap/SubKategoriSikapTambah.vue";
import SubKategoriSikapEdit from "../views/v1/subkategorisikap/SubKategoriSikapEdit.vue";
import PenilaianIndex from "../views/v1/penilaian/PenilaianIndex.vue";
import PerwalianIndex from "../views/v1/perwalian/Perwalian.vue";
import InputNilai from "../views/v1/penilaian/InputNilai.vue";
import InputNilaiSikap from "../views/v1/penilaiansikap/InputNilai.vue";
import GuruIndex from "../views/v1/guru/GuruIndex.vue";
import GuruEdit from "../views/v1/guru/GuruEdit.vue";


import SiswaAktifIndex from "../views/v1/siswaaktif/SiswaAktifIndex.vue";
import SiswaAktifEdit from "../views/v1/siswaaktif/SiswaAktifEdit.vue";

import SiswaBarudanpindahanIndex from "../views/v1/siswakelasbaru/SiswaKelasBaruIndex.vue";

import InfregUsersIndex from "../views/v1/infregusers/InfregUsersIndex.vue";
import NaikKelasIndex from "../views/v1/naikkelas/NaikKelasIndex.vue";
import LulusIndex from "../views/v1/lulus/LulusIndex.vue";
import ImportNis from "../views/v1/importnis/ImportNisIndex.vue";

import KelasRaport from "../views/v1/raport/KelasRaport.vue";
import SiswaKelasRaport from "../views/v1/raport/SiswaRaport.vue";

import EkskulIndex from "../views/v1/ekskul/EkskulIndex.vue";
import EkskulEdit from "../views/v1/ekskul/EkskulEdit.vue";
import EkskulTambah from "../views/v1/ekskul/EkskulTambah.vue";

import EkskulAktifIndex from "../views/v1/ekskulaktif/EkskulAktifIndex.vue";
import EkskulAktifEdit from "../views/v1/ekskulaktif/EkskulAktifEdit.vue";
import EkskulAktifTambah from "../views/v1/ekskulaktif/EkskulAktifTambah.vue";
import EkskulAktifPeserta from "../views/v1/ekskulaktif/EkskulAktifPeserta.vue";
import EkskulAktifPesertaNilai from "../views/v1/ekskulaktif/EkskulAktifPesertaNilai.vue";
import RekapEkskul from "../views/v1/ekskulaktif/RekapEkskul.vue";

import LaporanBulananKBIndex from "../views/v1/bulanankb/KBIndex.vue";
import LaporanBulananKBTambah from "../views/v1/bulanankb/KBTambah.vue";
import LaporanBulananKBEdit from "../views/v1/bulanankb/KBEdit.vue";
import LaporanBulananKBHistory from "../views/v1/bulanankb/KBHistory.vue";
import LaporanBulananKBPrint from "../views/v1/bulanankb/KBPrint.vue";
import PesertaLaporanBulananKBIndex from "../views/v1/bulanankb/PesertaListKBIndex.vue";
import PesertaLaporanAkhirnKBIndex from "../views/v1/bulanankb/PesertaListAkhirKBIndex.vue";

import LaporanAkhirKBTKIndex from "../views/v1/akhirkbtk/KBIndex.vue";
import LaporanAkhirKBTKTambah from "../views/v1/akhirkbtk/KBTambah.vue";
import LaporanAkhirKBTKEdit from "../views/v1/akhirkbtk/KBEdit.vue";
import LaporanAkhirKBTKHistory from "../views/v1/akhirkbtk/KBHistory.vue";
import LaporanAkhirKBTKPrint from "../views/v1/akhirkbtk/KBPrint.vue";

import store from '../store'
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "dashboard"
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/importnis",
    name: "admin-importnis",
    component: ImportNis,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/orangtua",
    name: "admin-orangtua",
    component: OrangtuaIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/guru",
    name: "admin-guru",
    component: GuruIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/kelas-raport",
    name: "admin-kelasraport",
    component: KelasRaport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/kelas-siswarapot/:id",
    name: "admin-siswakelasraport",
    component: SiswaKelasRaport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/guru/edit/:id',
    name: 'admin-guru-edit',
    component: GuruEdit,
    meta: {
      requiresAuth: true
    }
  },
  {

    path: "/ekskul",
    name: "admin-ekskul",
    component: EkskulIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ekskul/tambah",
    name: "admin-ekskul-tambah",
    component: EkskulTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ekskul/edit/:id',
    name: 'admin-ekskul-edit',
    component: EkskulEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ekskulaktif",
    name: "admin-ekskulaktif",
    component: EkskulAktifIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/ekskulaktif/tambah",
    name: "admin-ekskulaktif-tambah",
    component: EkskulAktifTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ekskulaktif/edit/:id',
    name: 'admin-ekskulaktif-edit',
    component: EkskulAktifEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ekskulaktif/peserta/:id',
    name: 'admin-ekskulaktif-peserta',
    component: EkskulAktifPeserta,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rekapekskul/peserta/:id',
    name: 'admin-ekskulaktif-peserta-rekap',
    component: RekapEkskul,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ekskulaktif/pesertanilai/:id/:idunit',
    name: 'admin-ekskulaktif-penilaian',
    component: EkskulAktifPesertaNilai,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/siswaaktif",
    name: "admin-siswaaktif",
    component: SiswaAktifIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/siswaaktif/edit/:id',
    name: 'admin-siswaaktif-edit',
    component: SiswaAktifEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/siswabarudanpindahan",
    name: "admin-siswabarudanpindahan",
    component: SiswaBarudanpindahanIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/penilaian",
    name: "admin-penilaian",
    component: PenilaianIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/perwalian",
    name: "admin-perwalian",
    component: PerwalianIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/inputnilai/:id",
    name: "admin-inputnilai",
    component: InputNilai,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/inputnilaisikap/:id",
    name: "admin-inputnilaisikap",
    component: InputNilaiSikap,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/workpriority",
    name: "admin-workpriority",
    component: WorkpriorityIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/workpriority/tambah",
    name: "admin-workpriority-tambah",
    component: WorkpriorityTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workpriority/edit/:id',
    name: 'admin-workpriority-edit',
    component: WorkpriorityEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/work",
    name: "admin-work",
    component: WorkIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/work/tambah",
    name: "admin-work-tambah",
    component: WorkTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/work/edit/:id',
    name: 'admin-work-edit',
    component: WorkEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/kelas",
    name: "admin-kelas",
    component: KelasIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/kelas/tambah",
    name: "admin-kelas-tambah",
    component: KelasTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kelas/edit/:id',
    name: 'admin-kelas-edit',
    component: KelasEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kelas/listsiswa/:id',
    name: 'admin-kelas-listsiswa',
    component: KelasSiswa,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/kelas/liststatussiswa/:id',
    name: 'admin-kelas-liststatussiswa',
    component: KelasStatusSiswa,
    meta: {
      requiresAuth: true
    }
  },


  
  {
    path: "/nilaisikap",
    name: "admin-nilaisikap",
    component: NilaiSikapListKelas,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/medicalinfo",
    name: "admin-medicalinfo",
    component: MedicalInfo,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/nilaisikap/listsiswa/:id",
    name: "admin-nilaisikapsiswa",
    component: NilaiListSiswa,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/nilaiceklis",
    name: "admin-nilaiceklis",
    component: NilaiCeklisListKelas,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/nilaiceklis/detail/:id",
    name: "admin-nilaiceklisdetail",
    component: NilaiCeklisListSiswa,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/mastermapelceklis",
    name: "admin-mastermapelceklis",
    component:MasterMapelCeklisIndex,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/mastermapelceklis/create",
    name: "admin-mastermapelceklis-create",
    component:MasterMapelCeklisTambah,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/mastermapelceklis/edit/:id",
    name: "admin-mastermapelceklis-edit",
    component: MasterMapelCeklisEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/mastermapelceklis/listjadwal/:id/:idta/:idunitlevel",
    name: "admin-mastermapelceklis-listjadwal",
    component: MasterMapelCeklisListJadwal,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/mastermapelceklis/form/:id/:idunit/:idta/:idunitlevel",
    name: "admin-mastermapelceklis-form",
    component: MasterMapelCeklisForm,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/masterp5/detail/:id",
    name: "admin-masterp5",
    component:Masterp5Index,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/masterp5/inputnilai/:id",
    name: "admin-masterp5-inputnilai",
    component:Masterp5InputNilai,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/masterp5/:id/create",
    name: "admin-masterp5-create",
    component:Masterp5Tambah,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/masterp5/edit/:id/:idunitlevel",
    name: "admin-masterp5-edit",
    component: Masterp5Edit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/masterp5/form/:id/:idunit/:idta/:idunitlevel",
    name: "admin-masterp5-form",
    component: Masterp5Form,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/nilaiperwalian",
    name: "admin-nilaiperwalian",
    component: Masterp5Kelas,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/absenkelas",
    name: "admin-absenkelas",
    component: AbsensiListKelas,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/absenkelas/listsiswa/:id",
    name: "admin-absenkelassiswa",
    component: AbsensiListSiswa,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/nilaibk",
    name: "admin-nilaibk",
    component: NilaiBkListKelas,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/nilaibk/listsiswa/:id",
    name: "admin-nilaibksiswa",
    component: NilaiBkListSiswa,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/nilaiwalas",
    name: "admin-nilaiwalas",
    component: NilaiWalasListKelas,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/nilaiwalas/listsiswa/:id",
    name: "admin-nilaiwalassiswa",
    component: NilaiWalasListSiswa,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/uploadraport",
    name: "admin-uploadraport",
    component: UploadRaportIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/naikkelas",
    name: "admin-naikkelas",
    component: NaikKelasIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/lulus",
    name: "admin-lulus",
    component: LulusIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/menu",
    name: "admin-menu",
    component: MenuIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/menu/tambah",
    name: "admin-menu-tambah",
    component: MenuTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/menu/edit/:id',
    name: 'admin-menu-edit',
    component: MenuEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/typay",
    name: "admin-typay",
    component: TypayIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/typay/tambah",
    name: "admin-typay-tambah",
    component: TypayTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/typay/edit/:id',
    name: 'admin-typay-edit',
    component: TypayEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/typaynominal",
    name: "admin-typaynominal",
    component: TypaynominalIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/typaynominal/tambah",
    name: "admin-typaynominal-tambah",
    component: TypaynominalTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/typaynominal/edit/:id',
    name: 'admin-typaynominal-edit',
    component: TypaynominalEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/unit",
    name: "admin-unit",
    component: UnitIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/unit/tambah",
    name: "admin-unit-tambah",
    component: UnitTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/unit/edit/:id',
    name: 'admin-unit-edit',
    component: UnitEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/unitlevel",
    name: "admin-unitlevel",
    component: UnitlevelIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/unitlevel/tambah",
    name: "admin-unitlevel-tambah",
    component: UnitlevelTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/unitlevel/edit/:id',
    name: 'admin-unitlevel-edit',
    component: UnitlevelEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/permission",
    name: "admin-permission",
    component: PermissionIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/permission/tambah",
    name: "admin-permission-tambah",
    component: PermissionTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/permission/edit/:id',
    name: 'admin-permission-edit',
    component: PermissionEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pendaftarbaru",
    name: "admin-pendaftarbaru",
    component: PendaftarBaru,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pendaftaraktif",
    name: "admin-pendaftaraktif",
    component: PendaftarAktif,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pendaftaraktif/detail/:id',
    name: 'admin-pendaftaraktif-show',
    component: PendaftarAktifShow,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/infreguser",
    name: "admin-infreguser",
    component: InfregUsersIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pendaftarlulus",
    name: "admin-pendaftarlulus",
    component: PendaftarLulus,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pendaftarlulus/detail/:id',
    name: 'admin-pendaftarlulus-show',
    component: PendaftarLulusShow,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pendaftartidaklulus",
    name: "admin-pendaftartidaklulus",
    component: PendaftarTidakLulus,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pendaftartidaklulus/detail/:id',
    name: 'admin-pendaftartidaklulus-show',
    component: PendaftarTidakLulusShow,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/role",
    name: "admin-role",
    component: RoleIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/role/tambah",
    name: "admin-role-tambah",
    component: RoleTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/role/edit/:id',
    name: 'admin-role-edit',
    component: RoleEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/user",
    name: "admin-user",
    component: UserIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/user/tambah",
    name: "admin-user-tambah",
    component: UserTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/edit/:id',
    name: 'admin-user-edit',
    component: UserEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/informasippdb",
    name: "admin-informasippdb",
    component: Informasippdb,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/jadwalpelaksanaan",
    name: "admin-jadwalpelaksanaan",
    component: JadwalpelaksanaanIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/jadwalpelaksanaan/create",
    name: "admin-jadwalpelaksanaan-create",
    component: JadwalpelaksanaanCreate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jadwalpelaksanaan/edit/:id',
    name: 'admin-jadwalpelaksanaan-edit',
    component: JadwalpelaksanaanEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/change-password/:id',
    name: 'admin-change-password',
    component: PasswordEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/change-profile/:id',
    name: 'admin-change-profile',
    component: ProfileEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/settingapp",
    name: "admin-settingapp",
    component: settingappIndex,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default
  },
  {
    path: "/daftarnamasekolah",
    name: "admin-daftarnamasekolah",
    component: DaftarNamaSekolahIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/daftarnamasekolah/tambah",
    name: "admin-daftarnamasekolah-tambah",
    component: DaftarNamaSekolahTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/daftarnamasekolah/edit/:id',
    name: 'admin-daftarnamasekolah-edit',
    component: DaftarNamaSekolahEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/daftarinforegistrasi",
    name: "admin-daftarinforegistrasi",
    component: DaftarInfoRegistrasiIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/daftarinforegistrasi/tambah",
    name: "admin-daftarinforegistrasi-tambah",
    component: DaftarInfoRegistrasiTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/daftarinforegistrasi/edit/:id',
    name: 'admin-daftarinforegistrasi-edit',
    component: DaftarInfoRegistrasiEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/modatransportasi",
    name: "admin-modatransportasi",
    component: ModaTransportasiIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/modatransportasi/tambah",
    name: "admin-modatransportasi-tambah",
    component: ModaTransportasiTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/modatransportasi/edit/:id',
    name: 'admin-modatransportasi-edit',
    component: ModaTransportasiEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/kategoripenilaian",
    name: "admin-kategoripenilaian",
    component: KategoriPenilaianIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/kategoripenilaian/tambah",
    name: "admin-kategoripenilaian-tambah",
    component: KategoriPenilaianTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kategoripenilaian/edit/:id',
    name: 'admin-kategoripenilaian-edit',
    component: KategoriPenilaianEdit,
    meta: {
      requiresAuth: true
    }
  },

  
  {
    path: "/kategorisikap",
    name: "admin-kategorisikap",
    component: KategoriSikapIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/kategorisikap/tambah",
    name: "admin-kategorisikap-tambah",
    component: KategoriSikapTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kategorisikap/edit/:id',
    name: 'admin-kategorisikap-edit',
    component: KategoriSikapEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/keterangannilai",
    name: "admin-keterangannilai",
    component: KeteranganNilaiIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/keterangannilai/tambah",
    name: "admin-keterangannilai-tambah",
    component: KeteranganNilaiTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/keterangannilai/edit/:id',
    name: 'admin-keterangannilai-edit',
    component: KeteranganNilaiEdit,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/subkategoripenilaian",
    name: "admin-subkategoripenilaian",
    component: SubKategoriPenilaianIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/subkategoripenilaian/tambah",
    name: "admin-subkategoripenilaian-tambah",
    component: SubKategoriPenilaianTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/subkategoripenilaian/edit/:id',
    name: 'admin-subkategoripenilaian-edit',
    component: SubKategoriPenilaianEdit,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/subkategorisikap",
    name: "admin-subkategorisikap",
    component: SubKategoriSikapIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/subkategorisikap/tambah",
    name: "admin-subkategorisikap-tambah",
    component: SubKategoriSikapTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/subkategorisikap/edit/:id',
    name: 'admin-subkategorisikap-edit',
    component: SubKategoriSikapEdit,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/groupkategorisikap",
    name: "admin-groupkategorisikap",
    component: GroupKategoriSikapIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/groupkategorisikap/tambah",
    name: "admin-groupkategorisikap-tambah",
    component: GroupKategoriSikapTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/groupkategorisikap/edit/:id',
    name: 'admin-groupkategorisikap-edit',
    component: GroupKategoriSikapEdit,
    meta: {
      requiresAuth: true
    }
  },



  {
    path: "/tahunajaran",
    name: "admin-tahunajaran",
    component: TahunajaranIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tahunajaran/tambah",
    name: "admin-tahunajaran-tambah",
    component: TahunajaranTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tahunajaran/edit/:id',
    name: 'admin-tahunajaran-edit',
    component: TahunajaranEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/daftarjenispekerjaan",
    name: "admin-daftarjenispekerjaan",
    component: JenisPekerjaanIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/daftarjenispekerjaan/tambah",
    name: "admin-daftarjenispekerjaan-tambah",
    component: JenisPekerjaanTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/daftarjenispekerjaan/edit/:id',
    name: 'admin-daftarjenispekerjaan-edit',
    component: JenisPekerjaanEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/relasikeluarga",
    name: "admin-relasikeluarga",
    component: RelasiKeluargaIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/relasikeluarga/tambah",
    name: "admin-relasikeluarga-tambah",
    component: RelasiKeluargaTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/relasikeluarga/edit/:id',
    name: 'admin-relasikeluarga-edit',
    component: RelasiKeluargaEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/aturannilai",
    name: "admin-aturannilai",
    component: AturanNilaiIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/aturannilai/tambah",
    name: "admin-aturannilai-tambah",
    component: AturanNilaiTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/aturannilai/edit/:id',
    name: 'admin-aturannilai-edit',
    component: AturanNilaiEdit,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/pengajar",
    name: "admin-pengajar",
    component: PengajarIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pengajar/tambah",
    name: "admin-pengajar-tambah",
    component: PengajarTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pengajar/edit/:id',
    name: 'admin-pengajar-edit',
    component: PengajarEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/matapelajaran",
    name: "admin-matapelajaran",
    component: MataPelajaranIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/matapelajaran/tambah",
    name: "admin-matapelajaran-tambah",
    component: MataPelajaranTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/matapelajaran/edit/:id',
    name: 'admin-matapelajaran-edit',
    component: MataPelajaranEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/bebanmengajar",
    name: "admin-bebanpengajar",
    component: BebanPengajarIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/bebanmengajar/tambah",
    name: "admin-bebanpengajar-tambah",
    component: BebanPengajarTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bebanmengajar/edit/:id',
    name: 'admin-bebanpengajar-edit',
    component: BebanPengajarEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/bebanmengajar/listpengajar/:id',
    name: 'admin-bebanpengajar-listpengajar',
    component: BebanPengajarListPengajar,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/bebanmengajar/listsiswa/:id',
    name: 'admin-bebanpengajar-listsiswa',
    component: BebanPengajarListSiswa,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: "/uploadraport",
    name: "admin-uploadraport",
    component: UploadRaportIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/uploadraport/tambah",
    name: "admin-uploadraport-tambah",
    component: UploadRaportTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/uploadraport/edit/:id',
    name: 'admin-uploadraport-edit',
    component: UploadRaportEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/listsiswaraport/:id",
    name: "admin-listsiswaraport",
    component: ListSiswaRaport,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/listsiswalulus/:id",
    name: "admin-listsiswalulus",
    component: ListUploadSiswaLulus,
    meta: {
      requiresAuth: true
    }
  },



  {
    path: "/uploadkelulusan",
    name: "admin-uploadkelulusan",
    component: UploadKelulusanIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/uploadkelulusan/tambah",
    name: "admin-uploadkelulusan-tambah",
    component: UploadKelulusanTambah,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/uploadkelulusan/edit/:id',
    name: 'admin-uploadkelulusan-edit',
    component: UploadKelulusanEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/laporan-bulanan-kbtk/:id",
    name: "admin-laporan-bulanan-kb",
    component: LaporanBulananKBIndex,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/peserta-bulanan-kbtk/:id",
    name: "admin-peserta-bulanan-kb",
    component: PesertaLaporanBulananKBIndex,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/peserta-akhir-kbtk/:id",
    name: "admin-peserta-akhir-kb",
    component: PesertaLaporanAkhirnKBIndex,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/laporan-bulanan-kbtk/tambah/:id/:bidang/:instructor',
    name: 'admin-laporan-bulanan-kb-tambah',
    component: LaporanBulananKBTambah,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/laporan-bulanan-kbtk/edit/:idsiswa/:id/:instructor/:bidang',
    name: 'admin-laporan-bulanan-kb-edit',
    component: LaporanBulananKBEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/laporan-bulanan-kbtk/report/:id/:instructor/:bidang',
    name: 'admin-laporan-bulanan-kb-history',
    component: LaporanBulananKBHistory,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/laporan-bulanan-kbtk/print/:id/:instructor/:bidang',
    name: 'admin-laporan-bulanan-kb-print',
    component: LaporanBulananKBPrint,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/laporan-akhir-kbtk/:id",
    name: "admin-laporan-akhir-kb",
    component: LaporanAkhirKBTKIndex,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/laporan-akhir-kbtk/tambah/:id/:bidang/:instructor',
    name: 'admin-laporan-akhir-kb-tambah',
    component: LaporanAkhirKBTKTambah,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/laporan-akhir-kbtk/edit/:idsiswa/:id/:instructor/:bidang',
    name: 'admin-laporan-akhir-kb-edit',
    component: LaporanAkhirKBTKEdit,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/laporan-akhir-kbtk/report/:id/:instructor/:bidang',
    name: 'admin-laporan-akhir-kb-history',
    component: LaporanAkhirKBTKHistory,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/laporan-akhir-kbtk/print/:id/:instructor/:bidang',
    name: 'admin-laporan-akhir-kb-print',
    component: LaporanAkhirKBTKPrint,
    meta: {
      requiresAuth: true
    }
  },


  
  {
    path: "/dashboards/landing",
    name: "Landing",
    component: Landing
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive
  },

  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban
  },
  {
    path: "/applications/wizard",
    name: "Wizard",
    component: Wizard
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover
  },
  // {
  //   path: "/authentication/signin/illustration",
  //   name: "Signin Illustration",
  //   component: Illustration
  // },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active"
});

//define route for handle authentication
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      //cek nilai dari getters isLoggedIn di module auth
      if (store.getters['auth/isLoggedIn']) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})


//create router



export default router;
