<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Edit typaynominal </h5>
             
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="updatetypaynominal">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">TA</label>
                          <select class="form-control" v-model="typaynominal.id_ta">
                            <option value="">-- select tahun ajaran --</option>
                            <option v-for="tahunajaran in tas" :key="tahunajaran.id" :value="tahunajaran.id">{{ tahunajaran.desc}}</option>
                          </select>
                          <small class="text-danger" v-if="validation.id_ta">{{
                            validation.id_ta[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Unit</label>
                          <select class="form-control" v-model="typaynominal.id_unit">
                            <option value="">-- select unit --</option>
                            <option v-for="u in units" :key="u.id" :value="u.id">{{ u.code}}</option>
                          </select>
                          <small class="text-danger" v-if="validation.id_unit">{{
                            validation.id_unit[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">typay</label>
                          <select class="form-control" v-model="typaynominal.id_typay">
                            <option value="">-- select typaynominal --</option>
                            <option v-for="typaynominal in typays" :key="typaynominal.id" :value="typaynominal.id">{{ typaynominal.name}}</option>
                          </select>
                          <small class="text-danger" v-if="validation.id_typaynominal">{{
                            validation.id_typaynominal[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Nominal</label>
                          <input
                            type="text"
                            placeholder="Nominal"
                            class="form-control"
                            v-model="typaynominal.nominal"
                          />
                          <small class="text-danger" v-if="validation.nominal">{{
                            validation.nominal[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Active</label>
                          <select class="form-control" v-model="typaynominal.active">
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
  
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                        &nbsp;
                        
                        <router-link
                        :to="{ name: 'admin-typaynominal', params: { idta: $route.params.idta, idunit: $route.params.idunit } }"
                        class="btn btn-danger btn-sm"
                      >
                        kembali
                      </router-link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive,onMounted, watch,  computed } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "typaynominalIndex",
    components: {},
    data() {
      return {
        showtypaynominal: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const typaynominal = reactive({
        id_ta: "",
        id_unit: "",
        id_typay: "",
        nominal: "",
        active: "",
      });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
  
      const route = useRoute();
  
    
  
  
      //function register, fungsi ini di jalankan ketika form di submit
      function updatetypaynominal() {
        //define variable
     
  
        let formData = new FormData();
        formData.append("id_ta", typaynominal.id_ta);
        formData.append("id_unit", typaynominal.id_unit);
        formData.append("id_typay", typaynominal.id_typay);
        formData.append("nominal", typaynominal.nominal);
        formData.append("active", typaynominal.active);
        formData.append("_method", "POST");
  
        //panggil actions "register" dari module "auth"
        store
          .dispatch("typaynominal/update", {
            typaynominalId: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-typaynominal" });
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
  
      onMounted(async () => {
        await store.dispatch("typaynominal/getDetail", route.params.id);
        await store.dispatch("tahunajaran/getAll");
        await store.dispatch("units/getAll");
        await store.dispatch("typay/getAll");
      });

      const tas = computed(() => {
        return store.getters["tahunajaran/getAll"];
      });

      const units = computed(() => {
        return store.getters["units/getAll"];
      });

      const typays = computed(() => {
        return store.getters["typay/getAll"];
      });
  
      watch(
        () => store.state.typaynominal.details,
        (newtypaynominal) => {
          if (newtypaynominal) {
            const {
              id_ta,
              id_unit,
              id_typay,
              nominal,
              active,
            } = newtypaynominal;
            typaynominal.id_ta = id_ta;
            typaynominal.id_unit = id_unit;
            typaynominal.id_typay = id_typay;
            typaynominal.nominal = nominal;
            typaynominal.active = active;
            

          }
        }
      );
  
      //computed
    
  
      //return a state and function
      return {
        typaynominal, // <-- state user
        validation, // <-- state validation
        updatetypaynominal,
        tas,
        units,
        typays
       
      };
    },
  };
  </script>