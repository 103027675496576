<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Edit Password</h5>
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                    <form  @submit.prevent="updatePassword">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="exampleFormControlInput1"
                                >Password Lama</label
                              >
                              <input
                                type="password"
                                placeholder="password lama"
                                class="form-control"
                                v-model="profile.old_password"
  
                              />
                              <small class="text-danger" v-if="validation.old_password">{{ validation.old_password[0] }}</small>
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="exampleFormControlInput1"
                                >Password Baru</label
                              >
                              <input
                                type="password"
                                placeholder="Password"
                                class="form-control"
                                v-model="profile.new_password"
  
                              />
                              <small class="text-danger" v-if="validation.new_password">{{ validation.new_password[0] }}</small>
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="exampleFormControlInput1"
                                >Konfirmasi Password Baru</label
                              >
                              <input
                                type="password"
                                placeholder="Password"
                                class="form-control"
                                v-model="profile.new_password_confirmation"
  
                              />
                              <small class="text-danger" v-if="validation.new_password_confirmation">{{ validation.new_password_confirmation[0] }}</small>
                            </div>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-12">
                            <button type="submit" class="btn btn-success btn-md">
                              simpan
                            </button>
                            &nbsp;
                            <nuxt-link
                              :to="{ name: 'admin-role' }"
                              class="btn btn-danger btn-md"
                              >kembali</nuxt-link
                            >
                          </div>
                        </div>
  
                      </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "MenuIndex",
    components: {},
    data() {
      return {
        showMenu: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const profile = reactive({
        old_password: '',
          new_password: '',
          new_password_confirmation: '',
      });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
  
      const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
      function updatePassword() {
        //define variable
      
  
        let formData = new FormData();
  
        formData.append('old_password', profile.old_password)
        formData.append('new_password', profile.new_password)
        formData.append('new_password_confirmation', profile.new_password_confirmation)
        formData.append("_method", "POST");
  
        //panggil actions "register" dari module "auth"
        store
          .dispatch("user/updatePassword", {
            menuId: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-user" });
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
  
     
      //return a state and function
      return {
        profile, // <-- state user
        validation, // <-- state validation
        updatePassword,
      
      };
    },
  };
  </script>