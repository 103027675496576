<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Edit guru</h5>
             
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="updateguru">
  
                    <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Fullname</label
                            >
                            <input
                              type="text"
                              placeholder="Fullname"
                              class="form-control"
                              readonly
                              v-model="guru.fullname"
                            />
                            <small class="text-danger" v-if="validation.fullname">{{ validation.fullname[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Nickname</label
                            >
                            <input
                              type="text"
                              placeholder="Nickname"
                              class="form-control"
                              v-model="guru.nickname"
                            />
                            <small class="text-danger" v-if="validation.nickname">{{ validation.nickname[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Gender</label
                            >
                            <select class="form-control" v-model="guru.gender">
                            <option value="">-- select Gender --</option>
                              <option value="Laki-laki">Laki-laki</option>
                              <option value="Perempuan">Perempuan</option>
                          </select>
                            <small class="text-danger" v-if="validation.gender">{{ validation.gender[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Birthplace</label
                            >
                            <input
                              type="text"
                              placeholder="Birthplace"
                              class="form-control"
                              v-model="guru.birthplace"
                            />
                            <small class="text-danger" v-if="validation.birthplace">{{ validation.birthplace[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Birthdate</label
                            >
                            <input
                              type="date"
                              placeholder="Birthdate"
                              class="form-control"
                              v-model="guru.birthdate"
                            />
                            <small class="text-danger" v-if="validation.birthdate">{{ validation.birthdate[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Status Pernikahan</label
                            >
                            <select class="form-control" v-model="guru.marital_status">
                            <option value="">-- select Status Pernikahan --</option>
                              <option value="Menikah">Menikah</option>
                              <option value="Belum Menikah">Belum Menikah</option>
                          </select>
                            <small class="text-danger" v-if="validation.marital_status">{{ validation.marital_status[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Kewarganegaraan</label
                            >
                            <select class="form-control" v-model="guru.wni">
                            <option value="">-- select Kewarganaegaraan --</option>
                              <option value="WNI">WNI</option>
                              <option value="WNA">WNA</option>
                            </select>
                            <small class="text-danger" v-if="validation.wni">{{ validation.wni[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Agama</label
                            >
                            <select class="form-control" v-model="guru.religion">
                            <option value="">-- Agama --</option>
                              <option value="Islam">Islam</option>
                              <option value="Kristen">Kristen</option>
                              <option value="Katolik">Katolik</option>
                              <option value="Hindu">Hindu</option>
                              <option value="Budha">Budha</option>
                              <option value="Konghucu">Konghucu</option>
                            </select>
                            <small class="text-danger" v-if="validation.religion">{{ validation.religion[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Alamat</label
                            >
                            <input
                              type="text"
                              placeholder="icon"
                              class="form-control"
                              v-model="guru.address"
                            />
                            <small class="text-danger" v-if="validation.address">{{ validation.address[0] }}</small>
                          </div>
                        </div>
                      </div>



                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Provinsi</label
                            >
                            <select class="form-control" v-model="guru.id_province"  @change="changeProvince">
                            <option value="">-- select Provinsi --</option>
                            <option v-for="p in province" :key="p.id" :value="p.id">{{ p.name }}</option>
                          </select>
                            <small class="text-danger" v-if="validation.id_province">{{ validation.id_province[0] }}</small>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Kota</label
                            >
                            <select class="form-control" v-model="guru.id_city"  @change="changeCity">
                            <option value="">-- select Kota --</option>
                            <option v-for="c in city" :key="c.id" :value="c.id">{{ c.name }}</option>
                          </select>
                            <small class="text-danger" v-if="validation.id_city">{{ validation.id_city[0] }}</small>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Kecamatan</label
                            >
                            <select class="form-control" v-model="guru.id_district"  @change="changeDistrict">
                            <option value="">-- select Kecamatan --</option>
                            <option v-for="d in district" :key="d.id" :value="d.id" >{{ d.name }}</option>
                          </select>
                            <small class="text-danger" v-if="validation.id_district">{{ validation.id_district[0] }}</small>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Kelurahan</label
                            >
                            <select class="form-control" v-model="guru.id_subdistrict" >
                            <option value="">-- select Kelurahan --</option>
                            <option v-for="s in subdistrict" :key="s.id" :value="s.id">{{ s.name }}</option>
                          </select>
                            <small class="text-danger" v-if="validation.id_subdistrict">{{ validation.id_subdistrict[0] }}</small>
                          </div>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Email</label
                            >
                            <input
                              type="email"
                              placeholder="email"
                              class="form-control"
                              v-model="guru.email"
                              readonly
                            />
                            <small class="text-danger" v-if="validation.email">{{ validation.email[0] }}</small>
                          </div>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Telephone</label
                            >
                            <input
                              type="text"
                              placeholder="telephone"
                              class="form-control"
                              v-model="guru.cellphone"
                            />
                            <small class="text-danger" v-if="validation.cellphone">{{ validation.cellphone[0] }}</small>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >Bahasa Yang Digunakan</label
                            >
                            <select class="form-control" v-model="guru.lang">
                            <option value="">-- Bahasa --</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Inggris">Inggris</option>
                              <option value="Mandarin">Mandarin</option>
                              <option value="Lainya">Lainya</option>
                            </select>
                            <small class="text-danger" v-if="validation.lang">{{ validation.lang[0] }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="exampleFormControlInput1"
                              >NUPTK</label
                            >
                            <input
                              type="text"
                              placeholder="nuptk"
                              class="form-control"
                              v-model="guru.nuptk"
                            />
                            <small class="text-danger" v-if="validation.nuptk">{{ validation.nuptk[0] }}</small>
                          </div>
                        </div>
                      </div>
  
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                        &nbsp;
                        <router-link
                          :to="{ name: 'admin-guru' }"
                          class="btn btn-danger btn-sm"
                          >kembali</router-link
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive, computed, onMounted, watch } from "vue";
  import { useStore } from "vuex";
  import {  useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "guruIndex",
    components: {},
    data() {
      return {
        showguru: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //guru state
      const guru = reactive({
        fullname: '',
          nickname:'',
          gender:'',
          birthplace:'',
          birthdate:'',
          marital_status:'',
          wni:'',
          religion:'',
          edulevel:'',
          address:'',
          id_province:'',
          id_city:'',
          id_district:'',
          id_subdistrict:'',
          cellphone:'',
          email:'',
          lang:'',
          nuptk:'',
      });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();

    const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
      function updateguru() {
        //define variable
       
  
        let formData = new FormData();
  
        formData.append('fullname', guru.fullname)
        formData.append('nickname', guru.nickname)
        formData.append('gender', guru.gender)
        formData.append('birthplace', guru.birthplace)
        formData.append('birthdate', guru.birthdate)
        formData.append('id_province', guru.id_province)
        formData.append('id_city', guru.id_city)
        formData.append('id_district', guru.id_district)
        formData.append('id_subdistrict', guru.id_subdistrict)
        formData.append('address', guru.address)
        formData.append('email', guru.email)
        formData.append('wni', guru.wni)
        formData.append('cellphone', guru.cellphone)
        formData.append('lang', guru.lang)
        formData.append('nuptk', guru.nuptk)
        formData.append('marital_status', guru.marital_status)
        formData.append('edulevel', guru.edulevel)
        formData.append('religion', guru.religion)
        formData.append("_method", "POST");
  
        //panggil actions "register" dari module "auth"
        store
          .dispatch("guru/update", {
            guruId: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-guru" });
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
  
      onMounted(async () => {
        await store.dispatch('global/getProvinsi');
        await store.dispatch('global/getCity');
        await store.dispatch('global/getDistrict');
        await store.dispatch('global/getSubDistrict');
        await store.dispatch("guru/getDetail", route.params.id);
      });
  
      watch(
        () => store.state.guru.details,
        (newguru) => {
          //console.log('newguru',newguru);
          if (newguru) {
            const {
                fullname,
                nickname,
                gender,
                birthplace,
                birthdate,
                marital_status,
                wni,
                religion,
                edulevel,
                address,
                id_province,
                id_city,
                id_district,
                id_subdistrict,
                cellphone,
                email,
                lang,
                nuptk,
            } = newguru;
            guru.fullname = fullname;
            guru.nickname = nickname;
            guru.gender = gender;
            guru.birthplace = birthplace;
            guru.birthdate = birthdate;
            guru.marital_status = marital_status;
            guru.wni = wni;
            guru.religion = religion;
            guru.edulevel = edulevel;
            guru.address = address;
            guru.id_province = id_province;
            guru.id_city = id_city;
            guru.id_district = id_district;
            guru.id_subdistrict = id_subdistrict;
            guru.cellphone = cellphone;
            guru.email = email;
            guru.lang = lang;
            guru.nuptk = nuptk;
            
          }
        }
      );
  
      //computed
      const province = computed(() => {
        //panggil getter dengan nama "getParent" di module "guru" vuex
        return store.getters["global/getProvinsi"];
      });
  
      const city = computed(() => {
        //panggil getter dengan nama "getParent" di module "guru" vuex
        return store.getters["global/getCity"];
      });

      const district = computed(() => {
        //panggil getter dengan nama "getParent" di module "guru" vuex
        return store.getters["global/getDistrict"];
      });

      const subdistrict = computed(() => {
        //panggil getter dengan nama "getParent" di module "guru" vuex
        return store.getters["global/getSubDistrict"];
      });

      const changeProvince = async () => {

        await store.dispatch("global/getCity", guru.id_province)
       
        }

        const changeCity = async () => {

await store.dispatch("global/getDistrict", guru.id_city)

}

const changeDistrict = async () => {
    await store.dispatch("global/getSubDistrict", guru.id_district)
        
        }

      


  
      //return a state and function
      return {
        guru, // <-- state guru
        validation, // <-- state validation
        updateguru,
        province,
        city,
        district,
        subdistrict,
        changeProvince,
        changeCity,
        changeDistrict
      };
    },
  };
  </script>