<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah user</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveuser">
               
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Username</label
                        >
                        <input
                          type="text"
                          placeholder="Username"
                          class="form-control"
                          v-model="user.username"
                        />
                        <small class="text-danger" v-if="validation.username">{{ validation.username[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Email</label
                        >
                        <input
                          type="email"
                          placeholder="Email"
                          class="form-control"
                          v-model="user.email"
                        />
                        <small class="text-danger" v-if="validation.email">{{ validation.email[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Password</label
                        >
                        <input
                          type="password"
                          placeholder="Password"
                          class="form-control"
                          v-model="user.password"
                        />
                        <small class="text-danger" v-if="validation.password">{{ validation.password[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-12">
                      <label>Role Akses</label>
                      <select class="form-control"  v-model="user.roles">
                        <option value="">-- select manu --</option>
                        <option  v-for="role in listroles" :key="role.id" :value="role.id">{{ role.name }}</option>
                      </select>
                    </div>

                      <small class="text-danger" v-if="validation.roles">{{ validation.roles[0] }}</small>

                  </div>

                  <!-- pegawai -->
                  <hr>
                  <h5>Profile Pegawai</h5>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Nama Lengkap</label
                        >
                        <input
                          type="text"
                          placeholder="nama_lengkap"
                          class="form-control"
                          v-model="user.nama_lengkap"
                        />
                        <small class="text-danger" v-if="validation.nama_lengkap">{{ validation.nama_lengkap[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                   <label>Unit Kerja Pegawai</label>
                    <div class="col-2"  v-for="u in units" :key="u.id">
                      <div class="form-check" >
                        <input class="form-check-input"  type="checkbox"  v-model="user.unit" :value="u.id" :id="`check-${u.id}`">
                        <label class="custom-control-label" :for="`check-${u.id}`">{{ u.name }}</label>
                        <br/>
                      </div>
                      <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label>Apakah Pegawai Ini Guru ?</label>
                     <div class="col-2">
                       <div class="form-check" >
                         <input class="form-check-input" type="checkbox"  v-model="user.guru">
                         <label class="custom-control-label" >Ya</label>
                         <br/>
                       </div>
                       <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                     </div>
                   </div>


                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="">Photo Profile</label>
                        <input type="file" @change="handleFilePhotoChange" class="form-control">
                        <small class="text-danger" v-if="validation.photo">{{ validation.photo[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-user' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "userIndex",
  components: {},
  data() {
    return {
      showuser: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

 
  setup() {
    //user state
    const user = reactive({
      username: '',
      email:'',
      password:'',
      roles:[],
      nama_lengkap:'',
      unit:[],
      photo:'',
      guru:''
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    function handleFilePhotoChange(e) {
    //get image
    const image = this.user.photo = e.target.files[0]
    

    //check fileType
    if (!image.type.match('image.*')) {

        //if fileType not allowed, then clear value and set null
        e.target.value = ''

        //set state "category.image" to null
        this.user.photo = null

        //show sweet alert
        
        Swal.fire({
          title: 'OOPS!',
          text: "Format File Tidak Didukung!",
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
      }

    }

    //function register, fungsi ini di jalankan ketika form di submit
    function saveuser() {
     
      //define variable
      let username = user.username;
      let email = user.email;
      let password = user.password;
      let roles = user.roles;
      // //console.log('role',roles);
      
      let unit = user.unit;
      let photo = user.photo;
      let guru = user.guru;
      let nama_lengkap = user.nama_lengkap;

      let formData = new FormData();

      formData.append("username", username);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("roles", roles);
      formData.append("nama_lengkap", nama_lengkap);
      formData.append("unit[]", unit);
      formData.append("photo", photo);
      formData.append("guru", guru);
      //console.log('formuser', formData)
      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("user/store", 
          formData,
        )
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-user" });
          Swal.fire({
          icon: 'success',
          title: 'Tambah data berhasil',
        });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("roles/getAll");
      await store.dispatch("units/getAll");
    });

    //computed
    const listroles = computed(() => {
      //panggil getter dengan nama "getParent" di module "user" vuex
      return store.getters["roles/getAll"];
    });

    const units = computed(() => {
      //panggil getter dengan nama "getParent" di module "user" vuex
      return store.getters["units/getAll"];
    });

   

    //return a state and function
    return {
      user, // <-- state user
      validation, // <-- state validation
      saveuser,
      listroles, 
      units,
      handleFilePhotoChange
    };
  },
};
</script>