<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Input Nilai BK </h5>
              <router-link to="/nilaibk" class="btn btn-primary btn-sm">Kembali</router-link>
            </div>
            <div class="row">
              <dl class="row">
                <dt class="col-sm-2">Kelas</dt>
                <dd class="col-sm-10">{{ kelas.classname }}</dd>

                <dt class="col-sm-2">Walikelas</dt>
                <dd class="col-sm-10">{{ kelas.wali_kelas }}</dd>
              </dl>

            </div>
          </div>
          <div class="card-body">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item" v-for="m in siswas.data" :key="m.id">
                <p class="accordion-header" id="headingOne">
                  <button class="accordion-button d-flex justify-content-between " type="button" data-bs-toggle="collapse"
                    :data-bs-target="`#collapse${m.id}`" aria-expanded="true" aria-controls="collapseOne">
                    <i class="fas fa-angle-double-right"></i> &nbsp; {{ m.fullname }}
                  </button>
                </p>
                <div :id="`collapse${m.id}`" class="accordion-collapse collapse" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="">Mid</label>
                          <textarea class="form-control" :value="getNilaiMid(m.id)"
                            @input="updateData(m.id, data.mid, $event)">

                        </textarea>
                        </div>
                        <div class="form-group">
                          <label for="">Akhir Semester</label>
                          <textarea class="form-control" :value="getNilai(m.id)" @input="updateData(m.id, data.akhir_semester, $event)">

                        </textarea>

                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRoute } from "vue-router";

export default {
  name: "kelasIndex",


  setup() {
    const store = useStore();
    const route = useRoute();
    const validation = ref([]);
    const kelas = reactive({
      classname: '',
      id_ta: '',
      wali_kelas: '',
      guru_pendamping: '',
      id_unitlevel: '',
      id_unit: ''
    });

    const data = reactive({
      // currentPage: 1,
      mid: 'mid',
      akhir_semester: 'akhir_semester',
      perPage: 100,
      search: '',
      raport: {
        file: '',
        id_studentactive: ''
      },

    });

    onMounted(async () => {
      await store.dispatch("siswa/getSiswaNilaiSikap", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id
      });
      await store.dispatch("kelas/getDetail", route.params.id);
      await store.dispatch("groupkategorisikap/getByUnitdanTa", route.params.id);
      await store.dispatch("nilaibk/getDataMid", route.params.id);
      await store.dispatch("nilaibk/getData", route.params.id);
      await store.dispatch("keterangannilai/getAll");
    });

    const siswas = computed(() => {
      return store.getters["siswa/getSiswaNilaiSikap"];

    });

    const kategori = computed(() => {
      return store.getters["groupkategorisikap/getByUnitdanTa"];
    });


    const nilaibkmid = computed(() => {
      return store.getters["nilaibk/getDataMid"];
    });

    const nilaibk = computed(() => {
      return store.getters["nilaibk/getData"];
    });

    async function updateData(idsiswa, jenis, event) {
      let formData = new FormData();
      formData.append('id_siswa', idsiswa)
      formData.append('nilai', event.target.value)
      formData.append('kelas_id', route.params.id)
      formData.append('jenis_semester', jenis)

      // console.log(formData);
      await store.dispatch('nilaibk/store', formData)
        .then(() => {

        })
        .catch((error) => {

          validation.value = error;

          Swal.fire({
            title: "GAGAL!",
            text: validation.value.error,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        });
    }

  



    const getNilaiMid = (idSiswa) => {
      const nilai = nilaibkmid.value.find(item => {
      
        return (
          item.id_siswa === idSiswa
        );
      });
      // console.log('hahah', nilai.nilai);
      return nilai ? nilai.nilai : '';
    };

    const getNilai = (idSiswa) => {
      const nilai = nilaibk.value.find(item => {
       
        return (
          item.id_siswa === idSiswa
        );
      });
      // console.log('hahah', nilai.nilai);
      return nilai ? nilai.nilai : '';
    };





    watch(
      () => store.state.kelas.details,
      (newkelas) => {
        if (newkelas) {
          const {
            wali_kelas,
            master_kelas,
            id_ta,

            guru_pendamping

          } = newkelas;
          kelas.classname = master_kelas.classname;
          kelas.id_ta = id_ta;
          kelas.id_unit = master_kelas.unit_level.id_unit
          kelas.wali_kelas = wali_kelas ? wali_kelas.fullname : '';
          kelas.guru_pendamping = guru_pendamping ? guru_pendamping.fullname : '';
          kelas.id_unitlevel = master_kelas.id_unitlevel;

        }
      }
    );



    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      kategori,
      kelas,
      siswas,
      validation,
      updateData,
      getNilaiMid,
      getNilai,
      data,
      nilaibk,
      nilaibkmid

    };
  },
};
</script>
  