<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Edit work</h5>
             
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="updatework">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="">Nama</label>
                          <input
                            type="text"
                            placeholder="Nama work"
                            class="form-control"
                            v-model="work.name"
                          />
                          <small class="text-danger" v-if="validation.name">{{
                            validation.name[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                  
                   
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                        &nbsp;
                        <router-link
                          :to="{ name: 'admin-work' }"
                          class="btn btn-danger btn-sm"
                          >kembali</router-link
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive,onMounted, watch } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "workIndex",
    components: {},
    data() {
      return {
        showwork: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const work = reactive({
        name: "",
        default: "",
        action: "",
      });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
  
      const route = useRoute();
  
    
  
  
      //function register, fungsi ini di jalankan ketika form di submit
      function updatework() {
        //define variable
     
  
        let formData = new FormData();
        formData.append("name", this.work.name);
       
        formData.append("_method", "POST");
  
        //panggil actions "register" dari module "auth"
        store
          .dispatch("work/update", {
            workId: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-work" });
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
  
      onMounted(async () => {
        await store.dispatch("work/getDetail", route.params.id);
      });
  
      watch(
        () => store.state.work.details,
        (newwork) => {
          if (newwork) {
            const {
              name,
             
            } = newwork;
            work.name = name;
          

          }
        }
      );
  
      //computed
    
  
      //return a state and function
      return {
        work, // <-- state user
        validation, // <-- state validation
        updatework,
       
      };
    },
  };
  </script>