<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Data  </h5>
           
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updatePengajar">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Unit</label>
                        <select
                        class="form-control select2"
                        v-model="pengajar.id_unit"


                        >
                          <option value="">-- select Unit--</option>
                          <option
                            v-for="u in units"
                            :key="u.id"
                            :value="u.id"
                          >
                            {{ u.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Mata Pelajaran</label
                        >
                        <select class="form-control" v-model="pengajar.mapel_id">
                        <option value="">-- select mapel --</option>
                        <option v-for="m in filteredMatapelajarans" :key="m.id" :value="m.id">{{ m.nama_mapel }}</option>
                      </select>
                        <small class="text-danger" v-if="validation.mapel_id">{{ validation.mapel_id[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Guru</label
                        >
                        <select class="form-control" v-model="pengajar.instructor_id">
                        <option value="">-- select guru --</option>
                        <option v-for="g in gurus" :key="g.id" :value="g.id">{{ g.fullname }}</option>
                      </select>
                        <small class="text-danger" v-if="validation.instructor_id">{{ validation.instructor_id[0] }}</small>
                      </div>
                    </div>
                  </div>

                  



                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-pengajar' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "UserIndex",
  components: {},
  data() {
    return {
      showUser: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const pengajar = reactive({
          mapel_id: '',
          instructor_id: '',
          id_unit:''
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
    function updatePengajar() {
      //define variable

      let formData = new FormData();

      
      formData.append('mapel_id', this.pengajar.mapel_id)
      formData.append('instructor_id', this.pengajar.instructor_id)
      formData.append('id_unit', this.pengajar.id_unit)



      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("pengajar/update", {
          userId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-pengajar" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    watch(
      () => store.state.pengajar.details,
      (newPengajar) => {
        //console.log('newMapel',newPengajar);
        if (newPengajar) {
          const {
            mapel_id,
            instructor_id,
            id_unit
          } = newPengajar;
            pengajar.mapel_id = mapel_id;
            pengajar.instructor_id = instructor_id;
            pengajar.id_unit = id_unit;
           
        }
      }
    );
   

    onMounted(async () => {

      await store.dispatch('matapelajaran/getAll'),
      await store.dispatch('guru/getAll');
      await store.dispatch("pengajar/getDetail", route.params.id);
      await store.dispatch("units/getAll");
    });

   

    const gurus = computed(() => {
      return store.getters["guru/getAll"];
    });

    const units = computed(() => {
      //panggil getter dengan nama "getParent" di module "unitlevel" vuex
      return store.getters["units/getAll"];
    });

    const matapelajarans = computed(() => {
      return store.getters["matapelajaran/getAll"];
    });
    
    const filteredMatapelajarans = computed(() => {
      // Filter mata pelajaran berdasarkan units.id yang dipilih
      console.log(matapelajarans);
      return matapelajarans.value.filter(m => m.id_unit === pengajar.id_unit);
    });
    //return a state and function
    return {
      pengajar, // <-- state user
      matapelajarans,
      gurus,
      validation, // <-- state validation
      updatePengajar,
      units,
      filteredMatapelajarans
    };
  },
};
</script>