<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Detail siswa</h5>
             
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                 
                  

                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>Identitas Siswa</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <tr style="border:none">
                                            <td width="200px">Nama Lengkap</td>
                                            <td width="20px">:</td>
                                            <td>{{siswa.fullname}}</td>
                                        </tr>
                                        <tr>
                                            <td>Nama Panggilan</td>
                                            <td>:</td>
                                            <td>{{siswa.nickname}}</td>
                                        </tr>
                                        <tr>
                                            <td>Jenis Kelamin</td>
                                            <td>:</td>
                                            <td>{{siswa.gender}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tempat Lahir</td>
                                            <td>:</td>
                                            <td>{{siswa.tempatlahir}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal Lahir</td>
                                            <td>:</td>
                                            <td>{{siswa.tanggallahir}}</td>
                                        </tr>
                                   
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4 col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>Data Orangtua</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <tr style="border:none">
                                            <td width="200px">Nama Lengkap</td>
                                            <td width="20px">:</td>
                                            <td>{{siswa.fullname}}</td>
                                        </tr>
                                        <tr>
                                            <td>Nama Panggilan</td>
                                            <td>:</td>
                                            <td>{{siswa.nickname}}</td>
                                        </tr>
                                        <tr>
                                            <td>Jenis Kelamin</td>
                                            <td>:</td>
                                            <td>{{siswa.gender}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tempat Lahir</td>
                                            <td>:</td>
                                            <td>{{siswa.tempatlahir}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tanggal Lahir</td>
                                            <td>:</td>
                                            <td>{{siswa.tanggallahir}}</td>
                                        </tr>
                                   
                                </div>
                            </div>
                        </div> -->
                       
                    </div>

                    <div class="row mt-4">
                      <div class="col-12">
                        <router-link
                          :to="{ name: 'admin-siswaaktif' }"
                          class="btn btn-danger btn-md"
                          >kembali</router-link
                        >
                      </div>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted, watch } from "vue";
  import { useStore } from "vuex";
  import { useRoute } from "vue-router";
  
  export default {
    name: "siswaIndex",
    components: {},
    data() {
      return {
        showsiswa: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state
      const siswa = reactive({
        fullname: '',
                tahunajaran:'',
                tingkatan:'',
                sekolah:'',
                nickname:'',
                gender:'',
                tempatlahir:'',
                tanggallahir:'',
      });
  
      //validation state

  
      //store vuex
      const store = useStore();
  


    const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
    
  
      onMounted(async () => {
  
        await store.dispatch("reg/getDetailSiswa", route.params.id);
      });
  
      watch(
        () => store.state.reg.pesertadetail,
        (newsiswa) => {
          //console.log('newsiswa',newsiswa);
          if (newsiswa) {
            const {
                fullname,
                nickname,
                gender,
                birthplace,
                birthdate,
                tahunajaran,
                tingkatan,
                sekolah
            } = newsiswa;
            siswa.fullname = fullname;
            siswa.nickname = nickname;
            siswa.gender = gender;
            siswa.tempatlahir = birthplace;
            siswa.tanggallahir = birthdate;
            siswa.tahunajaran = tahunajaran;
            siswa.tingkatan = tingkatan;
            siswa.sekolah = sekolah
            
            
          }
        }
      );
  
     
      


  
      //return a state and function
      return {
        siswa, // <--
      
      };
    },
  };
  </script>