<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Laporan Perkembangan Bulanan</h5>

              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                 
                  

                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ siswa.fullname }} </h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <!-- <table style="border: 1px;">
                                          <tr style="border:none">
                                            <td >Nama Lengkap</td>
                                            <td >:</td>
                                            <td>{{siswa.fullname}}</td>
                                        </tr>
                                        </table> -->                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="saveLaporan">
                      <div class="row m-3 table-responsive">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="">Perkembangan</label>
                            <table border="1" style="table-layout: fixed; width: 100%;border-collapse: separate;
                            border: 1px solid grey;
                            border-spacing: 0px;
                            ">
                              <thead>
                                <tr style="text-align:center">
                                  <th>Semester</th>
                                  <th>Bulan</th>
                                  <th>Jenis Laporan</th>
                                  <th style="width: 45%;">Komentar</th>
                                  <th>Foto 1</th>
                                  <th>Foto 2</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="m in history.data" :key="m.id" style="height: 1px">
                                  <td valign="top">&nbsp;{{ m.tas.desc }} </td>
                                  <td valign="top">&nbsp;{{ m.bulans.nama }}</td>
                                  <td valign="top">&nbsp;{{ m.elemen }} </td>
                                  <td valign="top" style="word-wrap: break-word !important;padding: 7px;">
                                    <div style="word-wrap: break-word !important;text-align: justify;">
                                      {{ limitText(m.narasi, 1000) }}
                                    </div>
                                  </td>
                                  <td align="center">
                                    <a v-if="m.foto1 != null " :href="'http://localhost:8000/public/report/' + m.foto1" target="_blank">
                                      <!-- <i class="fas fa-image"></i> -->
                                      <div style="width: 100px;">
                                        <img :src="'http://localhost:8000/public/report/' + m.foto1" alt=""  class="image img-thumbnail img-rounded" style="height:4cm;width:auto">
                                      </div>
                                    </a>
                                  </td>
                                  <td align="center">
                                    <a v-if="m.foto2 != null "  :href="'http://localhost:8000/public/report/' + m.foto2" target="_blank">
                                      <!-- <i class="fas fa-image"></i> -->
                                      <div style="width: 100px;">
                                        <img :src="'http://localhost:8000/public/report/' + m.foto2" alt="" class="image img-thumbnail img-rounded" style="height:4cm;width:auto">
                                      </div>
                                    </a>
                                  </td>
                                  <td align="center">
                                    
                                    <router-link v-if="$route.params.bidang.toLowerCase() === m.elemen.toLowerCase()" :to="{name: 'admin-laporan-bulanan-kb-edit', params:{idsiswa:m.id_student,id: m.id,instructor:this.$route.params.instructor}}" class="btn btn-warning btn-xs m-0 me-2"> <i class="fas fa-edit"></i></router-link>
                                    <router-link v-if="$route.params.bidang.toLowerCase() === m.elemen.toLowerCase()" :to="{name: 'admin-laporan-bulanan-kb-print', params:{id: m.id } }" class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-print"></i></router-link>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                                      <div class="col-12">
                                        
                                        <router-link
                                        :to="{ name: 'admin-laporan-bulanan-kb' , params:{id:this.$route.params.instructor }}"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                      </div>
                                    </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted, watch, computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import Api from "../../../api/Api";
  
  export default {
    name: "KBEdit",
    components: {},
    data() {
      return {
        showsiswa: false,
      };
    },
    methods: {
      limitText(text, limit) {
        if (text.length > limit) {
          return text.substring(0, limit) + '...';
        }
        return text;
      },
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state
      const siswa = reactive({
        foto1:'',
        foto2:'',
      });

      const variable = reactive({
        id_unit: "",
        id_ta:"",
        id_student: "",
        bulan: "",
        tahun: "",
        elemen: "",
        narasi: "",
        foto1:"",
        foto2:"",
      });


      const listbulan = computed(() => {
            return store.getters["global/getBulan"];
      });


      const tahunajaran = computed(() => {
            return store.getters["tahunajaran/getActive"];
      });

      const history = computed(() => {
        return store.getters["narasikb/history"];
      });


      //validation state

  
      //store vuex
      const store = useStore();

      const router = useRouter();

      const validation = ref([]);
  


    const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
    
      

      
  
      watch(
        () => store.state.reg.pesertadetail,
        (newsiswa) => {
          //console.log('newsiswa',newsiswa);
          if (newsiswa) {
            const {
                fullname,
                nickname,
                gender,
                birthplace,
                birthdate,
                tingkatan,
                sekolah,
                id_unitlevel,
                id,
            } = newsiswa;
            siswa.fullname = fullname;
            siswa.nickname = nickname;
            siswa.gender = gender;
            siswa.tempatlahir = birthplace;
            siswa.tanggallahir = birthdate;
            siswa.tingkatan = tingkatan;
            siswa.sekolah = sekolah;
            siswa.id_unitlevel = id_unitlevel;
            siswa.id = id;            
          }
        }
      );

      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        raport: {
          file: '',
          id_studentactive:''
        },
        
      });

      async function handleFileChange(e) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(image)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }

      async function handleFileChange2(e) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(image)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
      

      function saveLaporan() {
        //define variable
        // console.log(" siswa ",siswa);
        let formData = new FormData();
        formData.append('id_unit', siswa.id_unitlevel);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('bulan', this.variable.bulan);
        formData.append('tahun', this.variable.tahun);
        formData.append('elemen', this.variable.elemen);
        formData.append('narasi', this.variable.narasi);
        formData.append('foto1', this.variable.foto1);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        //panggil actions "register" dari module "auth"
        store
          .dispatch("narasikb/store", formData)
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-laporan-bulanan-kb" });
            Swal.fire({
              icon: "success",
              title: "Simpan data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
      
      onMounted(async () => {
        await store.dispatch("reg/getDetailSiswa", route.params.id);
        await store.dispatch("global/getBulan");
        await store.dispatch("tahunajaran/getActive");
        const tahunajaran = tahunajaran;
        await store.dispatch("narasikb/history",{id_student:route.params.id,elemen:route.params.bidang});
      });


      //return a state and function
      return {
        siswa, // <--
        listbulan,
        variable,
        handleFileChange,
        handleFileChange2,
        data,
        saveLaporan,
        validation,
        tahunajaran,
        history,
        Api
      };
    },
  };
  </script>
  <style scoped>
  table,th,td {
    border-width:1px !important;
  }
</style>