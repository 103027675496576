<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Detail Siswa</h5>
              <router-link
              :to="{ name: 'admin-pendaftarlulus' }"
              class="btn btn-danger btn-sm"
              >kembali</router-link
            >
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <div class="card position-sticky top-1">
                  <ul class="nav flex-column bg-white border-radius-lg p-3">
                    <li class="nav-item">
                      <a
                        class="nav-link text-body d-flex align-items-center"
                        data-scroll=""
                        href="#profile-siswa"
                      >
                        <i class="ni ni-spaceship me-2 text-dark opacity-6"></i>
                        <span class="text-sm">Profile Siswa</span>
                      </a>
                    </li>
                    <li class="nav-item pt-2">
                      <a
                        class="nav-link text-body d-flex align-items-center"
                        data-scroll=""
                        href="#profile-orangtua"
                      >
                        <i class="ni ni-books me-2 text-dark opacity-6"></i>
                        <span class="text-sm">Profile Orang Tua</span>
                      </a>
                    </li>
                    <li class="nav-item pt-2">
                      <a
                        class="nav-link text-body d-flex align-items-center"
                        data-scroll=""
                        href="#profile-alamat"
                      >
                        <i class="ni ni-atom me-2 text-dark opacity-6"></i>
                        <span class="text-sm">Data Tempat Tinggal</span>
                      </a>
                    </li>
                    <li class="nav-item pt-2">
                      <a
                        class="nav-link text-body d-flex align-items-center"
                        data-scroll=""
                        href="#profile-kesehatan"
                      >
                        <i class="ni ni-ui-04 me-2 text-dark opacity-6"></i>
                        <span class="text-sm">Riwayat Penyakit</span>
                      </a>
                    </li>

                    <li class="nav-item pt-2">
                      <a
                        class="nav-link text-body d-flex align-items-center"
                        data-scroll=""
                        href="#profile-dokumen"
                      >
                        <i class="ni ni-bell-55 me-2 text-dark opacity-6"></i>
                        <span class="text-sm">Dokumen Upload</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="card mt-2">
                  <div class="card-body">

                    <a :href="`https://api.annisaa-izada.sch.id/api/reg/parentagreement/${siswa.noreg}/1ifj290fj4890fu98fu98u`" target="_blank" class="btn btn-primary btn-block"> <i class="fa fa-download"></i> Pernyataan Orang Tua</a>
                    <a :href="`https://api.annisaa-izada.sch.id/api/reg/profileresume/${siswa.noreg}/eifuh2efh28hech82`" target="_blank" class="btn btn-info btn-block"> <i class="fa fa-download"></i> Print Bioadata Pendaftaran</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-9 mt-lg-0 mt-4">
                <div class="card card-body" id="profile">
                  <div class="row" >
                    <div class="col-sm-auto col-4">
                      <div class="avatar avatar-xl position-relative">
                        <img :src="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.photopath}`" class="w-100 border-radius-lg shadow-sm" v-if="siswa.photopath != null"/>
                        <img src="https://via.placeholder.com/100x100" alt="bruce" class="w-100 border-radius-lg shadow-sm" v-else>
                      </div>
                    </div>
                    <div class="col-sm-auto col-8 my-auto">
                      <div class="h-100">
                        <h5 class="mb-1 font-weight-bolder">
                         {{ siswa.fullname }}
                        </h5>
                        <p class="mb-0 font-weight-bold text-sm">
                          {{ siswa.unit }} - {{ siswa.kelas }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mt-4" id="profile-siswa">
                  <div class="card-header">
                    <h5>Profile  Siswa</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-6">
                        <label class="form-label">Nama Lengkap</label>
                        <div class="input-group">
                          <input id="firstName" name="firstName" class="form-control" type="text" :value="siswa.fullname" readonly>
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label">Nama Panggilan</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.nickname" readonly>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Jenis Kelamin</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.gender" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Tanggal Lahir</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="date" :value="siswa.birthdate" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Tempat Lahir</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.birthplace" readonly>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <label class="form-label mt-4">Kewarganegaraan</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.wni" readonly >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label mt-4">Bahasa</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.lang" readonly>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <label class="form-label mt-4">NIK</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.nik" readonly >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label mt-4">NIKK</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.nikk" readonly>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="card mt-4" id="profile-orangtua">
                  <div class="card-header">
                    <h5>Profile  Ayah</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-6">
                        <label class="form-label">Nama Lengkap</label>
                        <div class="input-group">
                          <input id="firstName" name="firstName" class="form-control" type="text" :value="siswa.namaayah" readonly>
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label">Hubungan</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" value="Ayah" readonly>
                        </div>
                      </div>
                      <div class="col-4">
                        <label class="form-label mt-4">Agama</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.agamaayah" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Tanggal Lahir</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="date" :value="siswa.birthdateayah" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Tempat Lahir</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.birthplaceayah" readonly>
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label mt-4">Kewarganegaraan</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.wniayah" readonly >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label mt-4">Pendidikan</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.pendidikanayah" readonly >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label mt-4">Email</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.emailayah" readonly >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label mt-4">No Kontak</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.nokontakayah" readonly>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="card mt-4" id="basic-info">
                  <div class="card-header">
                    <h5>Profile  Ibu</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-6">
                        <label class="form-label">Nama Lengkap</label>
                        <div class="input-group">
                          <input id="firstName" name="firstName" class="form-control" type="text" :value="siswa.namaIbu" readonly>
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label">Hubungan</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" value="Ibu" readonly>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Agama</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.agamaIbu" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Tanggal Lahir</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="date" :value="siswa.birthdateIbu" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Tempat Lahir</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.birthplaceIbu" readonly>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <label class="form-label mt-4">Kewarganegaraan</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.wniIbu" readonly >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label mt-4">Pendidikan</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.pendidikanIbu" readonly >
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <label class="form-label mt-4">Email</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.emailIbu" readonly >
                        </div>
                      </div>
                      <div class="col-6">
                        <label class="form-label mt-4">No Kontak</label>
                        <div class="input-group">
                          <input id="email" name="text" class="form-control" type="text" :value="siswa.nokontakIbu" readonly>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="card mt-4" id="profile-alamat">
                  <div class="card-header">
                    <h5>Tempat Tinggal</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-12">
                        <label class="form-label">Alamat</label>
                        <div class="input-group">
                         <textarea class="form-control" :value="siswa.alamat" readonly></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Jarak (km)</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.jarak" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Jarak (Jam)</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.jam" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">Jarak (menit)</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.menit" readonly>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">RT</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.rt" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">RW</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.rw" readonly>
                        </div>
                      </div>
                      <div class="col-sm-4 col-4">
                        <label class="form-label mt-4">post kode</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.postalcode" readonly>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>

                <div class="card mt-4" id="profile-kesehatan">
                  <div class="card-header">
                    <h5>Keterangan Kesehatan</h5>
                  </div>
                  <div class="card-body pt-0">

                    <div class="row">
                      <div class="col-sm-6 col-6">
                        <label class="form-label mt-4">Golongan Darah</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.darah" readonly>
                        </div>
                      </div>
                      <div class="col-sm-6 col-6">
                        <label class="form-label mt-4">Riwayat Kelahiran</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.riwayatkelahiran" readonly>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-6">
                        <label class="form-label mt-4">Tinggia badan</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.tinggibadan" readonly>
                        </div>
                      </div>
                      <div class="col-sm-6 col-6">
                        <label class="form-label mt-4">Berat Badan</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.beratbadan" readonly>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-sm-6 col-6">
                        <label class="form-label mt-4">Alergi Obat</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.alergiobat" readonly>
                        </div>
                      </div>
                      <div class="col-sm-6 col-6">
                        <label class="form-label mt-4">Alergi Makanan</label>
                        <div class="input-group">
                          <input id="lastName" name="lastName" class="form-control" type="text" :value="siswa.alergimakanan" readonly>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>

                <div class="card mt-4" id="profile-dokumen">
                  <div class="card-header d-flex">
                    <h5 class="mb-0">Dokumen Upload</h5>
                    <!-- <span class="badge badge-success ms-auto">Enabled</span> -->
                  </div>
                  <div class="card-body">
                    <div class="d-flex justify-content-between" v-if="siswa.photopath != null">
                      <p class="my-auto">Photo</p>
                      <a :href="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.photopath}`" target="_blank">
                      <img :src="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.photopath}`" width="150px"/>
                        </a>

                    </div>
                    <hr class="horizontal dark" v-if="siswa.kkpath != null">
                    <div class="d-flex justify-content-between" v-if="siswa.kkpath != null">
                      <p class="my-auto">KK</p>
                      <a :href="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.kkpath}`" target="_blank">
                        <img :src="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.kkpath}`" width="150px"/>
                      </a>


                    </div>
                    <hr class="horizontal dark" v-if="siswa.aktapath != null">
                    <div class="d-flex justify-content-between" v-if="siswa.aktapath != null">
                      <p class="my-auto">Akta</p>
                      <a :href="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.aktapath}`" target="_blank">
                      <img :src="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.aktapath}`" width="150px"/>
                     </a>
                    </div>
                    <hr class="horizontal dark" v-if="siswa.raport != null">
                    <div class="d-flex justify-content-between"  v-if="siswa.raport != null">
                      <p class="my-auto">Raport</p>
                      <a :href="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.raport}`" target="_blank">
                      <img :src="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.raport}`" width="150px"/>
                      </a>
                    </div>
                    <hr class="horizontal dark"  v-if="siswa.suratpernyataan != null">
                    <div class="d-flex justify-content-between" v-if="siswa.suratpernyataan != null">
                      <p class="my-auto">Pernyataan Orang</p>
                      <a :href="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.suratpernyataan}`" target="_blank">
                      <img :src="`https://api.annisaa-izada.sch.id/uploads/regdocsupports/${siswa.pathfolder}/${siswa.suratpernyataan}`" width="150px"/>
                      </a>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive,  onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "PendaftarAktifIndex",
  components: {},
  data() {
    return {
      showMenu: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const siswa = reactive({
      fullname: '',
      pathfolder:'',
      photopath:'',
      unit:'',
      noreg:'',
      kelas: '',
      nickname:'',
      birthdate:'',
      birthplace:'',
      gender:'',
      nik:'',
      nikk:'',
      wni:'',
      lang:'',
      namaayah:'',
      agamaayah:'',
      birthdateayah:'',
      birthplaceeayah:'',
      wniayah:'',
      pendidikanayah:'',
      emailayah:'',
      nokontakayah:'',
      namaIbu:'',
      birthdateIbu:'',
      birthplaceeIbu:'',
      wniIbu:'',
      pendidikanIbu:'',
      agamaIbu:'',
      emailIbu:'',
      nokontakIbu:'',

      alamat:'',
      rt:'',
      rw:'',
      postalcode:'',
      transportasi:'',
      jarak:'',
      jam:'',
      menit:'',

      darah:'',
      riwayatkelahiran:'',
      beratbadan:'',
      tinggibadan:'',
      alergiobat:'',
      alergimakanan:'',

     
      kkpath:'',
      aktapath:'',
      raport:'',
      suratpernyataan:'',
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();
    //route

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
  

    onMounted(async () => {
   
      await store.dispatch("reg/getDetailSiswa", route.params.id);
    });

    watch(
      () => store.state.reg.pesertadetail,
      (newMenu) => {
        if (newMenu) {
          const {
            fullname,
            pathfolder,
            photopath,
            unitlevel,
            noreg,
            nickname,
            birthdate,
            birthplace,
            gender,
            nik,
            nikk,
            wni,
            lang,
            orang_tua,
            tempat_tinggal,
            medical_info,
            kkpath,
            aktapath,
            suratpath,
            raportpath

          } = newMenu;
          siswa.fullname = fullname,
          siswa.unit = unitlevel.unit.name,
          siswa.kelas = unitlevel.kelas.classname,
          siswa.nickname = nickname,
          siswa.noreg = noreg,
          siswa.birthdate = birthdate,
          siswa.birthplace=birthplace,
          siswa.gender=gender,
          siswa.nik=nik,
          siswa.nikk=nikk,
          siswa.wni=wni,
          siswa.lang=lang,
          siswa.namaayah=orang_tua[0] ? orang_tua[0].ayah.fullname : '-',
          siswa.agamaayah=orang_tua[0] ? orang_tua[0].ayah.religion : '-',
          siswa.birthdateayah=orang_tua[0] ? orang_tua[0].ayah.birthdate : '-',
          siswa.birthplaceeayah=orang_tua[0] ? orang_tua[0].ayah.birthplace : '-',
          siswa.wniayah=orang_tua[0] ? orang_tua[0].ayah.wni : '-',
          siswa.pendidikanayah=orang_tua[0] ? orang_tua[0].ayah.edulevel : '-',
          siswa.emailayah=orang_tua[0] ? orang_tua[0].ayah.email : '-',
          siswa.nokontakayah=orang_tua[0] ? orang_tua[0].ayah.cellphone : '-',

          siswa.namaIbu=orang_tua[1] ? orang_tua[1].ibu.fullname : '-',
          siswa.agamaIbu=orang_tua[1] ? orang_tua[1].ibu.religion : '-',
          siswa.birthdateIbu=orang_tua[1] ? orang_tua[1].ibu.birthdate : '-',
          siswa.birthplaceeIbu=orang_tua[1] ? orang_tua[1].ibu.birthplace : '-',
          siswa.wniIbu=orang_tua[1] ? orang_tua[1].ibu.wni : '-',
          siswa.pendidikanIbu=orang_tua[1] ? orang_tua[1].ibu.edulevel : '-',
          siswa.emailIbu=orang_tua[1] ? orang_tua[1].ibu.email : '-',
          siswa.nokontakIbu=orang_tua[1] ? orang_tua[1].ibu.cellphone : '-',

          siswa.alamat = tempat_tinggal.address,
          siswa.rt = tempat_tinggal.rt_number,
          siswa.rw = tempat_tinggal.rw_number,
          siswa.postalcode = tempat_tinggal.postalcode,
          siswa.jarak = tempat_tinggal.distance_km,
          siswa.jam = tempat_tinggal.distance_hour,
          siswa.menit = tempat_tinggal.distance_minute,

          siswa.darah = medical_info ? medical_info.blood_grp : '-',
          siswa.riwayatkelahiran = medical_info ? medical_info.birth_histry : '-',
          siswa.beratbadan = medical_info ? medical_info.weight : '-',
          siswa.height = medical_info ? medical_info.height : '-',
          siswa.alergiobat = medical_info ? medical_info.medic_allergy : '-',
          siswa.alergimakanan = medical_info ? medical_info.food_allergy : '-',

          siswa.pathfolder = pathfolder
          siswa.photopath = photopath
          siswa.kkpath = kkpath
          siswa.aktapath = aktapath
          siswa.raport = raportpath
          siswa.suratpernyataan = suratpath

        }
      }
    );

 

    //return a state and function
    return {
      siswa, // <-- state user
      validation, 
    
    };
  },
};
</script>