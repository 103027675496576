<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Edit Permission</h5>
               
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="updatePermission">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1">Nama Permission</label>
                          <input
                            type="text"
                            placeholder="Nama Permission"
                            class="form-control"
                            v-model="permission.name"
                          />
                          <small class="text-danger" v-if="validation.name">{{
                            validation.name[0]
                          }}</small>
                        </div>
                      </div>
                    </div>
                 
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                        &nbsp;
                        <router-link
                          :to="{ name: 'admin-permission' }"
                          class="btn btn-danger btn-sm"
                          >kembali</router-link
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive,  onMounted, watch } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { useRoute } from "vue-router";
  import Swal from "sweetalert2";
  
  export default {
    name: "PermissionIndex",
    components: {},
    data() {
      return {
        showMenu: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const permission = reactive({
        name: "",
      
      });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
  
      const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
      function updatePermission() {
        //define variable
        let name = permission.name;
       
        let formData = new FormData();
  
        formData.append("name", name);
     
        formData.append("_method", "POST");
  
        //panggil actions "register" dari module "auth"
        store
          .dispatch("permission/update", {
            permissionId: route.params.id,
            payload: formData,
          })
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-permission" });
            Swal.fire({
              icon: "success",
              title: "Update data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
  
      onMounted(async () => {
        await store.dispatch("permission/getDetailPermission", route.params.id);
      });
  
      watch(
        () => store.state.permission.detailpermission,
        (newPermission) => {
          if (newPermission) {
            const {
              key,
             
            } = newPermission;
            permission.name = key;
         
          }
        }
      );
  
   
      //return a state and function
      return {
        permission, // <-- state user
        validation, // <-- state validation
        updatePermission,
      };
    },
  };
  </script>