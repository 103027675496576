<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah unitlevel</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveunitlevel">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Unit</label>
                        <select
                        class="form-control select2"
                        v-model="unitlevel.id_unit"


                        >
                          <option value="">-- select Unit--</option>
                          <option
                            v-for="u in units"
                            :key="u.id"
                            :value="u.id"
                          >
                            {{ u.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Kode</label>
                        <input
                          type="text"
                          placeholder="kode"
                          class="form-control"
                          v-model="unitlevel.code"
                        />
                        <small class="text-danger" v-if="validation.code">{{
                          validation.code[0]
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama</label>
                        <input
                          type="text"
                          placeholder="name"
                          class="form-control"
                          v-model="unitlevel.name"
                        />
                        <small class="text-danger" v-if="validation.name">{{
                          validation.name[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Sort</label>
                        <input
                          type="number"
                          placeholder="name"
                          class="form-control"
                          v-model="unitlevel.sort"
                        />
                        <small class="text-danger" v-if="validation.sort">{{
                          validation.sort[0]
                        }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Numrule</label
                        >
                        <input
                          type="number"
                          placeholder="name"
                          class="form-control"
                          v-model="unitlevel.numrule"
                        />
                        <small
                          class="text-danger"
                          v-if="validation.numrule"
                          >{{ validation.numrule[0] }}</small
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3">
                      <label for="exampleFormControlInput1"
                        >Validate min age</label
                      >
                      <input
                        type="number"
                        placeholder="number"
                        class="form-control"
                        v-model="unitlevel.validate_min_age"
                      />
                      <small
                        class="text-danger"
                        v-if="validation.validate_min_age"
                        >{{ validation.validate_min_age[0] }}</small
                      >
                    </div>

                    <div class="col-md-3">
                      <label for="exampleFormControlInput1"
                        >Validate max month</label
                      >
                      <input
                        type="number"
                        placeholder="name"
                        class="form-control"
                        v-model="unitlevel.validate_max_month"
                      />
                      <small
                        class="text-danger"
                        v-if="validation.validate_max_month"
                        >{{ validation.validate_max_month[0] }}</small
                      >
                    </div>

                    <div class="col-md-3">
                      <label for="exampleFormControlInput1"
                        >Validate max date</label
                      >
                      <input
                        type="number"
                        placeholder="name"
                        class="form-control"
                        v-model="unitlevel.validate_max_date"
                      />
                      <small
                        class="text-danger"
                        v-if="validation.validate_max_date"
                        >{{ validation.validate_max_date[0] }}</small
                      >
                    </div>

                    <div class="col-md-3">
                      <label for="exampleFormControlInput1"
                        >Validate max year</label
                      >
                      <select class="form-control" v-model="unitlevel.validate_max_year">
                        <option value="this_year">Tahun Ini</option>
                        <option value="next_year">Next Year</option>
                      </select>

                      <small
                        class="text-danger"
                        v-if="validation.validate_max_year"
                        >{{ validation.validate_max_year[0] }}</small
                      >
                    </div>
                  </div>


                  <div class="row mt-5">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                        :to="{ name: 'admin-unitlevel' }"
                        class="btn btn-danger btn-sm"
                        >kembali</router-link
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "unitlevelIndex",
  components: {},
  data() {
    return {
      showunitlevel: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

 
  setup() {
    //unitlevel state
    const unitlevel = reactive({
      id_unit:"",
        name: "",
        code: "",
        sort: "",
        numrule:"",
        validate_max_date:"",
        validate_max_month:"",
        validate_min_age:"",
        validate_max_year:""
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

   

    //function register, fungsi ini di jalankan ketika form di submit
    function saveunitlevel() {
     
      //define variable
    

      let formData = new FormData();

      formData.append("name", this.unitlevel.name);
      formData.append("code", this.unitlevel.code);
      formData.append("sort", this.unitlevel.sort);
      formData.append("numrule", this.unitlevel.numrule);
      formData.append("id_unit", this.unitlevel.id_unit);
      formData.append("validate_max_date", this.unitlevel.validate_max_date);
      formData.append("validate_max_month", this.unitlevel.validate_max_month);
      formData.append("validate_max_year", this.unitlevel.validate_max_year);
      formData.append("validate_min_age", this.unitlevel.validate_min_age);
      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("unitlevel/store", 
          formData,
        )
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-unitlevel" });
          Swal.fire({
          icon: 'success',
          title: 'Tambah data berhasil',
        });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("units/getAll");
    });

   

    const units = computed(() => {
      //panggil getter dengan nama "getParent" di module "unitlevel" vuex
      return store.getters["units/getAll"];
    });

   

    //return a state and function
    return {
      unitlevel, // <-- state unitlevel
      validation, // <-- state validation
      saveunitlevel,
      units,
    };
  },
};
</script>