<style>
      /* .gambar {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: center;
          align-items: stretch;
      } */

      .container {
        display: grid;
        /* grid-template-columns: repeat(auto-fit, minmax(7cm, 1fr)); */
        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr)); /* 7x4 cm dalam piksel */
        gap: 10px;
      }

      .image {
        max-width: 100%;
        height: auto;
      }

    </style>
<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header  text-center">
              <div class="d-flex justify-content-between">
                <h3 class="mb-0 w-100">
                  LAPORAN PERKEMBANGAN BULANAN <br>KB AN-NISAA' / TK AN-NISAA'
                  <br>TAHUN PELAJARAN {{ tahunajaran.name }}
                </h3>

              </div>
            </div>
  
            <div class="card-body mt-5">
              <div class="row">
                <div class="col-12">
                    <div class="row">
                          <div class="form-group">
                            <div class="row">
                                <div class="col-12">
                                    <!-- <h5>Identitas Siswa </h5> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-9">
                                  <table border="0">
                                    <tr>
                                      <td>Nama </td>
                                      <td>:</td>
                                      <td>&nbsp;{{ detail.regs?detail.regs.fullname:'' }}</td>
                                    </tr>
                                    <tr>
                                      <td>Kelas</td>
                                      <td>:</td>
                                      <td>&nbsp;{{ detail.regs?detail.regs.unitlevel.kelas.classname:'' }}</td>
                                    </tr>
                                  </table>
                                </div>
                                <div class="col-3">
                                  <table border="0">
                                    <tr>
                                      <td>Semester </td>
                                      <td>:</td>
                                      <td>&nbsp;{{ detail.tas?detail.tas.desc:'' }}</td>
                                    </tr>
                                    <tr>
                                      <td>Bulan</td>
                                      <td>:</td>
                                      <td>&nbsp;{{ detail.bulans?detail.bulans.nama:'' }}</td>
                                    </tr>
                                  </table>
                                </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-md-12">
                              <table border="1">
                                <tr>
                                  <td style="padding: 15px !important;">
                                    <b for="">KOMENTAR GURU : </b>
                                    <div class="mt-2" style="text-align:justify;">
                                      {{ detail.narasi }}
                                      <div class="container mt-5"
                                      style="display: flex;"
                                      >
                                        <img class="image" :src="'http://localhost:8000/public/report/' + detail.foto1" alt="" style="max-height:4cm;width:auto;">
                                        <img class="image" :src="'http://localhost:8000/public/report/' + detail.foto2" alt="" style="max-height:4cm;width:auto;">
                                      </div>
                                      <!-- <div class="gambar">
                                          <div>
                                            
                                          </div>
                                          <div>
                                            
                                          </div>
                                      </div> -->
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div class="col-md-12">
                              <div class="mt-4">
                                <b>TANGGAL PUBLIKASI</b>
                              </div>
                              <form @submit.prevent="savePublikasi($event)">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="tanggal_awal">Tanggal Awal <small class="text-danger" v-if="validation.tanggal_awal">{{ validation.tanggal_awal[0] }}</small></label>
                                      <input type="datetime-local" name="tanggal_awal" id="tanggal_awal" :value="publikasi ? publikasi.tanggal_awal : ''" class="form-control">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="tanggal_awal">Tanggal Akhir <small class="text-danger" v-if="validation.tanggal_akhir">{{ validation.tanggal_akhir[0] }}</small> </label>
                                      <input type="datetime-local" name="tanggal_akhir" id="tanggal_akhir" :value="publikasi ? publikasi.tanggal_akhir:''" class="form-control">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <button type="submit" class="btn btn-sm btn-success"
                                        
                                        >
                                          Simpan
                                        </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                      </div>
                    <div class="row mt-5">
                      <div class="col-12">
                        <label>
                        </label>
                        <router-link :to="{name: 'admin-laporan-bulanan-kb-history', params:{id: detail.regs?detail.id_student:0 }}" class="btn btn-danger btn-xs m-0 me-2"> <i class="fas fa-arrow-left"></i></router-link>
                        <a :href="'http://localhost:8000/api/laporan-kbtk-pdf/' + $route.params.id + '/' + (detail.regs && detail.regs.unitlevel.code ? detail.regs.unitlevel.code.substr(0, 2) : 'KB')" target="_blank" class="btn btn-primary btn-xs m-0 me-2">Print</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted, watch, computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import Api from "../../../api/Api";
  
  export default {
    name: "KBEdit",
    components: {},
    data() {
      return {
        showsiswa: false,
      };
    },
    methods: {
      limitText(text, limit) {
        if (text.length > limit) {
          return text.substring(0, limit) + '...';
        }
        return text;
      },
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state
      const siswa = reactive({
        foto1:'',
        foto2:'',
      });

      const variable = reactive({
        id_unit: "",
        id_ta:"",
        id_student: "",
        bulan: "",
        tahun: "",
        elemen: "",
        narasi: "",
        foto1:"",
        foto2:"",
      });


      const listbulan = computed(() => {
            return store.getters["global/getBulan"];
      });


      const tahunajaran = computed(() => {
            return store.getters["tahunajaran/getActive"];
      });

      const detail = computed(() => {
        return store.getters["narasikb/detail"];
      });

      const kelas = computed(() => {
            return store.getters["bebanmengajar/getKelasGuruData"];
      });

      const publikasi = computed(() => {
            return store.getters["narasikb/publikasi"];
      });


      //validation state

  
      //store vuex
      const store = useStore();

      const router = useRouter();

      const validation = ref([]);
  


    const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
    
      

      
  
      watch(
        () => store.state.reg.pesertadetail,
        (newsiswa) => {
          //console.log('newsiswa',newsiswa);
          if (newsiswa) {
            const {
                fullname,
                nickname,
                gender,
                birthplace,
                birthdate,
                tingkatan,
                sekolah,
                id_unitlevel,
                id,
            } = newsiswa;
            siswa.fullname = fullname;
            siswa.nickname = nickname;
            siswa.gender = gender;
            siswa.tempatlahir = birthplace;
            siswa.tanggallahir = birthdate;
            siswa.tingkatan = tingkatan;
            siswa.sekolah = sekolah;
            siswa.id_unitlevel = id_unitlevel;
            siswa.id = id;            
          }
        }
      );

      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        raport: {
          file: '',
          id_studentactive:''
        },
        
      });

      async function handleFileChange(e) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(image)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }

      async function handleFileChange2(e) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(image)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
      

      function saveLaporan() {
        //define variable
        // console.log(" siswa ",siswa);
        let formData = new FormData();
        formData.append('id_unit', siswa.id_unitlevel);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.id);
        formData.append('bulan', this.variable.bulan);
        formData.append('tahun', this.variable.tahun);
        formData.append('elemen', this.variable.elemen);
        formData.append('narasi', this.variable.narasi);
        formData.append('foto1', this.variable.foto1);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        //panggil actions "register" dari module "auth"
        store
          .dispatch("narasikb/store", formData)
          .then(() => {
            //redirect ke dashboard
            router.push({ name: "admin-laporan-bulanan-kb" });
            Swal.fire({
              icon: "success",
              title: "Simpan data berhasil",
            });
  
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
      }
      
      onMounted(async () => {
        
        await store.dispatch("global/getBulan");
        await store.dispatch("tahunajaran/getActive");
        const tahunajaran = tahunajaran;
        await store.dispatch("narasikb/detail",route.params.id);
        await store.dispatch("bebanmengajar/getKelasGuruData", route.params.id);
        await store.dispatch("narasikb/publikasi", route.params.id);
      });

      async function savePublikasi(event) {
        let formData = new FormData();
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_laporan_bulanan_kbtk', route.params.id);
        formData.append('tanggal_awal', event.target.tanggal_awal.value);
        formData.append('tanggal_akhir', event.target.tanggal_akhir.value);
        formData.append("_method", "POST");
        console.log(formData);
        store
          .dispatch("narasikb/publikasi_store", formData)
          .then(() => {
            // router.push({ name: 'admin-laporan-akhir-kb', params: { id:route.params.instructor } });
            Swal.fire({
              icon: "success",
              title: "Simpan data berhasil",
            });
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });


      }


      //return a state and function
      return {
        listbulan,
        variable,
        handleFileChange,
        handleFileChange2,
        data,
        saveLaporan,
        validation,
        tahunajaran,
        detail,
        Api,
        kelas,
        publikasi,
        savePublikasi
      };
    },
  };
  </script>